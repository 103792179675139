import React from 'react'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import pdf from "../Icons/General Instructions.pdf";


function CDMA() {
    return (
        <>
         <div class="lernen_banner large bg-cdma">
        <div class="container">
            <div class="row">
                <div class="lernen_banner_title">
                    <h1>CDMA</h1>
                    <div class="lernen_breadcrumb">
                        <div class="breadcrumbs">
									<span class="first-item">
									<a href="index.html">Homepage</a></span>
                            <span class="separator">&gt;</span>
                            <span class="last-item">CDMA</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="features" class="wrap-bg">
       
       <div class="container">
           <div class="row justify-content-center text-center">
               <div class="col-lg-8">
                   <div class="section-title with-p">
                       <h2>About Course</h2>
                       <div class="bar"></div>
                       <p>Code Division Multiple Access (CDMA) is a channel access method normally used by 3G radio communication technology as well as in some other technologies. The technicality of CDMA technology has given significant advantages over other parallel technologies in terms of spectrum efficiency and overall performance.
                       </p>
                   </div>
               </div>
           </div>
         
           <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 1 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            {/* <!-- uses solid style --> */}
                            <i class="secondary-color fas fa-chalkboard-teacher fa-3x"></i>
                            <h4><a href="#">IT Experts as Trainers</a></h4>
                            <p>Learning a technology with a professional who is well expertise in that solve 60% of your needs.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 2 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-cubes fa-3x"></i>
                            <h4><a href="#">Fully Hands-on Training</a></h4>
                            <p>We support any training with more practical classes. So, we always prefers to give hands-on training.
</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 3 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-people-carry fa-3x"></i>
                            <h4><a href="#">Flexible Timings</a></h4>
                            <p>We can give the flexibility timings as per your schedule. So that it will not impact your office or personal work.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
            </div>
           {/* <div class="header-curriculum">
                
               
               
            </div> */}
            <h3>Curriculum Content</h3>
            {/* <h4 className='text-right  btn-primary'  ><a href={pdf}  Download>Download Curriculum PDF </a></h4> */}
           <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            CDMA - Introduction
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; What is CDMA? </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Salient Features of CDMA </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Third Generation Standards </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; CDMA Development Group (CDG) </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; IMT-2000 System </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            CDMA - Channels 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Forward Channel </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Reverse Channel </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                             CDMA - Multiple Access Methods
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                         <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Frequency Division Duplex </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Time Division Duplex </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Frequency Division Duplex </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            FDMA - Technology
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; FDMA Overview </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Advantages of FDMA </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Disadvantages of FDMA </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            TDMA - Technology
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; TDMA Overview </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; FDMA and TDMA </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Advantages of TDMA </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Disadvantages of TDMA </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            CDMA - Technology
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                         <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; How Does CDMA Work? </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; CDMA Capacity </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Centralized Methods </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Processing Gain </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Advantages of CDMA</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Disadvantages of CDMA</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>


                                  {/* FAQ Start */}
                                  <h3>Premium Course FAQs</h3>
            <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What will you learn as a part of this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Who should go for this training?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What are the skills that you will be learning with our DevOps course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What are the pre-requisites for this Course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I miss a class?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            You will never miss a lecture at Techiecub! You can choose either of the two options: View the recorded session of the class available in your LMS OR You can attend the missed session, in any other live batch.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I have queries after I complete this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Your access to the Support Team is for lifetime and will be available 24/7. The team will help you in resolving queries, during and after the course.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How soon after Signing up would I get access to the Learning Content?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Post-enrolment, the LMS access will be instantly provided to you and will be available for lifetime. You will be able to access the complete set of previous class recordings, PPTs, PDFs, assignments. Moreover the access to our 24x7 support team will be granted instantly as well. You can start learning right away.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
                      {/* FAQ End */}

          
         
       </div>
      
   </div>
  

  
        </>
    )
}

export default CDMA;