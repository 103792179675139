import React from 'react'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import pdf from "../Icons/General Instructions.pdf";


function Azure() {
    return (
        <>
         <div class="lernen_banner large bg-azure">
        <div class="container">
            <div class="row">
                <div class="lernen_banner_title">
                    <h1>Azure</h1>
                    <div class="lernen_breadcrumb">
                        <div class="breadcrumbs">
									<span class="first-item">
									<a href="/">Homepage</a></span>
                            <span class="separator">&gt;</span>
                            <span class="last-item">Azure</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="features" class="wrap-bg">
       
       <div class="container">
           <div class="row justify-content-center text-center">
               <div class="col-lg-8">
                   <div class="section-title with-p">
                       <h2>About Course</h2>
                       <div class="bar"></div>
                       <p>Azure is a complete cloud platform that can host your existing application infrastructure, provide compute-based services tailored for your application development needs, or even augment your onpremises applications. Azure integrates the cloud services that you need to develop, test, deploy, and manage your applications—while taking advantage of the efficiencies of cloud computing. By hosting your applications in Azure, you can start small and easily scale your application as your customer demand grows. Azure also offers the reliability that’s needed for high-availability applications, even including failover between different regions. You can easily manage all of your services remotely from one location, the Azure portal, or programmatically by using various APIs and templates.
                       </p>
                   </div>
               </div>
           </div>
         
           <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 1 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            {/* <!-- uses solid style --> */}
                            <i class="secondary-color fas fa-chalkboard-teacher fa-3x"></i>
                            <h4><a href="#">IT Experts as Trainers</a></h4>
                            <p>Learning a technology with a professional who is well expertise in that solve 60% of your needs.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 2 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-cubes fa-3x"></i>
                            <h4><a href="#">Fully Hands-on Training</a></h4>
                            <p>We support any training with more practical classes. So, we always prefers to give hands-on training.
</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 3 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-people-carry fa-3x"></i>
                            <h4><a href="#">Flexible Timings</a></h4>
                            <p>We can give the flexibility timings as per your schedule. So that it will not impact your office or personal work.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
            </div>
           {/* <div class="header-curriculum">
                
               
               
            </div> */}
            <h3>Curriculum Content</h3>
            {/* <h4 className='text-right  btn-primary'  ><a href={pdf}  Download>Download Curriculum PDF </a></h4> */}
           <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Introduction to Cloud Computing 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Approaches to Cloud Computing  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Infrastructure as a Service  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Software as a Service</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Platform as a Service  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Cloud Services Defined  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Long-Term Vision </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Introduction to the Windows Azure Platform
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; The Operating System  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Service Creation  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Windows Azure Storage </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; The Worker Role  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Windows Azure AppFabric  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; The Access Control Service  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; SQL Azure </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Creating a Web Role Project 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Software Development Kits   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Windows Azure Tools for Visual Studio   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Web Role Project Template  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; The Cloud Project   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Deployment to Windows Azure   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Configuration and Upgrading  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Service Definition File  </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Tables, Queues, and Worker Roles 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                         <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; The Table Service  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; The Queue Service  </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Building an AppFabric Solution 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Windows Azure AppFabric Components   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Service Bus   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Direct Connection  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Bindings   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; HTTP for Management URI  </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            WCF Data Services and OData 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; The Astoria Project   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; WCF Data Services (ADO NET Data Services)   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; The Building Blocks  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; WCF Data Service  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Query and Relationship  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Filtering, Sorting, and Pagination   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; WCF Data Service Client  </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Using SQL Azure 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; SQL Azure Features   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; SQL Azure Database Access  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Database Server Creation in the Cloud   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; SQL Azure Access   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; SQL Azure Relational Engine Feature  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Existing Database Migration   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; SQL Azure Migration Wizard   </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Accessing Azure Services from Everywhere 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Creating the Storage Account Project   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Accessing the Storage Account from PHP    </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Using HTTP and REST   </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Application Architecture 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Characteristics of a Multitier Solution   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; The Data Access Layer  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; The Service Agent   </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>


                      {/* FAQ Start */}
                      <h3>Premium Course FAQs</h3>
            
            <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What will you learn as a part of this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Who should go for this training?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What are the skills that you will be learning with our DevOps course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What are the pre-requisites for this Course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I miss a class?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            You will never miss a lecture at Techiecub! You can choose either of the two options: View the recorded session of the class available in your LMS OR You can attend the missed session, in any other live batch.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I have queries after I complete this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Your access to the Support Team is for lifetime and will be available 24/7. The team will help you in resolving queries, during and after the course.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How soon after Signing up would I get access to the Learning Content?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Post-enrolment, the LMS access will be instantly provided to you and will be available for lifetime. You will be able to access the complete set of previous class recordings, PPTs, PDFs, assignments. Moreover the access to our 24x7 support team will be granted instantly as well. You can start learning right away.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
                      {/* FAQ End */}

          
         
       </div>
      
   </div>
  

  
        </>
    )
}

export default Azure;