import React from 'react';


function ResourceOutsourcing() {
    return (
        <>


            <div class="lernen_banner large bg-outsourcing">
                <div class="container">
                    <div class="row">
                        <div class="lernen_banner_title">
                           <h1> IT Staffing</h1>

                            <div class="lernen_breadcrumb">
                                <div class="breadcrumbs">
                                    <span class="first-item">
                                        <a href="index.html">Homepage</a></span>
                                    <span class="separator">&gt;</span>
                                    <span class="last-item"> IT Staffing</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* <!-- services style projects area start --> */}
            <div id="projects" class="wrap-bg">
                <div class="container">
                    <div class="row justify-content-center text-center">
                        <div class="col-lg-8">
                            <div class="section-title with-p">
                                <h2>IT Staffing</h2>
                                <div class="bar"></div>
                                <p>TechieCub solutions provide quality candidates by targetting on the resource's technology and talent. We concentrate on each and every step that filters the quality candidates from the rest.
                            </p>
                            </div>
                        </div>
                    </div>
                    {/* <!-- .row --> */}
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
                            {/* <!-- 1 --> */}
                            <div class="themeioan_services">
                                <article>
                                    {/* <!-- single services --> */}
                                    <div class="blog-content">
                                        <div class="icon-space">
                                            <div class="glyph-icon flaticon-030-test"></div>
                                        </div>
                                        <h5 class="title"><a href="services-detail.html">Contract Staffing</a>
                                        </h5>
                                        <p>We hire top-end employees for a particular period of time, to accomplish the given task with efficiency.

                                    </p>
{/*                                         <div class="mt-25">
                                            <a href="services-detail.html" class="button-light"><i class="fas fa-arrow-right"></i> Read More</a>
                                        </div> */}
                                    </div>
                                </article>
                                {/* <!-- end single services --> */}
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
                            {/* <!-- 2 --> */}
                            <div class="themeioan_services">
                                <article>
                                    {/* <!-- single services --> */}
                                    <div class="blog-content">
                                        <div class="icon-space">
                                            <div class="glyph-icon flaticon-009-skills"></div>
                                        </div>
                                        <h5 class="title"><a href="services-detail.html">Permanent Staffing</a></h5>
                                        <p>Need the right talent in your team? We provide you with the best professional skillsets.
                                    </p>
{/*                                         <div class="mt-25">
                                            <a href="services-detail.html" class="button-light"><i class="fas fa-arrow-right"></i> Read More</a>
                                        </div> */}
                                    </div>
                                </article>
                                {/* <!-- end single services --> */}
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
                            {/* <!-- 3 --> */}
                            <div class="themeioan_services">
                                <article>
                                    {/* <!-- single services --> */}
                                    <div class="blog-content">
                                        <div class="icon-space">
                                            <div class="glyph-icon flaticon-028-thinking"></div>
                                        </div>
                                        <h5 class="title"><a href="services-detail.html">Payroll Transfer</a>
                                        </h5>
                                        <p>We handle Payroll transfers with efficiency, as we maintain them with perfect solutions.
                                    </p>
{/*                                         <div class="mt-25">
                                            <a href="services-detail.html" class="button-light"><i class="fas fa-arrow-right"></i> Read More</a>
                                        </div> */}
                                    </div>
                                </article>
                                {/* <!-- end single services --> */}
                            </div>
                        </div>
                    </div>
                    {/* <!-- .row end --> */}
                </div>
            </div>
            {/* <!-- services style projects area end --> */}

        </>
    )
}

export default ResourceOutsourcing;