import React from 'react';


function CoWorks() {
    return (
        <>


            <div class="lernen_banner large bg-coworks">
                <div class="container">
                    <div class="row">
                        <div class="lernen_banner_title">
                           <h1>Co-Working Space</h1>

                            <div class="lernen_breadcrumb">
                                <div class="breadcrumbs">
                                    <span class="first-item">
                                        <a href="index.html">Homepage</a></span>
                                    <span class="separator">&gt;</span>
                                    <span class="last-item">Co-Working Space</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* <!-- services style projects area start --> */}
            <div id="projects" class="wrap-bg">
                <div class="container">
                    <div class="row justify-content-center text-center">
                        <div class="col-lg-8">
                            <div class="section-title with-p">
                                <h2>Co-Working Space</h2>
                                <div class="bar"></div>
                                <p>Techiecubsolutions offers Co-working space, on-demand meeting rooms, conference room etc. for your business needs in Bangalore
                            </p>
                            </div>
                        </div>
                    </div>
                    {/* <!-- .row --> */}
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
                            {/* <!-- 1 --> */}
                            <div class="themeioan_services">
                                <article>
                                    {/* <!-- single services --> */}
                                    <div class="blog-content">
                                        <div class="icon-space">
                                            <div class="glyph-icon flaticon-030-test"></div>
                                        </div>
                                        <h5 class="title"><a href="services-detail.html">Amenities</a>
                                        </h5>
                                        <p>We provide good Amenities like Professional Business Address, Reception Support and internet access facilities.
                                    </p>
{/*                                         <div class="mt-25">
                                            <a href="services-detail.html" class="button-light"><i class="fas fa-arrow-right"></i> Read More</a>
                                        </div> */}
                                    </div>
                                </article>
                                {/* <!-- end single services --> */}
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
                            {/* <!-- 2 --> */}
                            <div class="themeioan_services">
                                <article>
                                    {/* <!-- single services --> */}
                                    <div class="blog-content">
                                        <div class="icon-space">
                                            <div class="glyph-icon flaticon-009-skills"></div>
                                        </div>
                                        <h5 class="title"><a href="services-detail.html">Meeting Rooms</a></h5>
                                        <p>Now a days used by many businesses for thier board meetings, conducting interviews, meeting clients and so on. So we have meeting fecilities also.
                                    </p>
{/*                                         <div class="mt-25">
                                            <a href="services-detail.html" class="button-light"><i class="fas fa-arrow-right"></i> Read More</a>
                                        </div> */}
                                    </div>
                                </article>
                                {/* <!-- end single services --> */}
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
                            {/* <!-- 3 --> */}
                            <div class="themeioan_services">
                            <div class="services_image services_bg2 hoverblack">
{/*                                 <div class="opac">
                                    <h3>Speaker Courses</h3>
                                    <p>Open a beautiful store & increase your conversion rates. Deploy a conversion rate optimization.</p>
                                </div> */}
                            </div>
                                {/* <!-- end single services --> */}
                            </div>
                        </div>


                    </div>
                    {/* <!-- .row end --> */}
                </div>
            </div>
            {/* <!-- services style projects area end --> */}

        </>
    )
}

export default CoWorks;