import React from 'react'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import pdf from "../Icons/General Instructions.pdf";


function Mongodb() {
    return (
        <>
         <div class="lernen_banner large bg-mongodb">
        <div class="container">
            <div class="row">
                <div class="lernen_banner_title">
                    <h1>MongoDB</h1>
                    <div class="lernen_breadcrumb">
                        <div class="breadcrumbs">
									<span class="first-item">
									<a href="/">Homepage</a></span>
                            <span class="separator">&gt;</span>
                            <span class="last-item">MongoDB</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="features" class="wrap-bg">
       
       <div class="container">
           <div class="row justify-content-center text-center">
               <div class="col-lg-8">
                   <div class="section-title with-p">
                       <h2>About Course</h2>
                       <div class="bar"></div>
                       <p>With the rise of unstructured data and semi-structured data across various sectors, NoSQL databases are getting more and more popular. MongoDB is one of the most widely used NoSQL Database. MongoDB is a document-based, distributed database built for modern application developers. It is more relevant today than ever for cloud-native applications. MongoDB works with Rich JSON Documents, supports a powerful query language, Supports aggregations and other modern use-cases such as geo-based search, graph search, and text search. MongoDB like its counterparts in SQL-like databases supports Full ACID transactions and joins in queries. This MongoDB course starts with the fundamentals of NoSQL databases, use-cases and features. After that, the course covers the fundamentals of MongoDB and afterwards takes the training participants to the advanced level of MongoDB. This is a completely hands-on course and each participant will have access to cloud-lab for complete control of the databases. Corporate training for MongoDB is customised based on the requirements of the organisation. 
                       </p>
                   </div>
               </div>
           </div>
         
           <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 1 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            {/* <!-- uses solid style --> */}
                            <i class="secondary-color fas fa-chalkboard-teacher fa-3x"></i>
                            <h4><a href="#">IT Experts as Trainers</a></h4>
                            <p>Learning a technology with a professional who is well expertise in that solve 60% of your needs.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 2 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-cubes fa-3x"></i>
                            <h4><a href="#">Fully Hands-on Training</a></h4>
                            <p>We support any training with more practical classes. So, we always prefers to give hands-on training.
</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 3 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-people-carry fa-3x"></i>
                            <h4><a href="#">Flexible Timings</a></h4>
                            <p>We can give the flexibility timings as per your schedule. So that it will not impact your office or personal work.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
            </div>
           {/* <div class="header-curriculum">
                
               
               
            </div> */}
            <h3>Curriculum Content</h3>
            {/* <h4 className='text-right  btn-primary'  ><a href={pdf}  Download>Download Curriculum PDF </a></h4> */}
           <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Introduction to NoSQL
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; What Is NoSQL? </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Why NoSQL databases are required. </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Types of NoSQL Database </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; NoSQL vs SQL Comparison </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; ACID & BASE Property </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; CAP Theorem </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            MongoDB Architecture
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Document, Collection, Databases </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; JSON and BSON </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Storage Engines </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Read Path </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Journaling </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Capped Collection</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Oplog collection </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Introduction to CSS
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Document, Collection, Databases </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; JSON and BSON </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Storage Engines </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Read Path </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Journaling </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Capped Collection</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Oplog collection </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            CRUD Operations
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; MongoDB Data Types </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Inserting, Update, Deleting the documents </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Querying the documents </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Bulk insert operation </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Updating multiple document </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Limiting documents</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Filtering documents </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Schema Design and Data modeling
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Dynamic Schema </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; What is Data modeling? </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; RDBMS and MongoDB Data modeling difference </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Embedding Document </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Reference Document </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Indexes
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Index concepts in MongoDB </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Types of indexes </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Indexes and its use cases </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Creating Indexes </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Managing Indexes </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Index strategies</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Database Administration in MongoDB
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Database status </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Troubleshooting issues </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Current Operations </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Rotating log files </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Users and Roles </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Copy and Clone database</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; DB and Collection Stats </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            MongoDB: Backup and Security
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Concept of backups </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; mongoexport/mongoimport </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; mongodump/mongorestore </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Oplog backups </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; LVM Backups </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Backups using MMS/Ops Manager</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Purpose of security </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>


                      {/* FAQ Start */}
                      <h3>Premium Course FAQs</h3>
            <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What will you learn as a part of this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Who should go for this training?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What are the skills that you will be learning with our DevOps course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What are the pre-requisites for this Course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I miss a class?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            You will never miss a lecture at Techiecub! You can choose either of the two options: View the recorded session of the class available in your LMS OR You can attend the missed session, in any other live batch.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I have queries after I complete this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Your access to the Support Team is for lifetime and will be available 24/7. The team will help you in resolving queries, during and after the course.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How soon after Signing up would I get access to the Learning Content?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Post-enrolment, the LMS access will be instantly provided to you and will be available for lifetime. You will be able to access the complete set of previous class recordings, PPTs, PDFs, assignments. Moreover the access to our 24x7 support team will be granted instantly as well. You can start learning right away.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
                      {/* FAQ End */}

          
         
       </div>
      
   </div>
  

  
        </>
    )
}

export default Mongodb;