import React,{useState} from 'react';
import joi from 'joi';
import valid from '../../common/validate'
import swal from "sweetalert";

const AppDevelopment = () => {
    const [data,setData]=useState({});
    const [errors,setErrors]=useState({});
    const [emailStatus,setEmailStatus]=useState(false)
     

    const schema = {
        contactName: joi.string().required().label('name'),
        contactEmail: joi.string().email().required(),
         contactSubject: joi.string().required().label('Subject'),
         contactMessage: joi.string().required().label('message')
    }
   
    
    


  

    // const sendEmail=(e)=> {
    //     e.preventDefault();
    
    //     emailjs
    //       .sendForm(
    //         "service_tv8sniw",
    //         "template_q0wsozo",
    //         e.target,
    //         "user_5Ci8rPqJhp90LnCWBPXln"
    //       )
    //       .then(
    //         (result) => {
    //           console.log(result.text);
    //           swal('Message Send Successfully','Message','success');
    //         },
    //         (error) => {
    //           console.log(error.text);
    //         }
    //       );
    //     e.target.reset();
    //   }
    const handleOnChange=(e)=>{
     const input={...data};
     const error={...errors};
     const errorMessage = valid.validateProperty(e.currentTarget, schema);
     console.log(errorMessage)
     if (errorMessage) error[e.currentTarget.name] = errorMessage;
     else delete error[e.currentTarget.name];
 
     input[e.currentTarget.name] = e.currentTarget.value;
     setData(input);
     setErrors(error || {})
    
    }

    const handleSubmit=()=>{
        setEmailStatus(true)
       		window.Email.send({
				Host: "mail.techiecubsolutions.com",
				Username : "info@techiecubsolutions.com",
				Password : "Redhat@19",
				To : 'info@techiecubsolutions.com',
				From : "info@techiecubsolutions.com",
				Subject : "App Development Enquery",
				Body : 'Name ->  '+data.contactName+'<br>'+'Email -> '+data.contactEmail+'<br>'+'Subject ->  '+data.contactSubject+'<br>'+'Message ->  '+data.contactMessage+'<br>',
				}).then(
					message => {
						// form.append('<div class="success-msg">Thank you! We will contact you shortly.</div>');
                        setEmailStatus(false)
                        swal('Thanks for the details. We will connect with you shortly.','Message Send Success','success')
						//formData.reset()
					}
				)
				.catch(
					err=>{console.log(err);setEmailStatus(false)}
                    // form.append('<div class="error-msg">Error occurred. Please try again later.</div>')
				);
    }

    return ( 
        <>
        <div class="lernen_banner large bg-appDevelopment">
            <div class="container">
                <div class="row">
                    <div class="lernen_banner_title">
                        <h1>App Development</h1>
                        <div class="lernen_breadcrumb">
                            <div class="breadcrumbs">
                                <span class="first-item">
                                    <a href="index.html">Homepage</a></span>
                                <span class="separator">&gt;</span>
                                <span class="last-item">App Dvelopment</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        


{/* <!-- features area start --> */}
<div id="features" class="wrap-bg">
    {/* <!-- .container --> */}
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-lg-8">
                <div class="section-title with-p">
          
                    <h2>App Development</h2>
                    <div class="bar"></div>
                    <p>App Development plays a very critical role in today’s digital world. Creating a website is like creating an identity of your business in the web world (www). It is a very powerful communication tool, which informs the world around you of you and your business and its existence.Techiecub Solutions provide you the high quality professional services with our expertise in world-class web development.
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                {/* <!-- 1 --> */}
                <div class="single-features-light">
                    {/* <!-- single features --> */}
                    <div class="move">
                        {/* <!-- uses solid style --> */}
                        <i class="secondary-color fa fa-android fa-3x"></i>
                        <h4><a href="#">Android</a></h4>
                        <p>We help develop intuitive Android apps ranging from custom applications to enterprise-level. Our app developers make the best use of Android SDK platform and mobile app development tools to build secure and user-friendly Google Play Store apps.</p>
{/*                         <div class="feature_link">
                            <a href="#"><i class="fas fa-arrow-right"></i></a>
                        </div> */}
                    </div>
                </div>
                {/* <!-- end single features --> */}
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                {/* <!-- 2 --> */}
                <div class="single-features-light">
                    {/* <!-- single features --> */}
                    <div class="move">
                        <i class="secondary-color fa fa-apple fa-3x"></i>
                        <h4><a href="#">IOS</a></h4>
                        <p>Our expert iOS developers ensure to build high-performance iPhone apps that are both engaging and monetizing. With our capabilities, we not only develop your app but strategize your entire product lifecycle to leverage long-term business growth.</p>
{/*                         <div class="feature_link">
                            <a href="#"><i class="fas fa-arrow-right"></i></a>
                        </div> */}
                    </div>
                </div>
                {/* <!-- end single features --> */}
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                {/* <!-- 3 --> */}
                <div class="single-features-light">
                    {/* <!-- single features --> */}
                    <div class="move">
                        <i class="secondary-color fa fa-mobile fa-3x"></i>
                        <h4><a href="#">Progressive Web Apps</a></h4>
                        <p>Develop an installable app-like experience for desktop and mobile that can be delivered directly via the web. PWAs are highly responsive and have a single code base for different mobile app development platforms. Consider building web apps for a progressive.</p>
{/*                         <div class="feature_link">
                            <a href="#"><i class="fas fa-arrow-right"></i></a>
                        </div> */}
                    </div>
                </div>
                {/* <!-- end single features --> */}
            </div>
        </div>
        {/* <!-- .row end --> */}
    </div>
    {/* <!-- .container end --> */}
</div>
{/* <!-- features area end -->
<!-- contact area start --> */}
<div id="contact" class="wrap-bg wrap-bg">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-lg-8">
                <div class="section-title with-p">
                    <h2>Get in Touch Today</h2>
                    <div class="bar"></div>
                    <p>Some members enter the profession because they're eager to make a difference. People tend to remember their members for an entire lifetime.
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-lg-6">
                <div class="dreidbgleft">
                    <img src="images/content/product/app-contact.jpg" alt="Buy this Course"/>
                </div>
            </div>
            <div class="col-md-12 col-lg-6">
            <div class="themeioan-form-contact form" id="contact-us-form" >
                           
                           <div>
                               <input onChange={handleOnChange} class="input-text required-field" type="text" name="contactName" id="contactName"
                                      placeholder="Name" title="Your Full Name"/>
                                      {errors.contactName && <div className="error-msg">{errors['contactName']}</div>}
                           </div>
                           <div>
                               <input onChange={handleOnChange} class="input-text required-field email-field" type="email" name="contactEmail"
                                      id="contactEmail" placeholder="Email" title="Your Email"/>
                                      {errors.contactEmail && <div className="error-msg">{errors['contactEmail']}</div>}
                           </div>
                           <div>
                               <input onChange={handleOnChange} class="input-text required-field" type="text" name="contactSubject" id="contactSubject"
                                      placeholder="Subject" title="Your Subject"/>
                                      {errors.contactSubject && <div className="error-msg">{errors['contactSubject']}</div>}
                           </div>
                           <div>
                       <textarea onChange={handleOnChange} class="input-text required-field" name="contactMessage" id="contactMessage"
                                 placeholder="Message" title="Your Message"></textarea>
                                      {errors.contactMessage && <div className="error-msg">{errors['contactMessage']}</div>}

                           </div>
                           {<input class={"color-two button"}  onClick={handleSubmit}  type="submit"
                                  value="Send Message"/>}
                                  
                       </div>
            </div>
        </div>
    </div>
</div>
{/* <!-- contact area end --> */}




    </>
     );
}
 
export default AppDevelopment;