// import './App.css';
import UbiquitousRoutes from "./pages/ubiquitousRoutes.jsx";


function App() {
 
  return (
    <div className="App">
      <UbiquitousRoutes/>
    </div>
  );
}

export default App;
