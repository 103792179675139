import React,{useState} from 'react';

const About = () => {
    const [readMore, setReadMore] = useState(false);
    const [id, setId] = useState('');
    const handleClickReadMore = (id) => {
        if (readMore) {
            setReadMore(false)
            setId(id);
        } else {
            setReadMore(true)
            setId(id);
        }
    }
    return ( 
        <>
           {/* <!-- breadcrumb banner content area start --> */}
    <div class="lernen_banner large bg-about">
        <div class="container">
            <div class="row">
                <div class="lernen_banner_title">
                    <h1>About Us</h1>
                    <div class="lernen_breadcrumb">
                        <div class="breadcrumbs">
									<span class="first-item">
									<a href="index.html">Homepage</a></span>
                            <span class="separator">&gt;</span>
                            <span class="last-item">About</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- end breadcrumb banner content area start -->

        <!-- services area start --> */}
    <div id="services" class="wrap-bg">
        {/* <!-- .container --> */}
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="dreidbgleft">
                        <img src="images/content/landing/about-image.jpg" alt="Buy this Course"/>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="section-title section-text-left text-left">
                        <div>
                            <h3>Our Vision </h3>
                            <div class="bar"></div>
                            <p>Our vision is to develop in a constant manner and grow as a major IT service provider to become a leading performer, in providing quality Web and Software Development solutions in the competitive global marketplace. Our professional, flexible and integrated process reflects in what we do. We always guide our customers to success. We have the ability to accelerate and quickly share the great work or products of your organization or business.</p>
                            {/* <p>Fortunately, we have been able to bring together a talented crew of professionals shaped and molded by their collective experiences in the agency, corporate and private industries, all of which possess outstanding talent. The synergy of what MacSyn InfoTech does comes from a blend of passion for success and the skill to help accelerate your organization.</p> */}
                            {/* <p>Our Mission to develop long-term and strategic partnerships with our clients, and help them to transform today’s challenges into tomorrow’s successes. And we are committed to meeting and exceeding the expectations in providing excellent service, unexpected quality and outstanding value to our people, clients and partners.</p> */}
                            <p>Our vision is clear, delivering the best training solutions for individuals and corporate clients. We specialize in tailoring programs that enhance skills, foster growth, and ensure success in today's dynamic professional landscape. Join us for a transformative learning experience, where excellence is not just a goal but a commitment we uphold in every training session.</p>                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- services area end -->

    <!-- services two area start --> */}

        <div id="services-two" class="wrap-bg">
        {/* <!-- .container --> */}
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <div class="section-title section-text-left text-left">
                        <div>
                            <h3>Our Mission</h3>
                            <div class="bar"></div>
                            <p>Our mission is to enhancing the business growth of our customers with creative Design and Development to deliver market-defining high-quality solutions that create value and reliable competitive advantage for our clients around the world.</p>
                            <p>Our mission is to deliver optimal solutions with quality and services at reasonable prices. For us customer satisfaction is given top place. We are very friendly in our dealings to the customers and it helps us retain existing clients and expand customer circle. We always try to improve the quality of our products by exploring innovative ideas.</p>
                            <p>Our Vision is to become one of the leading human resources consulting firm operating globally by maintaining our uncompromising principles and create value for all our stakeholders.</p>
                            <p>Our mission to by providing meticulous training in the trending technologies. We strive to make the aspiring candidates realize their IT dreams through our job-oriented training. We also want to be the most sought-after destination for software training.</p>
                            {/* <p>Our mission to offers Coworking Space in Bangalore. Our flexible office spaces are move-in ready and suitable to accommodate teams of any size. Instead of leasing your own office space, and furnishing it, now you can get better facilities with much less investment when you opt to go for techiecubsolutions's coworking space. Comparing rented or leased office space, Spacelance Coworking space will let you enjoy all the benefits of having your own office space with lot more features at an affordable price.</p> */}

{/*                             <ul class="themeioan_ul_icon">
                                <li><i class="fas fa-check-circle"></i> We list your options by state</li>
                                <li><i class="fas fa-check-circle"></i> Expert-created content and resources</li>
                                <li><i class="fas fa-check-circle"></i> Medical Connections Academy</li>
                            </ul> */}
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="dreidbg">
                        <img src="images/content/landing/about-mission.jpg" alt="Buy this Course"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
  {/*   <!-- services two area end -->
    <!-- features area start -->  */}
    <div id="features" class="wrap-bg wrap-bg-dark services">
        {/* <!-- .container --> */}
        <div class="container">
            {/* <!-- .row start --> */}
            <div class="row justify-content-center text-center">
                <div class="col-lg-8">
                  <div class="section-title with-p">
                    <h2>Our Values</h2>
                    <div class="bar"></div>
                    <p>To enrich the knowledge & skill sets of young software engineers by providing value added training in the areas of Software Development & Testing.
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">

                        <div class="single-features-light">
                            <div class="move">
                                <h4><a href="#">Integrity</a></h4>
                                <p>We embrace and uphold the highest standards of personal and professional ethics, honesty and trust.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">

                        <div class="single-features-light">
                            <div class="move">

                                <h4><a href="#">Responsibility</a></h4>
                                <p>We are responsible to fulfill our commitments to our people, clients,  </p>
                                {id == 1 && readMore ? <span id='more'>
                                            <p>
                                            partners and all our stakeholders with a clear understanding of the urgency and accountability inherent in those commitments.
                                            </p>

                                        </span> : ''}



                                        <div class="mt-25">
                                            <a style={{ cursor: 'pointer' }} onClick={() => handleClickReadMore(1)} class="button-light"><i class="fas fa-arrow-right"></i> {id == 1 && readMore ? 'Read Less' : 'Read More'}</a>
                                        </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">

                        <div class="single-features-light">
                            <div class="move">
                                <h4><a href="#">Passion for Excellence</a></h4>
                                <p>We are committed to delivering superior services with integrity,</p>
                                {id == 2 && readMore ? <span id='more'>
                                            <p>
                                            trust and appreciation to maintain our customer loyalty. We promise that we will deliver exceptional business results while making a positive contribution to our client’s organization.
                                            </p>

                                        </span> : ''}



                                        <div class="mt-25">
                                            <a style={{ cursor: 'pointer' }} onClick={() => handleClickReadMore(2)} class="button-light"><i class="fas fa-arrow-right"></i> {id == 2 && readMore ? 'Read Less' : 'Read More'}</a>
                                        </div>
                            </div>
                        </div>
                    </div>
                </div>
                   <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">                       
                        <div class="single-features-light">
                            <div class="move">
                             
                                <h4><a href="#">Empowerment</a></h4>
                                <p>We are empowered to deliver operational excellence through innovation and leadership at all levels.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                        <div class="single-features-light">
                            <div class="move">
                                <h4><a href="#">Collaboration</a></h4>
                                <p>We work as a team and share knowledge for continuous improvement, learning and innovation. </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                       <div class="single-features-light">
                           <div class="move">
                               <h4><a href="#">Respect</a></h4>
                               <p>We treat everyone with un compromising respect,   </p>
                               {id == 3 && readMore ? <span id='more'>
                                            <p>
                                            civility and fairness. And we always welcome diversity and differences of opinion.
                                            </p>

                                        </span> : ''}



                                        <div class="mt-25">
                                            <a style={{ cursor: 'pointer' }} onClick={() => handleClickReadMore(3)} class="button-light"><i class="fas fa-arrow-right"></i> {id == 3 && readMore ? 'Read Less' : 'Read More'}</a>
                                        </div>
                           </div>
                       </div>
                   </div>
                </div>
             
            {/* <!-- .row end --> */}
        </div>
        {/* <!-- .container end --> */}
    </div>
    {/* <!-- features area end -->


    <!-- why-us area start --> */}
   
    {/* <!-- why-us area end -->

    {/* <!-- members area end --> */}

    
   
    {/* <!-- members area end -->

    <!-- Feature Contact --> */}
    {/* <div id="feature_contact" class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="feature_contact_item fc_bg2 ">
                    <div class="opac">
                        <h3>Australia</h3>
                        <p>121 King Street, Melbourne Victoria 3000 Australia<br></br>
                            Email: ioan@drozd.at<br></br>
                            Phone: 123-456-7890</p>
                        <a href="services-detail.html" class="color-one btn-custom">Get in Touch <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="feature_contact_item fc_bg1 ">
                    <div class="opac">
                        <h3>Envato HQ</h3>
                        <p>PO Box 16122 Collins Street West Victoria 8007 <br></br>
                            Email: ioan@drozd.at<br></br>
                            Phone: 123-456-7890</p>
                        <a href="services-detail2.html" class="color-one btn-custom">Get in Touch <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
    {/* <!-- Feature Contact end --> */}
    
        </>
     );
}
 
export default About;