import React from 'react'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import pdf from "../Icons/General Instructions.pdf";


function DataScience() {
    return (
        <>
         <div class="lernen_banner large bg-dataScience">
        <div class="container">
            <div class="row">
                <div class="lernen_banner_title">
                    <h1>Data Science</h1>
                    <div class="lernen_breadcrumb">
                        <div class="breadcrumbs">
									<span class="first-item">
									<a href="index.html">Homepage</a></span>
                            <span class="separator">&gt;</span>
                            <span class="last-item">Data Science</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="features" class="wrap-bg">
       
       <div class="container">
           <div class="row justify-content-center text-center">
               <div class="col-lg-8">
                   <div class="section-title with-p">
                       <h2>About Course</h2>
                       <div class="bar"></div>
                       <p>Data Science Masters Program makes you proficient in tools and systems used by Data Science Professionals. It includes training on Statistics, Data Science, Python, Apache Spark & Scala, Tensorflow and Tableau. The curriculum has been determined by extensive research on 5000+ job descriptions across the globe..
                       </p>
                   </div>
               </div>
           </div>
         
           <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 1 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            {/* <!-- uses solid style --> */}
                            <i class="secondary-color fas fa-chalkboard-teacher fa-3x"></i>
                            <h4><a href="#">IT Experts as Trainers</a></h4>
                            <p>Learning a technology with a professional who is well expertise in that solve 60% of your needs.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 2 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-cubes fa-3x"></i>
                            <h4><a href="#">Fully Hands-on Training</a></h4>
                            <p>We support any training with more practical classes. So, we always prefers to give hands-on training.
</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 3 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-people-carry fa-3x"></i>
                            <h4><a href="#">Flexible Timings</a></h4>
                            <p>We can give the flexibility timings as per your schedule. So that it will not impact your office or personal work.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
            </div>
           {/* <div class="header-curriculum">
                
               
               
            </div> */}
            <h3>Curriculum Content</h3>
            {/* <h4 className='text-right  btn-primary'  ><a href={pdf}  Download>Download Curriculum PDF </a></h4> */}
           <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Introduction to Data Science
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Introduction to Data Analytics </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Introduction to Business Analytics </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Understanding Business Applications </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Data types and data Models </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Type of Business Analytics </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Evolution of Analytics </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Basic Operations in R Programming
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Introduction to R programming </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Types of Objects in R </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Naming standards in R </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Creating Objects in R </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Data Structure in R </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Matrix, Data Frame, String, Vectors </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Understanding Vectors & Data input in R </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Data Handling in R Programming
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Basic Operations in R </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Sub-setting Data </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Selecting (Keeping) Variables </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Excluding (Dropping) Variables </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Merging Data </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Sorting Data </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Visualization using R</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Introduction to Statistics
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Basic Statistics </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Measure of central tendency </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Types of Distributions </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Anova </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; F-Test </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Central Limit Theorem & applications </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Types of variables</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Introduction to Probability
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Standard Normal Distribution </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Normal Distribution </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Geometric Distribution </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Poisson Distribution </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Binomial Distribution </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Probability Mass Function </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Random Variable</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Data Visualization Techniques
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Bubble Chart </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Sparklines </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Waterfall chart </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Box Plot </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Line Charts </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Frequency Chart </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Bimodal & Multimodal Histograms</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Introduction to Machine Learning
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; What is Machine Learning? </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; When is Learning required? </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Data Mining </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Application Areas and Roles </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Types of Machine Learning </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Supervised Learning </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Unsupervised Learning</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Tableau Analytics
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Tableau Introduction </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Data connection to Tableau </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Various visualizations Techniques in Tableau </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Map based visualization using Tableau </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Reference Lines </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Adding Totals, sub totals, Captions</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Advanced Formatting Options</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            R integration in Tableau
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Integrating R code with Tableau </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Creating statistical model with dynamic inputs </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Visualizing R output in Tableau </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Case Study 1- Real time project with Twitter Data Analytics </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Case Study 2- Real time project with Google Finance </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Case Study 3- Real time project with IMDB Website </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                </Accordion>
                       {/* FAQ Start */}
                      <h3>Premium Course FAQs</h3>
            
            <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I miss a class?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            You will never miss a lecture at Techiecub! You can choose either of the two options: View the recorded session of the class available in your LMS OR You can attend the missed session, in any other live batch.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I have queries after I complete this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Your access to the Support Team is for lifetime and will be available 24/7. The team will help you in resolving queries, during and after the course.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How soon after Signing up would I get access to the Learning Content?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Post-enrolment, the LMS access will be instantly provided to you and will be available for lifetime. You will be able to access the complete set of previous class recordings, PPTs, PDFs, assignments. Moreover the access to our 24x7 support team will be granted instantly as well. You can start learning right away.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
                      {/* FAQ End */}

          
         
       </div>
      
   </div>
  

  
        </>
    )
}

export default DataScience;