import React,{useState} from 'react';
import swal from 'sweetalert';

function Footer() {
    const [email,setEmail]=useState('')
    const handleOnChange=(e)=>{
        setEmail(e.currentTarget.value);
    }
    const handleSubmit=()=>{
       		window.Email.send({
				Host: "mail.techiecubsolutions.com",
				Username : "info@techiecubsolutions.com",
				Password : "Redhat@19",
				To : 'info@techiecubsolutions.com',
				From : "info@techiecubsolutions.com",
				Subject : "Subscribe",
				Body : 'Email -> '+email,
				}).then(
					message => {
						// form.append('<div class="success-msg">Thank you! We will contact you shortly.</div>');
                      
                        swal('Thanks for the subscribe. We will connect with you shortly.','Message Send Success','success')
                        setEmail('')
						//formData.reset()
					}
				)
				.catch(
					err=>{console.log(err);}
                    // form.append('<div class="error-msg">Error occurred. Please try again later.</div>')
				);
    }


    return (
        <>
            <footer id="footer">
                <div class="footer-top">

                    <div class="container">
                        <div class="row">
                            <div class="col-xl-3 col-lg-4 col-sm-6">
                                <div class="f-widget-title">
                                    <h4>Techiecub Solutions</h4>
                                </div>
                                <div class="sigle-address">
                                    <div class="address-icon">
                                        <i class="fas fa-home"></i>
                                    </div>
                                    <p>Amruth Nagar ,Sector B <br></br>
                                     Amruthnagar,Bengaluru
                                    
                        </p>
                            </div>
                                    <div class="sigle-address">
                                        <div class="address-icon">
                                            <i class="far fa-envelope-open"></i>
                                        </div>
                                        <p>info@techiecubsolutions.com</p>
                                    </div>
                                    {/* <div class="sigle-address">
                                        <div class="address-icon">
                                            <i class="fas fa-headphones"></i>
                                        </div>
                                        <p>+917892132877</p>
                                    </div> */}
                                </div>
                                <div class="col-xl-2 offset-xl-1 col-lg-2 col-sm-6">
                                    <div class="f-widget-title">
                                        <h4>Quick</h4>
                                    </div>
                                    <div class="f-widget-link">
                                        <ul>
                                            <li><a href="/">Home</a></li>
                                            <li><a href="/about">About Us</a></li>
                                            <li><a href="/service-main">Services</a></li>                                        
                                            <li><a href="/Trainingmain">IT Trainings</a></li>
                                            <li><a href="/productmain">Product Development</a></li>
                                            <li><a href="/Careers">Careers</a></li>
                                            <li><a href="/contact">Contact</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-xl-2 offset-xl-1 col-lg-3 col-sm-6 pl-0 pr-0">
                                    <div class="f-widget-title">
                                        <h4>Links</h4>
                                    </div>
                                    <div class="f-widget-link">
                                        <ul>

                                            {/* <li><a href="/job-asistance">Placement Program</a></li> */}
                                            <li><a href="/internship-programe">Internships</a></li>
                                            <li><a href="/corporate-training">IT Training</a></li>
                                            <li><a href="/productmain">Product Development</a></li>
                                            <li><a href="/resource-outsourcing">Global IT Staffing</a></li>
                                            {/* <li><a href="/co-works">Co-Working Space</a></li> */}
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-sm-6">
                                    <div class="f-widget-title">
                                        <h4>Subscribe</h4>
                                    </div>

                                    <div class="footer-newsletter sigle-address subscribe-form-pt">
                                        <p>Subscribe to our newsletter!
                            Stay always in touch!</p>

                                        <div class="themeioan-form-newsletter form" >
                                            <div class="newslleter-call">
                                                <input class="input-text required-field" defaultValue={email} onChange={handleOnChange} type="text" placeholder="Your email"
                                                    title="Your email" />
                                                <div class="footer-submit" >
                                                    <input class="newsletter-submit" onClick={handleSubmit}  type="button" value="Send" />
                                                </div>
                                            </div>
                                        </div>

                                        <span>* Don't worry, we don't spam.</span>
                                    </div>

                                </div>
                            </div>
                            <div class="cd-top"><i class="fas fa-level-up-alt"></i></div>
                        </div>
                    </div>

                    <div class="container">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="copyright">
                                    <p>©2024 Techiecub . All Rights Reserved. Designed By <a href="#" title="ThemeIoan" target="_blank">Techiecub Solutions</a>
                                    </p>
                                </div>
                            </div>
                            <div class="col-sm-6">

                                <div class="text-right icon-round-white footer-social mt-25 mb-25">
                                    <a target='_blank' href="https://www.facebook.com/Techiecub-Solutions-104172641944747" title="Facebook"><i class="fab fa-facebook"></i></a>
                                    <a href="#" title="Twitter"><i class="fab fa-twitter"></i></a>
                                    <a href="#" title="Instagram"><i class="fab fa-instagram"></i></a>
                                    <a href="#" title="Google+"><i class="fab fa-google-plus"></i></a>
                                </div>
                            </div>
                        </div>


                        <div class="cd-top">Top</div>
                        <div class="cursor"></div>
                        <div class="cursor2"></div>

                    </div>
               
            </footer>
        </>
    );
}

export default Footer;