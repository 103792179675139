import React, { useEffect, useState } from 'react';
import valid from '../common/validate';
import sendEmail from '../common/mail';
import joi from 'joi';
import swal from 'sweetalert'

const TrainingMain = () => {


    const [data, setData] = useState({});
    const [errors, setErrors] = useState({});
    const [emailStatus, setEmailStatus] = useState(false)


    const schema = {
        contactName: joi.string().required().label('name'),
        contactEmail: joi.string().email().required(),
        contactSubject: joi.string().required().label('Subject'),
        contactMessage: joi.string().required().label('message')
    }







    // const sendEmail=(e)=> {
    //     e.preventDefault();

    //     emailjs
    //       .sendForm(
    //         "service_tv8sniw",
    //         "template_q0wsozo",
    //         e.target,
    //         "user_5Ci8rPqJhp90LnCWBPXln"
    //       )
    //       .then(
    //         (result) => {
    //           console.log(result.text);
    //           swal('Message Send Successfully','Message','success');
    //         },
    //         (error) => {
    //           console.log(error.text);
    //         }
    //       );
    //     e.target.reset();
    //   }
    const handleOnChange = (e) => {
        const input = { ...data };
        const error = { ...errors };
        const errorMessage = valid.validateProperty(e.currentTarget, schema);
        console.log(errorMessage)
        if (errorMessage) error[e.currentTarget.name] = errorMessage;
        else delete error[e.currentTarget.name];

        input[e.currentTarget.name] = e.currentTarget.value;
        setData(input);
        setErrors(error || {})

    }

    const handleSubmit = () => {
        setEmailStatus(true)
        window.Email.send({
            Host: "mail.techiecubsolutions.com",
            Username: "info@techiecubsolutions.com",
            Password: "Redhat@19",
            To: 'info@techiecubsolutions.com',
            From: "info@techiecubsolutions.com",
            Subject: "Enquery",
            Body: 'Name ->  ' + data.contactName + '<br>' + 'Email -> ' + data.contactEmail + '<br>' + 'Subject ->  ' + data.contactSubject + '<br>' + 'Message ->  ' + data.contactMessage + '<br>',
        }).then(
            message => {
                // form.append('<div class="success-msg">Thank you! We will contact you shortly.</div>');
                setEmailStatus(false)
                swal('Thanks for the details. We will connect with you shortly.', 'Message Send Success', 'success')
                //formData.reset()
            }
        )
            .catch(
                err => { console.log(err); setEmailStatus(false) }
                // form.append('<div class="error-msg">Error occurred. Please try again later.</div>')
            );
    }

    return (
        <>
            {/* <!-- breadcrumb banner content area start --> */}
            <div class="lernen_banner large bg-training">
                <div class="container">
                    <div class="row">
                        <div class="lernen_banner_title">
                            <h1>Learn
                                <span id="typed-strings">
                                    <span> &nbsp;to make Money</span>
                                    <span> &nbsp;about Life</span>
                                    <span> &nbsp;for result</span>
                                </span>
                                <span id="typed"></span>
                            </h1>

                            <div class="lernen_breadcrumb">
                                <div class="breadcrumbs">
                                    <span class="first-item">
                                        <a href="index.html">Homepage</a></span>
                                    <span class="separator">&gt;</span>
                                    <span class="last-item">Training</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- end breadcrumb banner content area start -->

    <!-- features area start --> */}
            <div id="features" class="wrap-bg">
                {/* <!-- .container --> */}
                <div class="container">
                    <div class="row justify-content-center text-center">
                        <div class="col-lg-8">
                            <div class="section-title with-p">
                                <h2> About Training </h2>
                                <div class="bar"></div>
                                <p>Training Courses are designed for continuing career development and are offered by a number of leading technical domain experts around the world.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                            {/* <!-- 1 --> */}
                            <div class="single-features-light">{/* <!-- single features --> */}
                                <div class="move">
                                    {/* <!-- uses solid style --> */}
                                    <i class="secondary-color fas fa-chalkboard-teacher fa-3x"></i>
                                    <h4><a href="#">Online Training</a></h4>
                                    <p>We offers intructor led and recorded session classes which offers learners the oppertunity to learn from the best teachers of country no matter where they reside.
                                        Our best e-courses enhance learners understanding of all the concepts.

                                        Along with that we provide :
                                        Individual attention will be provided during interactive queries handling sessions
                                        Personalised training  schdules can managed for professionals and freshers
                                        Option to re-watch, repeat exercies , re-read peer discussions and take the time they need to master concepts
                                        Lifelong LMS access and once enrolled can participate for any intructor session.</p>
                                    {/*                             <div class="feature_link">
                                <a href="#"><i class="fas fa-arrow-right"></i></a>
                            </div> */}
                                </div>
                            </div>{/* <!-- end single features --> */}
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                            {/* <!-- 2 --> */}
                            <div class="single-features-light">{/* <!-- single features --> */}
                                <div class="move">
                                    <i class="secondary-color fas fa-cubes fa-3x"></i>
                                    <h4><a href="#">Class Room Training</a></h4>
                                    <p>We provide facility to enroll for trainings that occures in classroom.It facilitates learner to have peaceful and studios environemt without disturbance.
                                        Our high-end techie classes provide projection of real-life projects.Users can make use of smart classes, projectors and work with production servers to deploy and maintain applications whether
                                        on-premise or on cloud.

                                        Classroom training helps learner to meet below requirements

                                        Allow for highly-skilled instructors to match the training level and style to the employees in the room.
                                        classes can also break off into brainstorming or role-playing groups.
                                        Most importantly, for complex or highly collaborative subjects, class room training provides
                                        the direct immersion experience employees need to learn from the material.
                                        Employees get a chance to learn from each other by personal interactiona and brianstorming.</p>
                                    {/*                             <div class="feature_link">
                                <a href="#"><i class="fas fa-arrow-right"></i></a>
                            </div> */}
                                </div>
                            </div>{/* <!-- end single features --> */}
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                            {/* <!-- 3 --> */}
                            <div class="single-features-light">{/* <!-- single features --> */}
                                <div class="move">
                                    <i class="secondary-color fas fa-people-carry fa-3x"></i>
                                    <h4><a href="#"> Free Demo clases </a></h4>
                                    <p>Experience the essence of our courses with free demo classes! Join us for a sneak peek into our engaging and insightful training sessions. Discover the quality of our instruction, interact with our expert trainers, and get a taste of the valuable skills you'll acquire. Don't miss this opportunity to explore the exciting world of learning awaiting you. Register now for our free demo classes!</p>
                                    {/* <div class="feature_link">
                                <a href="#"><i class="fas fa-arrow-right"></i></a>
                            </div> */}
                                </div>
                            </div>{/* <!-- end single features --> */}
                        </div>
                    </div>
                    {/* <!-- .row end --> */}
                </div>
                {/* <!-- .container end --> */}
            </div>
            {/* <!-- features area end -->


    <!-- why-us area start --> */}
            <div id="why-us" class="wrap-bg-second">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-xl-6 col-lg-5 services-detail-why why-us-left-bg2">
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-xl-6 col-lg-7 wrap-padding text-left">
                        <div class="section-title">
                            <div>
                                <h3>Get about the TechieCub Solutions service stats</h3>
                                <div class="bar"></div>
                            </div>
                            <p>Providing high quality web & mobile based products and also providing training.Get to know what is our core competency and Efficient training stats
                                <br /><br /></p>
                            <div class="row">
                                {/* <!-- #counter --> */}
                                <div class="col-xs-12 col-sm-12 col-md-4">
                                    <div class="info">
                                        {/* <!-- 1 --> */}
                                        <div class="themeioan_counter text-center">{/* <!-- single counter item --> */}
                                            <i class="secondary-color fas fa-chalkboard-teacher fa-3x"></i>
                                            <h4>10</h4>
                                            <p>Real-time Trainers</p>
                                        </div>{/* <!-- end single counter item --> */}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-4">
                                    {/* <!-- .row --> */}
                                    <div class="info">
                                        {/* <!-- 2 --> */}
                                        <div class="themeioan_counter text-center">{/* <!-- single counter item --> */}
                                            <i class="secondary-color fas fa-cubes fa-3x"></i>
                                            <h4>20</h4>
                                            <p>IT Courses</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-4">
                                    <div class="info">
                                        {/* <!-- 3 --> */}
                                        <div class="themeioan_counter text-center">{/* <!-- single counter item --> */}
                                            <i class="secondary-color fas fa-user-cog fa-3x"></i>
                                            <h4>10</h4>
                                            <p>IT Client</p>
                                        </div>{/* <!-- end single counter item --> */}
                                    </div>
                                </div>
                                {/*                         <!-- .row end -->
                        <!-- #counter area end --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*      <!-- why-us area end -->


     <!-- testimonials area start --> */}
            {/* <!-- Video Start Container--> */}
            {/*     <!-- testimonials area end -->

    <!-- services two area start --> */}
            <div id="services-two" class="wrap-bg wrap-bg-dark">
                {/* <!-- .container --> */}
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div class="section-title section-text-left text-left">
                                <div>
                                    <h3>Why Choose TechieCub solutions?</h3>
                                    <div class="bar"></div>
                                    <p>A Choice that makes a big difference in your career. Techiecub Solution is excellent training with real-time IT experts to helping its students & Employees to reach their goals and their training experiences, by providing innovative surroundings and faculties</p>
                                    <ul class="themeioan_ul_icon">
                                        <li><i class="fas fa-check-circle"></i> Flexible Timings</li>
                                        <li><i class="fas fa-check-circle"></i> Providing demo classes</li>
                                        <li><i class="fas fa-check-circle"></i> Customized Curriculum</li>
                                        <li><i class="fas fa-check-circle"></i> Training with MNC IT expert</li>
                                        <li><i class="fas fa-check-circle"></i> Fully Hands-on Training</li>
                                        <li><i class="fas fa-check-circle"></i> Affordable Fees</li>
                                        <li><i class="fas fa-check-circle"></i> Lab Support</li>
                                        <li><i class="fas fa-check-circle"></i> Practice on Industry-specific Use-cases & Real Projects</li>
                                        <li><i class="fas fa-check-circle"></i> Professional Certification training Programs</li>
                                        <li><i class="fas fa-check-circle"></i> Documents will be provided for the course training courses</li>
                                        <li><i class="fas fa-check-circle"></i> Get Global Recognised Certification after Course Completion</li>
                                        <li><i class="fas fa-check-circle"></i> Resume and Interview Preparation Assistance</li>

                                    </ul>
                                    <div class="mt-25 mb-50">
                                        <a href="#" class="color-two btn-custom ">Start Today <i class="fas fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="dreidbg">
                                <img src="images/content/services/taining-main-2.jpg" alt="Buy this Course" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*     <!-- services two area end -->

    <!-- contact area start --> */}
            <div id="contact" class="wrap-bg">
                <div class="container">
                    <div class="row justify-content-center text-center">
                        <div class="col-lg-8">
                            <div class="section-title with-p">
                                <h2>Get in Touch Today</h2>
                                <div class="bar"></div>
                                <p>Some members enter the profession because they're eager to make a difference. People tend to remember their members for an entire lifetime.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-lg-6">
                            <div class="dreidbgleft">
                                <img src="images/content/services/training-main-contact.jpg" alt="Buy this Course" />
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-6">
                            <div class="themeioan-form-contact form" id="contact-us-form" >

                                <div>
                                    <input onChange={handleOnChange} class="input-text required-field" type="text" name="contactName" id="contactName"
                                        placeholder="Name" title="Your Full Name" />
                                    {errors.contactName && <div className="error-msg">{errors['contactName']}</div>}
                                </div>
                                <div>
                                    <input onChange={handleOnChange} class="input-text required-field email-field" type="email" name="contactEmail"
                                        id="contactEmail" placeholder="Email" title="Your Email" />
                                    {errors.contactEmail && <div className="error-msg">{errors['contactEmail']}</div>}
                                </div>
                                <div>
                                    <input onChange={handleOnChange} class="input-text required-field" type="text" name="contactSubject" id="contactSubject"
                                        placeholder="Subject" title="Your Subject" />
                                    {errors.contactSubject && <div className="error-msg">{errors['contactSubject']}</div>}
                                </div>
                                <div>
                                    <textarea onChange={handleOnChange} class="input-text required-field" name="contactMessage" id="contactMessage"
                                        placeholder="Message" title="Your Message"></textarea>
                                    {errors.contactMessage && <div className="error-msg">{errors['contactMessage']}</div>}

                                </div>
                                {<input class={"color-two button"} onClick={handleSubmit} type="submit"
                                    value="Send Message" />}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- contact area end -->  */}


        </>
    );
}

export default TrainingMain;
