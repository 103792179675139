import React,{useState} from "react";
import joi from 'joi';
import valid from '../common/validate'
import swal from "sweetalert";

// import emailjs from "emailjs-com";
// import Header from "./header";
// import NavigationBar from "./navigationBar";
// import Footer from  "./footer";
// import * as PropTypes from 'prop-types';
// import logo from '../img/about-us.jpg'


const Careers = () => {

    const [data,setData]=useState({});
    const [errors,setErrors]=useState({});
    const [emailStatus,setEmailStatus]=useState(false)
     

    const schema = {
        contactName: joi.string().required().label('name'),
        contactEmail: joi.string().email().required(),
         contactPhone: joi.number().required().label('phone'),
         content: joi.string().required().label('Link')
    }
   
    
    


  

    // const sendEmail=(e)=> {
    //     e.preventDefault();
    
    //     emailjs
    //       .sendForm(
    //         "service_tv8sniw",
    //         "template_q0wsozo",
    //         e.target,
    //         "user_5Ci8rPqJhp90LnCWBPXln"
    //       )
    //       .then(
    //         (result) => {
    //           console.log(result.text);
    //           swal('Message Send Successfully','Message','success');
    //         },
    //         (error) => {
    //           console.log(error.text);
    //         }
    //       );
    //     e.target.reset();
    //   }
    const handleOnChange=(e)=>{
     const input={...data};
     const error={...errors};
     if (e.currentTarget.type == 'file') {
        e.preventDefault();
        let self = this;
        let fr = new FileReader();

            fr.readAsDataURL(e.currentTarget.files[0]);
            fr.onloadend = function (e) {

                input["FileSRC"] = e.target.result;
              
            };
        input['FileName'] = e.currentTarget.files[0].name;
    }else{
        const errorMessage = valid.validateProperty(e.currentTarget, schema);
        console.log(errorMessage)
        if (errorMessage) error[e.currentTarget.name] = errorMessage;
        else delete error[e.currentTarget.name];
    
        input[e.currentTarget.name] = e.currentTarget.value;
    }
     
     setData(input);
     setErrors(error || {})
    
    }

    const handleSubmit=()=>{
        setEmailStatus(true)
       		window.Email.send({
				Host: "mail.techiecubsolutions.com",
				Username : "info@techiecubsolutions.com",
				Password : "Redhat@19",
				To : 'info@techiecubsolutions.com',
				From : "info@techiecubsolutions.com",
				Subject : "Career",
				Body : 'Name ->  '+data.contactName+'<br>'+'Email -> '+data.contactEmail+'<br>'+'Phone ->  '+data.contactPhone+'<br>'+'Linkdien ->  '+data.content+'<br>',
                Attachments:[
                    {
                        name:data.FileName,
                        data:data.FileSRC
                    }
                ]
				}).then(
					message => {
						// form.append('<div class="success-msg">Thank you! We will contact you shortly.</div>');
                        setEmailStatus(false)
                        swal('Thanks for the details. We will connect with you shortly.','Message Send Success','success')
						//formData.reset()
					}
				)
				.catch(
					err=>{console.log(err);setEmailStatus(false)}
                    // form.append('<div class="error-msg">Error occurred. Please try again later.</div>')
				);
    }
  return ( 
    <>
    {/* <!-- breadcrumb banner content area start --> */}
    <div class="lernen_banner large bg-carrer">
        <div class="container">
            <div class="row">
                <div class="lernen_banner_title">
                    <h1>Careers</h1>
                    <div class="lernen_breadcrumb">
                        <div class="breadcrumbs">
									<span class="first-item">
									<a href="/">Homepage</a></span>
                            <span class="separator">&gt;</span>
                            <span class="last-item">Careers</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
{/*      <!-- end breadcrumb banner content area start -->

    <!-- contact area start --> */}
    <div id="contact" class="wrap-bg">
        <div class="container">
            <div class="row justify-content-center text-center">
                <div class="col-lg-8">
                    <div class="section-title with-p">
                        <h2>Working With Us</h2>
                        <div class="bar"></div>
                        <p>Our assets are our employees and we firmly believe in providing a homely atmosphere where they explore their knowledge required to grow professionally. Techiecub Solutions promotes a culture of individual and professional growth within an environment of continuous learning and team collaboration. By bringing your expertise to teams with diverse talents and backgrounds, you'll be both contributing and learning simultaneously during the course of project work. We foster open interactions with other colleagues at Techiecub Solutions through social and technical forums giving ample opportunity for all to explore avenues of growth in all aspects of ones tenure at Techiecubsolutions.Interested candidates can share the details along with Resume.</p>
                        
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-lg-6">
                    <div class="dreidbgleft">
                        <img src="images/content/landing/career.jpg" alt="Buy this Course"/>
                    </div>
                </div>
                <div class="col-md-12 col-lg-6">
                    <div class="themeioan-form-contact form" id="contact-us-form">
                        {/* <!-- Change Placeholder and Title --> */}
                        <div>
                            <input onChange={handleOnChange} class="input-text required-field" type="text" name="contactName" id="contactName"
                                   placeholder="Name" title="Your Full Name"/>
                                    {errors.contactName && <div className="error-msg">{errors['contactName']}</div>}
                        </div>
                        <div>
                            <input  onChange={handleOnChange} class="input-text required-field email-field" type="email" name="contactEmail"
                                   id="contactEmail" placeholder="Email" title="Your Email"/>
                                    {errors.contactEmail && <div className="error-msg">{errors['contactEmail']}</div>}
                        </div>
                        <div>
                            <input   onChange={handleOnChange} class="input-text required-field" type="number" name="contactPhone" id="contactPhone"
                                   placeholder="Phone" title="Your Contact Number"/>
                                    {errors.contactPhone && <div className="error-msg">{errors['contactPhone']}</div>}
                        </div>
                        <div>
                            <input   onChange={handleOnChange} class="input-text required-field" type="text" name="content" id="content"
                                   placeholder="LinkedIn Profile" title="Your LinkedIn Profile"/>
                                    {errors.content && <div className="error-msg">{errors['content']}</div>}
                        </div>
                        <div>
                        <input type="file"  onChange={handleOnChange} name="attachmentFile" id="attachmentFile" class="demoInputBox" />
                        </div>
                        <input class="color-two button" onClick={handleSubmit} type="submit"
                               value="Submit Profile"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- contact area end --> */}
    </>
   );
}
 
export default Careers;

