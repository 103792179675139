import React from 'react'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import pdf from "../Icons/General Instructions.pdf";


function Google() {
    return (
        <>
         <div class="lernen_banner large bg-google">
        <div class="container">
            <div class="row">
                <div class="lernen_banner_title">
                    <h1>Google</h1>
                    <div class="lernen_breadcrumb">
                        <div class="breadcrumbs">
									<span class="first-item">
									<a href="/">Homepage</a></span>
                            <span class="separator">&gt;</span>
                            <span class="last-item">Google</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="features" class="wrap-bg">
       
       <div class="container">
           <div class="row justify-content-center text-center">
               <div class="col-lg-8">
                   <div class="section-title with-p">
                       <h2>About Course</h2>
                       <div class="bar"></div>
                       <p> Google cloud platform provides enterprises a trusted, secure and scalable cloud solution. It provides a wide range of IAAS, PAAS and CAAS services. Learning Google cloud for enterprises is essential for its IT OPs team to keep on top of the things. This Google cloud training also paves the way for the advanced level of skill training such as cloud automation, container-related technologies, DevOps and many more. The Google cloud training course starts from the fundamental and takes your team to advanced level, exploring various domains such as storage, compute, networking, containers, security, deployment and monitoring. In this training, on Google Cloud platform a hands-on approach is taken to impart the cloud-skills. The GCP training covers the aspects of designing and planning a cloud solution architecture, managing and provisioning the cloud solution infrastructure, designing for security and compliance, analyzing and optimizing technical and business processes, managing implementations of cloud architecture, ensuring cloud solution and operations reliability. This Google cloud training helps your team prepare for Google Cloud Architect certification
                       </p>
                   </div>
               </div>
           </div>
         
           <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 1 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            {/* <!-- uses solid style --> */}
                            <i class="secondary-color fas fa-chalkboard-teacher fa-3x"></i>
                            <h4><a href="#">IT Experts as Trainers</a></h4>
                            <p>Learning a technology with a professional who is well expertise in that solve 60% of your needs.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 2 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-cubes fa-3x"></i>
                            <h4><a href="#">Fully Hands-on Training</a></h4>
                            <p>We support any training with more practical classes. So, we always prefers to give hands-on training.
</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 3 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-people-carry fa-3x"></i>
                            <h4><a href="#">Flexible Timings</a></h4>
                            <p>We can give the flexibility timings as per your schedule. So that it will not impact your office or personal work.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
            </div>
           {/* <div class="header-curriculum">
                
               
               
            </div> */}
            <h3>Curriculum Content</h3>
            {/* <h4 className='text-right  btn-primary'  ><a href={pdf}  Download>Download Curriculum PDF </a></h4> */}
           <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                             Introduction to Google Cloud Platform (GCP)
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Overview of Google Cloud Platform </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Getting started with Google Cloud Platform </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Virtual Machines in the Cloud </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Storage in the Cloud </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            GCP - Infrastructure Foundation
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Introduction to GCP networking </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Google cloud Virtual Networks </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Google cloud Virtual Machines </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Google cloud IAM</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Google cloud Data Storage Services </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Google cloud Resource Management </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Google cloud Resource Monitoring </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            GCP - Infrastructure Core Services
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Google cloud Interconnecting Networks</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Google cloud Load Balancing </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Google cloud Autoscaling </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Google cloud Managed Services</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            GCP - Containers, Scaling and Automation
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                         <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Application Infrastructure Services </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Application Development Services </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Containers in Google cloud </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Infrastructure Automation with GCP APIs</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Infrastructure Automation with Deployment Manager </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            GCP - Advanced services
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Containers in the Cloud</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Applications in the Cloud </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Developing on Google Cloud </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Deploying and Monitoring in the Cloud</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Big Data and Machine Learning services</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            GCP - Design and Process
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Defining the service</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Business logic layer design </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Data layer design </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Network design</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            GCP - Design for Enterprise
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Design for resiliency, scalability and DR</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Design for security </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Capacity planning and cost optimization </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Deployment,monitoring and alerting</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                 </Accordion>


                      {/* FAQ Start */}
                      <h3>Premium Course FAQs</h3>
            <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What will you learn as a part of this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Who should go for this training?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What are the skills that you will be learning with our DevOps course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What are the pre-requisites for this Course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I miss a class?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            You will never miss a lecture at Techiecub! You can choose either of the two options: View the recorded session of the class available in your LMS OR You can attend the missed session, in any other live batch.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I have queries after I complete this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Your access to the Support Team is for lifetime and will be available 24/7. The team will help you in resolving queries, during and after the course.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How soon after Signing up would I get access to the Learning Content?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Post-enrolment, the LMS access will be instantly provided to you and will be available for lifetime. You will be able to access the complete set of previous class recordings, PPTs, PDFs, assignments. Moreover the access to our 24x7 support team will be granted instantly as well. You can start learning right away.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
                      {/* FAQ End */}

          
         
       </div>
      
   </div>
  

  
        </>
    )
}

export default Google;