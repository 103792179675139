import React from 'react'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import pdf from "../Icons/General Instructions.pdf";


function PlSql() {
    return (
        <>
         <div class="lernen_banner large bg-plsql">
        <div class="container">
            <div class="row">
                <div class="lernen_banner_title">
                    <h1>Pl/Sql</h1>
                    <div class="lernen_breadcrumb">
                        <div class="breadcrumbs">
									<span class="first-item">
									<a href="/">Homepage</a></span>
                            <span class="separator">&gt;</span>
                            <span class="last-item">Pl/Sql</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="features" class="wrap-bg">
       
       <div class="container">
           <div class="row justify-content-center text-center">
               <div class="col-lg-8">
                   <div class="section-title with-p">
                       <h2>About Course</h2>
                       <div class="bar"></div>
                       <p>PL/SQL refers to Procedural Language extension to Structured Query Language (SQL). The main objective of PL/SQL is to join database language and procedural programming language. Oracle developed it to boost the capabilities of SQL. It allows you to store compiled code straight into the database. PLSQL also lets you to define triggers, which are subprograms that the database implements automatically in reply to specified events. PLSQL automatically obtains the power, portability and security of the Oracle database.
                       </p>
                   </div>
               </div>
           </div>
         
           <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 1 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            {/* <!-- uses solid style --> */}
                            <i class="secondary-color fas fa-chalkboard-teacher fa-3x"></i>
                            <h4><a href="#">IT Experts as Trainers</a></h4>
                            <p>Learning a technology with a professional who is well expertise in that solve 60% of your needs.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 2 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-cubes fa-3x"></i>
                            <h4><a href="#">Fully Hands-on Training</a></h4>
                            <p>We support any training with more practical classes. So, we always prefers to give hands-on training.
</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 3 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-people-carry fa-3x"></i>
                            <h4><a href="#">Flexible Timings</a></h4>
                            <p>We can give the flexibility timings as per your schedule. So that it will not impact your office or personal work.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
            </div>
           {/* <div class="header-curriculum">
                
               
               
            </div> */}
            <h3>Curriculum Content</h3>
            {/* <h4 className='text-right  btn-primary'  ><a href={pdf}  Download>Download Curriculum PDF </a></h4> */}
           <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Introduction to Oracle Database
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; List the features of Oracle Database 12c </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Categorize the different types of SQL statements </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Describe the data set used by the course </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Log on to the database using SQL Developer environment </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Save queries to files and use script files in SQL Developer </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Retrieve Data using the SQL SELECT Statement
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; List the capabilities of SQL SELECT statements </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Select All Columns </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Select Specific Columns </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Use Column Heading Defaults </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Use Arithmetic Operators </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Understand Operator Precedence </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Learn the DESCRIBE command to display the table structure </li></a>

                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Learn to Restrict and Sort Data
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Write queries that contain a WHERE clause to limit the output retrieved </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; List the comparison operators and logical operators that are used in a WHERE clause </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Describe the rules of precedence for comparison and logical operators </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Use character string literals in the WHERE clause </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Sort output in descending and ascending order </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Usage of Single-Row Functions to Customize Output
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Describe the differences between single row and multiple row functions </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Manipulate strings with character function in the SELECT and WHERE clauses </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Manipulate numbers with the ROUND, TRUNC, and MOD functions </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Perform arithmetic with date data </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Manipulate dates with the DATE functions </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Invoke Conversion Functions and Conditional Expressions
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Describe implicit and explicit data type conversion </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Use the TO_CHAR, TO_NUMBER, and TO_DATE conversion functions </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Nest multiple functions </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Apply the NVL, NULLIF, and COALESCE functions to data </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Use conditional IF THEN ELSE logic in a SELECT statement </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Aggregate Data Using the Group Functions
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Use the aggregation functions to produce meaningful reports </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Divide the retrieved data in groups by using the GROUP BY clause </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Exclude groups of data by using the HAVING clause </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Use Sub-queries to Solve Queries
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Describe the types of problem that sub-queries can solve </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Define sub-queries </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; List the types of sub-queries </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            The SET Operators
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Describe the SET operators </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Use a SET operator to combine multiple queries into a single query </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Control the order of rows returned </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Management of Schema Objects
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Add, Modify, and Drop a Column </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Add, Drop, and Defer a Constraint </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Create and Remove Indexes </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
             </Accordion>


                       {/* FAQ Start */}
                      <h3>Premium Course FAQs</h3>          
            <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I miss a class?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            You will never miss a lecture at Techiecub! You can choose either of the two options: View the recorded session of the class available in your LMS OR You can attend the missed session, in any other live batch.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I have queries after I complete this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Your access to the Support Team is for lifetime and will be available 24/7. The team will help you in resolving queries, during and after the course.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How soon after Signing up would I get access to the Learning Content?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Post-enrolment, the LMS access will be instantly provided to you and will be available for lifetime. You will be able to access the complete set of previous class recordings, PPTs, PDFs, assignments. Moreover the access to our 24x7 support team will be granted instantly as well. You can start learning right away.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
                      {/* FAQ End */}

          
         
       </div>
      
   </div>
  

  
        </>
    )
}

export default PlSql;