import React, { useState } from 'react';
import joi from 'joi';
import valid from '../../common/validate'
import swal from "sweetalert";

const WebDevelopment = () => {
    const [data, setData] = useState({});
    const [errors, setErrors] = useState({});
    const [emailStatus, setEmailStatus] = useState(false)
    const [readMore, setReadMore] = useState(false);
    const [id, setId] = useState('');
    const handleClickReadMore = (id) => {
        if (readMore) {
            setReadMore(false)
            setId(id);
        } else {
            setReadMore(true)
            setId(id);
        }
    }

    const schema = {
        contactName: joi.string().required().label('name'),
        contactEmail: joi.string().email().required(),
        contactSubject: joi.string().required().label('Subject'),
        contactMessage: joi.string().required().label('message')
    }







    // const sendEmail=(e)=> {
    //     e.preventDefault();

    //     emailjs
    //       .sendForm(
    //         "service_tv8sniw",
    //         "template_q0wsozo",
    //         e.target,
    //         "user_5Ci8rPqJhp90LnCWBPXln"
    //       )
    //       .then(
    //         (result) => {
    //           console.log(result.text);
    //           swal('Message Send Successfully','Message','success');
    //         },
    //         (error) => {
    //           console.log(error.text);
    //         }
    //       );
    //     e.target.reset();
    //   }
    const handleOnChange = (e) => {
        const input = { ...data };
        const error = { ...errors };
        const errorMessage = valid.validateProperty(e.currentTarget, schema);
        console.log(errorMessage)
        if (errorMessage) error[e.currentTarget.name] = errorMessage;
        else delete error[e.currentTarget.name];

        input[e.currentTarget.name] = e.currentTarget.value;
        setData(input);
        setErrors(error || {})

    }

    const handleSubmit = () => {
        setEmailStatus(true)
        window.Email.send({
            Host: "mail.techiecubsolutions.com",
            Username: "info@techiecubsolutions.com",
            Password: "Redhat@19",
            To: 'info@techiecubsolutions.com',
            From: "info@techiecubsolutions.com",
            Subject: "Web Development Enquery",
            Body: 'Name ->  ' + data.contactName + '<br>' + 'Email -> ' + data.contactEmail + '<br>' + 'Subject ->  ' + data.contactSubject + '<br>' + 'Message ->  ' + data.contactMessage + '<br>',
        }).then(
            message => {
                // form.append('<div class="success-msg">Thank you! We will contact you shortly.</div>');
                setEmailStatus(false)
                swal('Thanks for the details. We will connect with you shortly.', 'Message Send Success', 'success')
                //formData.reset()
            }
        )
            .catch(
                err => { console.log(err); setEmailStatus(false) }
                // form.append('<div class="error-msg">Error occurred. Please try again later.</div>')
            );
    }

    return (
        <>
            <div class="lernen_banner large bg-webDevelopment">
                <div class="container">
                    <div class="row">
                        <div class="lernen_banner_title">
                            <h1>Web Development</h1>
                            <div class="lernen_breadcrumb">
                                <div class="breadcrumbs">
                                    <span class="first-item">
                                        <a href="index.html">Homepage</a></span>
                                    <span class="separator">&gt;</span>
                                    <span class="last-item">Web Development</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





            {/* <!-- features area start --> */}
            <div id="features" class="wrap-bg">
                {/* <!-- .container --> */}
                <div class="container">
                    <div class="row justify-content-center text-center">
                        <div class="col-lg-8">
                            <div class="section-title with-p">
                                <h2>Web Design and Development</h2>
                                <div class="bar"></div>
                                <p>Techiecub Solutions' team of innovative Web Designers are focused on designing aesthetic portions of the website along with
                                    its usability. Web designers work will be intensively concerned on client website objectives and then move to an
                                    Information Architecture(IA) to set a information hierarachy about website. Then, web designers closly work on creating
                                    wireframes and finally move to design stage.

                                    To achieve aaesthetically  pleasing layout which offers exellant user experince,We designers in Techiecub Soulutions
                                    follow confine Web Design Principles

                                    Our Web Design Principles
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                            {/* <!-- 1 --> */}
                            <div class="single-features-light">
                                {/* <!-- single features --> */}
                                <div class="move">
                                    {/* <!-- uses solid style --> */}
                                    <i class="secondary-color fas fa-chalkboard-teacher fa-3x"></i>
                                    <h4><a href="#">Visual Hierarchy</a></h4>
                                    <p>Visual hierarchy is one of the most important principles behind good web design.
                                        Designers are experinced in choosing best visuals..
                                    </p>


                                    {/*                             <div class="feature_link">
                                <a href="#"><i class="fas fa-arrow-right"></i></a>
                            </div> */}
                                    {id == 1 && readMore ? <span id='more'>
                                        <p>
                                            to get the user attention.
                                            It is well known fact human eye perceives
                                            what it sees.
                                            Experts team is well versed in highlighting prominent portion of website which need more user attention.
                                            Visual hierarchy work as eye-catcher to users and without knowing much visualization will define everything.
                                        </p>

                                    </span> : ''}



                                    <div class="mt-25">
                                        <a style={{ cursor: 'pointer' }} onClick={() => handleClickReadMore(1)} class="button-light"><i class="fas fa-arrow-right"></i> {id == 1 && readMore ? 'Read Less' : 'Read More'}</a>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end single features --> */}
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                            {/* <!-- 2 --> */}
                            <div class="single-features-light">
                                {/* <!-- single features --> */}
                                <div class="move">
                                    <i class="secondary-color fas fa-cubes fa-3x"></i>
                                    <h4><a href="#">Divine Proportions</a></h4>
                                    <p>Techiecub solutions experts work on Golden Ration magical number 1.618 (φ) technique . Golden ratio designs are considered as aesthetically pleasent and
                                        catches attention of the client.</p>
                                    {/*                             <div class="feature_link">
                                <a href="#"><i class="fas fa-arrow-right"></i></a>
                            </div> */}
                                </div>
                            </div>
                            {/* <!-- end single features --> */}
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                            {/* <!-- 3 --> */}
                            <div class="single-features-light">
                                {/* <!-- single features --> */}
                                <div class="move">
                                    <i class="secondary-color fas fa-people-carry fa-3x"></i>
                                    <h4><a href="#">Hick’s Law</a></h4>
                                    <p>Hick's law states the more options the more difficult to take decision. In an era of infinite choice, people need better filters!
                                    </p>
                                    {id == 2 && readMore ? <span id='more'>
                                        <p>
                                            Our designers take care of eliminate distracting choices and add better filters for easier decision-making throughout the design process.
                                        </p>

                                    </span> : ''}



                                    <div class="mt-25">
                                        <a style={{ cursor: 'pointer' }} onClick={() => handleClickReadMore(2)} class="button-light"><i class="fas fa-arrow-right"></i> {id == 2 && readMore ? 'Read Less' : 'Read More'}</a>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end single features --> */}
                        </div>
                    </div>
                    {/* <!-- .row end --> */}
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                            {/* <!-- 1 --> */}
                            <div class="single-features-light">
                                {/* <!-- single features --> */}
                                <div class="move">
                                    {/* <!-- uses solid style --> */}
                                    <i class="secondary-color fas fa-chalkboard-teacher fa-3x"></i>
                                    <h4><a href="#"> Fitt’s Law</a></h4>
                                    <p>For Designing Fitt's law states the more frequency of usage of a feature directly propotionate the size of feature on the website.
                                        Designers focus on not even implementing the feature but right place and right size so that even phone users can use the feature
                                        {/* with handy design */}
                                        .</p>
                                    {/*                             <div class="feature_link">
                                <a href="#"><i class="fas fa-arrow-right"></i></a>
                            </div> */}
                                </div>
                            </div>
                            {/* <!-- end single features --> */}
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                            {/* <!-- 2 --> */}
                            <div class="single-features-light">
                                {/* <!-- single features --> */}
                                <div class="move">
                                    <i class="secondary-color fas fa-cubes fa-3x"></i>
                                    <h4><a href="#">Strategy-Driven Design </a></h4>
                                    <p>A site's pages need to convey relevancy to a visitor's interest and provide clear call-to-actions that will guide them to their next step in their visit.
                                        Our Web's creative team brings several years of web and agency .</p>
                                    {id == 3 && readMore ? <span id='more'>
                                        <p>
                                            Our proven success record across industries gives us insight into the best approach for creating a compelling design as well as the programmatic requirements for turning it into a functioning, engaging web page

                                        </p>

                                    </span> : ''}
                                    <div class="mt-25">
                                        <a style={{ cursor: 'pointer' }} onClick={() => handleClickReadMore(3)} class="button-light"><i class="fas fa-arrow-right"></i> {id == 3 && readMore ? 'Read Less' : 'Read More'}</a>
                                    </div>
                                    {/*                             <div class="feature_link">
                                <a href="#"><i class="fas fa-arrow-right"></i></a>
                            </div> */}
                                </div>
                            </div>
                            {/* <!-- end single features --> */}
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                            {/* <!-- 3 --> */}
                            <div class="single-features-light">
                                {/* <!-- single features --> */}
                                <div class="move">
                                    <i class="secondary-color fas fa-people-carry fa-3x"></i>
                                    <h4><a href="#">Maximum Effectiveness</a></h4>
                                    <p>Our expert designers team familiar with building precise blueprint with a clear idea to make the bussiness succeed.

                                        Designers are having good understanding of when to use repetitions, shapes , grid pattern to highlight first piece of information </p>
                                    {/*                             <div class="feature_link">
                                <a href="#"><i class="fas fa-arrow-right"></i></a>
                            </div> */}
                                </div>
                            </div>
                            {/* <!-- end single features --> */}
                        </div>
                    </div>
                </div>
                {/* <!-- .container end --> */}
            </div>

            {/* <!-- UI/UX starts here --> */}
            <div id="why-us-white">
                <div class="why-us-container why-us-left-bg5">
                    <div class="container">
                        <div class="row justify-content-center text-center">
                            <div class="col-lg-8">
                                <div class="section-title with-p">
                                    <h2>OUR UX/UI DESIGN PROCESS</h2>
                                    <div class="bar"></div>
                                    <p>Be it a website design and development project, dashboards for data visualization and analytics, Internet of Things systems or a consumer app, we always rely on a consistent and efficient design process. It includes a number of steps each with its own outcome and deliverables.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-5 col-xl-6 col-lg-6">
                        </div>
                        <div class="row">

                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                                <div class="single-features-light">
                                    <div class="section-title">
                                        <div>
                                            <h3>ANALYSIS AND UNDERSTANDING</h3>
                                            <div class="bar"></div>
                                        </div>
                                    </div>
                                    <p> We start with a profound analysis to understand the product. We conduct a series of stakeholder interviews, study your business goals and expectations, analyze the domain and competition around your future system. </p>
                                    {id == 4 && readMore ? <span id='more'>
                                        <p>
                                            It allows us to collect basic requirements, outline challenges and determine the technology constraints we’ll be dealing with.
                                        </p>

                                    </span> : ''}



                                    <div class="mt-25">
                                        <a style={{ cursor: 'pointer' }} onClick={() => handleClickReadMore(4)} class="button-light"><i class="fas fa-arrow-right"></i> {id == 4 && readMore ? 'Read Less' : 'Read More'}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                                <div class="single-features-light">
                                    <div class="section-title">
                                        <div>
                                            <h3>USER RESEARCH</h3>
                                            <div class="bar"></div>
                                        </div>
                                    </div>
                                    <p> As an experienced mobile and web development and design company, we place a particular focus on user research. Different business analysis techniques like empathy maps or vision boards help us learn as much as we can about your target audience. </p>
                                    {id == 5 && readMore ? <span id='more'>
                                        <p>
                                            We identify personas, describe their characteristics and build user scenarios which demonstrate all possible activities and user journeys across the system.
                                        </p>

                                    </span> : ''}



                                    <div class="mt-25">
                                        <a style={{ cursor: 'pointer' }} onClick={() => handleClickReadMore(5)} class="button-light"><i class="fas fa-arrow-right"></i> {id == 5 && readMore ? 'Read Less' : 'Read More'}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                                <div class="single-features-light">
                                    <div class="section-title">
                                        <div>
                                            <h3>INTERACTIONS AND FLOW</h3>
                                            <div class="bar"></div>
                                        </div>
                                    </div>
                                    <p> Having user scenarios map and system requirements, we elaborate the information structure. Information architecture visualizes interactions and gives you the first look of the product’s pages and screens. </p>
                                    {id == 6 && readMore ? <span id='more'>
                                        <p>
                                            Iteration by iteration, we move to paper prototypes (sketches) and then interactive wireframes, which already let you test the flow and functionality of the future system.
                                        </p>

                                    </span> : ''}



                                    <div class="mt-25">
                                        <a style={{ cursor: 'pointer' }} onClick={() => handleClickReadMore(6)} class="button-light"><i class="fas fa-arrow-right"></i> {id == 6 && readMore ? 'Read Less' : 'Read More'}</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- UI/UX ends here --> */}
            {/* <!-- features area start --> */}
            <div id="features" class="wrap-bg">
                {/* <!-- .container --> */}
                <div class="container">
                    <div class="row justify-content-center text-center">
                        <div class="col-lg-8">
                            <div class="section-title with-p">
                                <h2>Web Development </h2>
                                <div class="bar"></div>
                                <p>Our Web developers work on the design patterns and create functioning website to be used for realistic bussiness.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                            {/* <!-- 1 --> */}
                            <div class="single-features-light">
                                {/* <!-- single features --> */}
                                <div class="move">
                                    {/* <!-- uses solid style --> */}
                                    <i class="secondary-color fas fa-chalkboard-teacher fa-3x"></i>
                                    <h4><a href="#">Website Strategy & Planning</a></h4>
                                    <p>Our seasoned professionals work closely with clients to ensure that their web goals are in line with their marketing and overall strategic business objectives.
                                        Key Components to a Successful Website Strategy </p>
                                    {id == 7 && readMore ? <span id='more'>
                                        <p>
                                            <ul class="themeioan_ul_icon">
                                                <li><i class="fas fa-check-circle"></i>  Understand your target audience </li>
                                                <li><i class="fas fa-check-circle"></i>  Clearly define your goals & metrics for success</li>
                                                <li><i class="fas fa-check-circle"></i> Drive your audience to your website</li>
                                                <li><i class="fas fa-check-circle"></i> Engage your visitors</li>
                                            </ul>
                                        </p>

                                    </span> : ''}



                                    <div class="mt-25">
                                        <a style={{ cursor: 'pointer' }} onClick={() => handleClickReadMore(7)} class="button-light"><i class="fas fa-arrow-right"></i> {id == 7 && readMore ? 'Read Less' : 'Read More'}</a>
                                    </div>


                                    {/*                             <div class="feature_link">
                                <a href="#"><i class="fas fa-arrow-right"></i></a>
                            </div> */}
                                </div>
                            </div>
                            {/* <!-- end single features --> */}
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                            {/* <!-- 2 --> */}
                            <div class="single-features-light">
                                {/* <!-- single features --> */}
                                <div class="move">
                                    <i class="secondary-color fas fa-cubes fa-3x"></i>
                                    <h4><a href="#">Website Design</a></h4>
                                    <p>Web design is the merging of content strategy, branding and best-practice user experience and interface principles.
                                        Static designs can be enhanced through user interactivity and other media such as animations or videos.
                                       </p>
                                    {id == 8 && readMore ? <span id='more'>
                                        <p>
                                        A well-defined content and layout strategy acts as a guide for creating site visuals -- from global elements to individual page content.
                                        We follow our design principles :
                                            <ul class="themeioan_ul_icon">
                                                <li><i class="fas fa-check-circle"></i>  Visual Hierarchy </li>
                                                <li><i class="fas fa-check-circle"></i>  Divine Proportions</li>
                                                <li><i class="fas fa-check-circle"></i> Hick’s Law</li>
                                                <li><i class="fas fa-check-circle"></i> Fitt’s Law</li>
                                            </ul>
                                        </p>

                                    </span> : ''}



                                    <div class="mt-25">
                                        <a style={{ cursor: 'pointer' }} onClick={() => handleClickReadMore(8)} class="button-light"><i class="fas fa-arrow-right"></i> {id == 8 && readMore ? 'Read Less' : 'Read More'}</a>
                                    </div>

                                    {/*                             <div class="feature_link">
                                <a href="#"><i class="fas fa-arrow-right"></i></a>
                            </div> */}
                                </div>
                            </div>
                            {/* <!-- end single features --> */}
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                            {/* <!-- 3 --> */}
                            <div class="single-features-light">
                                {/* <!-- single features --> */}
                                <div class="move">
                                    <i class="secondary-color fas fa-people-carry fa-3x"></i>
                                    <h4><a href="#"> eCommerce</a></h4>
                                    <p>Open Source Consulting :
                                        With our experience building custom software applications, our track record for customer service, and our involvement in the Open Source community,
                                        we are able to offer Open Source consulting services as a natural extension of our </p>
                                    {id == 13 && readMore ? <span id='more'>
                                        <p>
                                        business.
                                        We provide the following open source consulting services: 
                                            <ul class="themeioan_ul_icon">

                                                <li><i class="fas fa-check-circle"></i>  Technology Strategy </li>
                                                <li><i class="fas fa-check-circle"></i>  -Application Development</li>
                                                <li><i class="fas fa-check-circle"></i> Web Performance</li>
                                                <li><i class="fas fa-check-circle"></i> Site Security Analysis</li>
                                            </ul>
                                        </p>

                                    </span> : ''}



                                    <div class="mt-25">
                                        <a style={{ cursor: 'pointer' }} onClick={() => handleClickReadMore(13)} class="button-light"><i class="fas fa-arrow-right"></i> {id == 13 && readMore ? 'Read Less' : 'Read More'}</a>
                                    </div>

                                    {/*                             <div class="feature_link">
                                <a href="#"><i class="fas fa-arrow-right"></i></a>
                            </div> */}
                                </div>
                            </div>
                            {/* <!-- end single features --> */}
                        </div>
                    </div>
                    {/* <!-- .row end --> */}
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                            {/* <!-- 1 --> */}
                            <div class="single-features-light">
                                {/* <!-- single features --> */}
                                <div class="move">
                                    {/* <!-- uses solid style --> */}
                                    <i class="secondary-color fas fa-chalkboard-teacher fa-3x"></i>
                                    <h4><a href="#"> Full Service Web Hostinga</a></h4>
                                    <p>We offers affordable, comprehensive managed hosting solutions with support plans to meet your site or application requirements.
                                        We offer configurations such as shared web hosting and dedicated servers. We can scale up or down based on predicted </p>

                                    {id == 9 && readMore ? <span id='more'>
                                        <p>
                                        traffic.
                                        our 24x7x365 support plans provide on-call support for mission critical web sites.
                                        Our Web Application Hosting offers:
                                            <ul class="themeioan_ul_icon">
                                                <li><i class="fas fa-check-circle"></i>  Customer Relationship Management Systems</li>
                                                <li><i class="fas fa-check-circle"></i>  E-Commerce</li>
                                                <li><i class="fas fa-check-circle"></i> Content Management Systems</li>
                                                <li><i class="fas fa-check-circle"></i> Custom-developed and third-party applications</li>
                                            </ul>
                                        </p>

                                    </span> : ''}



                                    <div class="mt-25">
                                        <a style={{ cursor: 'pointer' }} onClick={() => handleClickReadMore(9)} class="button-light"><i class="fas fa-arrow-right"></i> {id == 9 && readMore ? 'Read Less' : 'Read More'}</a>
                                    </div>

                                    {/*                             <div class="feature_link">
                                <a href="#"><i class="fas fa-arrow-right"></i></a>
                            </div> */}
                                </div>
                            </div>
                            {/* <!-- end single features --> */}
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                            {/* <!-- 2 --> */}
                            <div class="single-features-light">
                                {/* <!-- single features --> */}
                                <div class="move">
                                    <i class="secondary-color fas fa-cubes fa-3x"></i>
                                    <h4><a href="#">Website Support and Maintenance</a></h4>
                                    <p>We are committed to our customers’ success, and we understand that problems can and will arise.
                                        Therefore, we offer the following hours of operations for the different types of services listed below</p>

                                    {id == 10 && readMore ? <span id='more'>
                                        <p>
                                            <ul class="themeioan_ul_icon">
                                                <li><i class="fas fa-check-circle"></i>Physical access to collocated servers: 24x7 </li>
                                                <li><i class="fas fa-check-circle"></i>NOC Support: 24x7</li>
                                                <li><i class="fas fa-check-circle"></i>Remote Hands Requests for collocated servers: 24x7</li>
                                                <li><i class="fas fa-check-circle"></i>Remote access to collocated or hosted servers: 24x7</li>
                                            </ul>
                                        </p>

                                    </span> : ''}



                                    <div class="mt-25">
                                        <a style={{ cursor: 'pointer' }} onClick={() => handleClickReadMore(10)} class="button-light"><i class="fas fa-arrow-right"></i> {id == 10 && readMore ? 'Read Less' : 'Read More'}</a>
                                    </div>

                                    {/*                             <div class="feature_link">
                                <a href="#"><i class="fas fa-arrow-right"></i></a>
                            </div> */}
                                </div>
                            </div>
                            {/* <!-- end single features --> */}
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                            {/* <!-- 3 --> */}
                            <div class="single-features-light">
                                {/* <!-- single features --> */}
                                <div class="move">
                                    <i class="secondary-color fas fa-people-carry fa-3x"></i>
                                    <h4><a href="#">Maximum Effectiveness</a></h4>
                                    <p>Our expert designers team familiar with building precise blueprint with a clear idea to make the bussiness succeed.

                                        Designers are having good understanding of when to use repetitions, shapes , grid pattern to highlight first piece of information </p>
                                        {id == 11 && readMore ? <span id='more'>
                                        <p>
                                           
                                        </p>

                                    </span> : ''}



                                    <div class="mt-25">
                                        <a style={{ cursor: 'pointer' }} onClick={() => handleClickReadMore(11)} class="button-light"><i class="fas fa-arrow-right"></i> {id == 11 && readMore ? 'Read Less' : 'Read More'}</a>
                                    </div>
                                    {/*                             <div class="feature_link">
                                <a href="#"><i class="fas fa-arrow-right"></i></a>
                            </div> */}
                                </div>
                            </div>
                            {/* <!-- end single features --> */}
                        </div>
                    </div>
                </div>
                {/* <!-- .container end --> */}
            </div>




            {/* <!-- features area end -->

   

    <!-- contact area start --> */}
            <div id="contact" class="wrap-bg wrap-bg">
                <div class="container">
                    <div class="row justify-content-center text-center">
                        <div class="col-lg-8">
                            <div class="section-title with-p">
                                <h2>Get in Touch Today</h2>
                                <div class="bar"></div>
                                <p>Some members enter the profession because they're eager to make a difference. People tend to remember their members for an entire lifetime.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-lg-6">
                            <div class="dreidbgleft">
                                <img src="images/content/product/web-contact.jpg" alt="Buy this Course" />
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-6">
                            <div class="themeioan-form-contact form" id="contact-us-form" >

                                <div>
                                    <input onChange={handleOnChange} class="input-text required-field" type="text" name="contactName" id="contactName"
                                        placeholder="Name" title="Your Full Name" />
                                    {errors.contactName && <div className="error-msg">{errors['contactName']}</div>}
                                </div>
                                <div>
                                    <input onChange={handleOnChange} class="input-text required-field email-field" type="email" name="contactEmail"
                                        id="contactEmail" placeholder="Email" title="Your Email" />
                                    {errors.contactEmail && <div className="error-msg">{errors['contactEmail']}</div>}
                                </div>
                                <div>
                                    <input onChange={handleOnChange} class="input-text required-field" type="text" name="contactSubject" id="contactSubject"
                                        placeholder="Subject" title="Your Subject" />
                                    {errors.contactSubject && <div className="error-msg">{errors['contactSubject']}</div>}
                                </div>
                                <div>
                                    <textarea onChange={handleOnChange} class="input-text required-field" name="contactMessage" id="contactMessage"
                                        placeholder="Message" title="Your Message"></textarea>
                                    {errors.contactMessage && <div className="error-msg">{errors['contactMessage']}</div>}

                                </div>
                                {<input class={"color-two button"} onClick={handleSubmit} type="submit"
                                    value="Send Message" />}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- contact area end --> */}




        </>
    );
}

export default WebDevelopment;