import React from 'react'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import pdf from "../Icons/General Instructions.pdf";


function Ansible() {
    return (
        <>
         <div class="lernen_banner large bg-ansible">
        <div class="container">
            <div class="row">
                <div class="lernen_banner_title">
                    <h1>Ansible</h1>
                    <div class="lernen_breadcrumb">
                        <div class="breadcrumbs">
									<span class="first-item">
									<a href="/">Homepage</a></span>
                            <span class="separator">&gt;</span>
                            <span class="last-item">Ansible</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="features" class="wrap-bg">
       
       <div class="container">
           <div class="row justify-content-center text-center">
               <div class="col-lg-8">
                   <div class="section-title with-p">
                       <h2>About Course</h2>
                       <div class="bar"></div>
                       <p>Ansible doesn’t use an agent on the specific remote host. On the contrary, it applies SSH which is assumed to be installed one every system you wish to control. Moreover, Ansible is written in Python which should be installed on the remote host. This implies that you don’t have to launch a client server environment prior to applying Ansible. You can just initiate it from any of your machines. From the client’s perspective there is no knowledge of any Ansible server.
                       </p>
                   </div>
               </div>
           </div>
         
           <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 1 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            {/* <!-- uses solid style --> */}
                            <i class="secondary-color fas fa-chalkboard-teacher fa-3x"></i>
                            <h4><a href="#">IT Experts as Trainers</a></h4>
                            <p>Learning a technology with a professional who is well expertise in that solve 60% of your needs.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 2 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-cubes fa-3x"></i>
                            <h4><a href="#">Fully Hands-on Training</a></h4>
                            <p>We support any training with more practical classes. So, we always prefers to give hands-on training.
</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 3 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-people-carry fa-3x"></i>
                            <h4><a href="#">Flexible Timings</a></h4>
                            <p>We can give the flexibility timings as per your schedule. So that it will not impact your office or personal work.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
            </div>
           {/* <div class="header-curriculum">
                
               
               
            </div> */}
            <h3>Curriculum Content</h3>
            {/* <h4 className='text-right  btn-primary'  ><a href={pdf}  Download>Download Curriculum PDF </a></h4> */}
           <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Introduction to Ansible
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; What is Configuration Management? </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Automation of manual work on IT orchestration </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; What is Continuous Integration? </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; What is DevOps? </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; How CM is important for DevOps </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Overview of Ansible </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; What issues Solving by Ansible? </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Strengths of Ansible</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Concept and terminology on Ansible </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Architecture of Ansible </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Different from Chef and Puppet </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Getting start with Ansible
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Infrastructure Management </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Some prerequisites </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Set up on Windows / Linux </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Create basic inventory file </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Run Ansible Ad-Hoc command </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Testing with Vagrant </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Connect to your target nodes with SSH </li></a>

                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Inventory
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Basic inventory example </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Hosts and groups </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Inventory file for Multiple Servers </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Configure groups of servers or individual servers </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Manage users and groups </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Manage packages </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Manage files and directories </li></a>

                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Ansible Playbooks
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Overview of Playbooks </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Power Plays </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Handlers</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Variables </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Loops </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Asynchronous Actions </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Error Handler </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Manage Ansible code using git</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Task </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Running Playbook </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Docker containers with Ansible
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Introduction to Docker </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Docker application lifecycle </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Create Docker image with Ansible</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Synergy of containerization and automation </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Staring MySQL containers </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Data storage containers </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Highly available infrastructure with Ansible
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Spec up our inventory and host groups </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Using roles </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Configure our database backend</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Configure our web server front-end</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Configure a replicated filesystem </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Configure centralized logging</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Deployments with Ansible
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Deploying Application to local Vagrant environment </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Promote code to higher environment </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Alternative to Ansible compares</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Troubleshooting
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Best Practices on Ansible
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

            </Accordion>

                      {/* FAQ Start */}
                      <h3>Premium Course FAQs</h3>
            
            <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I miss a class?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            You will never miss a lecture at Techiecub! You can choose either of the two options: View the recorded session of the class available in your LMS OR You can attend the missed session, in any other live batch.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I have queries after I complete this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Your access to the Support Team is for lifetime and will be available 24/7. The team will help you in resolving queries, during and after the course.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How soon after Signing up would I get access to the Learning Content?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Post-enrolment, the LMS access will be instantly provided to you and will be available for lifetime. You will be able to access the complete set of previous class recordings, PPTs, PDFs, assignments. Moreover the access to our 24x7 support team will be granted instantly as well. You can start learning right away.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
                      {/* FAQ End */}

          
         
       </div>
      
   </div>
  

  
        </>
    )
}

export default Ansible;