import React from 'react'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import pdf from "../Icons/General Instructions.pdf";


function DockerKubernetes() {
    return (
        <>
         <div class="lernen_banner large bg-dockerKubernetes">
        <div class="container">
            <div class="row">
                <div class="lernen_banner_title">
                    <h1>Docker & Kubernetes</h1>
                    <div class="lernen_breadcrumb">
                        <div class="breadcrumbs">
									<span class="first-item">
									<a href="/">Homepage</a></span>
                            <span class="separator">&gt;</span>
                            <span class="last-item">Docker & Kubernetes</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="features" class="wrap-bg">
       
       <div class="container">
           <div class="row justify-content-center text-center">
               <div class="col-lg-8">
                   <div class="section-title with-p">
                       <h2>About Course</h2>
                       <div class="bar"></div>
                       <p>Docker is an open-source technology. It lets complete independence between applications and infrastructure. Developers and IT Ops can tap their potential and hence form better collaboration and innovation using Docker. Docker is an ecosystem of pertinent tools to build, deploy and manage virtualized application container on a common operation system. It offers container software that is appropriate for developers and teams who want to get started and are exploring container-based applications.
                       </p>
                   </div>
               </div>
           </div>
         
           <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 1 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            {/* <!-- uses solid style --> */}
                            <i class="secondary-color fas fa-chalkboard-teacher fa-3x"></i>
                            <h4><a href="#">IT Experts as Trainers</a></h4>
                            <p>Learning a technology with a professional who is well expertise in that solve 60% of your needs.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 2 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-cubes fa-3x"></i>
                            <h4><a href="#">Fully Hands-on Training</a></h4>
                            <p>We support any training with more practical classes. So, we always prefers to give hands-on training.
</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 3 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-people-carry fa-3x"></i>
                            <h4><a href="#">Flexible Timings</a></h4>
                            <p>We can give the flexibility timings as per your schedule. So that it will not impact your office or personal work.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
            </div>
           {/* <div class="header-curriculum">
                
               
               
            </div> */}
            <h3>Curriculum Content</h3>
            {/* <h4 className='text-right  btn-primary'  ><a href={pdf}  Download>Download Curriculum PDF </a></h4> */}
           <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What is Docker?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Use cases </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Major components of Docker </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Docker architecture fundamentals </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                                
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Docker architecture
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Docker images </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Docker registry </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Docker containers </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            The underlying technology
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Namespaces </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Control groups </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Union FS </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Installation of Docker
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Installation on Ubuntu via apt-get </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; installation of newer version of Docker </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Dockerizing applications
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; The hello world example </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Interactive container </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Daemonizing programs</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Container usage
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Running a web app in a container</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Investigating a container </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Port mapping </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Managing images
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Listing images </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Downloading images</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Finding images</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Data in containers
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Data volumes </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Host directories as data volume</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Host file as data volume</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Contributing to the ecosystem
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; What is Docker Hub? </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Registering on Docker Hub</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Command line login</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>

                      {/*course detail end   */}


                      {/* FAQ Start */}
                      <h3>Premium Course FAQs</h3>
            
            <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I miss a class?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            You will never miss a lecture at Techiecub! You can choose either of the two options: View the recorded session of the class available in your LMS OR You can attend the missed session, in any other live batch.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I have queries after I complete this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Your access to the Support Team is for lifetime and will be available 24/7. The team will help you in resolving queries, during and after the course.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How soon after Signing up would I get access to the Learning Content?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Post-enrolment, the LMS access will be instantly provided to you and will be available for lifetime. You will be able to access the complete set of previous class recordings, PPTs, PDFs, assignments. Moreover the access to our 24x7 support team will be granted instantly as well. You can start learning right away.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
                      {/* FAQ End */}

          
         
       </div>
      
   </div>
  

  
        </>
    )
}

export default DockerKubernetes;