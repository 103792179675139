import React, { useState } from "react";
const Service = () => {
  const [readMore, setReadMore] = useState(false);
  const [id, setId] = useState("");
  const handleClickReadMore = (id) => {
    if (readMore) {
      setReadMore(false);
      setId(id);
    } else {
      setReadMore(true);
      setId(id);
    }
  };
  return (
    <>
      <div id="services-two" class="wrap-bg wrap-bg-light text-left">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-6">
              <div class="section-title section-text-left">
                <div>
                  <h3>The power of Techiecub Solutions</h3>
                  <div class="bar"></div>
                  <p>
                    Our vision is to develop in a constant manner and grow as a
                    major IT service provider to become a leading performer, in
                    providing quality Web and Software Development solutions in
                    the competitive global marketplace. Our professional,
                    flexible and integrated process reflects in what we do. We
                    always guide our customers to success. We have the ability
                    to accelerate and quickly share the great work or products
                    of your organization or business.
                  </p>
                  <div class="mt-25 mb-50">
                    <a href="/about" class="color-two btn-custom ">
                      Start Today <i class="fas fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-lg-6">
                  <div class="min-h-350px services_image services_bg1"></div>
                </div>
                <div class="col-lg-6">
                  <div class="min-h-350px services_image services_bg2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="projects" class="wrap-bg wrap-bg-dark ">
        <div class="container">
          <div class="row justify-content-center text-center">
            <div class="col-lg-8">
              <div class="section-title with-p">
                <h2>Top Trending Technologies</h2>
                <div class="bar"></div>
                <p>
                  As per the revolution and increase in the technology, there
                  are various types of IT courses available for candidates to
                  seek growth towards their future success. Courses offer a
                  chance for the candidate to gain more knowledge and skill on a
                  specific platform. Doing IT certification courses helps to
                  give an exclusive solution to people and make it clear to
                  understand the essential concepts on the course. So, if you’re
                  the one who is looking forward to going for an IT course,
                  below we have listed some course
                </p>
              </div>
            </div>
          </div>

          <div class="row socialmediaCarousel">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
              <div class="themeioan_services">
                <article>
                  <div class="blog-content">
                    <div class="icon-space">
                      <div class="glyph-icon flaticon-030-test"></div>
                    </div>
                    <h5 class="title">
                      <a href="services-detail.html">Internet of things(IoT)</a>
                    </h5>
                    <p>
                      IoT enables everyday objects to be connected to the internet, allowing them to send and receive data. This connectivity facilitates automation, remote monitoring, and the exchange of information, contributing to the development of smart and efficient systems in various domains such as home automation, healthcare, transportation, and industrial processes
                      {id == 1 && readMore ? (
                        <span id="more">
                          <p></p>
                        </span>
                      ) : (
                        ""
                      )}
                      <div class="mt-25">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => handleClickReadMore(1)}
                          class="button-light"
                        >
                          <i class="fas fa-arrow-right"></i>{" "}
                          {id == 1 && readMore ? "Read Less" : "Read More"}
                        </a>
                      </div>
                    </p>
                  </div>
                </article>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
              <div class="themeioan_services">
                <article>
                  <div class="blog-content">
                    <div class="icon-space">
                      <div class="glyph-icon flaticon-009-skills"></div>
                    </div>
                    <h5 class="title">
                      <a href="services-detail.html">AI/ML</a>
                    </h5>
                    <p>
                      Machine Learning is one the hottest technology trending
                      these days. This course covers every aspect of machine
                      learning from thinking, development & deployment. More
                      than 10 projects of different domains are covered. It uses
                      scikit-learn for machine learning & pandas for data
                      wrangling. It's a very hands-on course.
                      {id == 2 && readMore ? (
                        <span id="more">
                          <p></p>
                        </span>
                      ) : (
                        ""
                      )}
                      <div class="mt-25">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => handleClickReadMore(2)}
                          class="button-light"
                        >
                          <i class="fas fa-arrow-right"></i>{" "}
                          {id == 2 && readMore ? "Read Less" : "Read More"}
                        </a>
                      </div>
                    </p>
                  </div>
                </article>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
              <div class="themeioan_services">
                <article>
                  <div class="blog-content">
                    <div class="icon-space">
                      <div class="glyph-icon flaticon-028-thinking"></div>
                    </div>
                    <h5 class="title">
                      <a href="services-detail.html">Big Data & Analytics</a>
                    </h5>
                    <p>
                      Data is the new oil” and knowledge of Hadoop framework is
                      a must-have skill for anyone interested to make a career
                      in this emerging field of Big Data Technologies. This
                      course builds a foundation for Big Data Technologies.
                      After completing this learners will get to know the
                      challenges faced in Big Data field.
                      {id == 3 && readMore ? (
                        <span id="more">
                          <p></p>
                        </span>
                      ) : (
                        ""
                      )}
                      <div class="mt-25">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => handleClickReadMore(3)}
                          class="button-light"
                        >
                          <i class="fas fa-arrow-right"></i>{" "}
                          {id == 3 && readMore ? "Read Less" : "Read More"}
                        </a>
                      </div>
                    </p>
                  </div>
                </article>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
              <div class="themeioan_services">
                <article>
                  <div class="blog-content">
                    <div class="icon-space">
                      <div class="glyph-icon flaticon-028-thinking"></div>
                    </div>
                    <h5 class="title">
                      <a href="services-detail.html">
                        Full Stack (ReactJs And NodeJs)
                      </a>
                    </h5>
                    <p>
                      Full Stack Development refers to the practice of working
                      with both the front-end (client-side) and back-end
                      (server-side) of an application. A full stack developer is
                      proficient in a variety of technologies and frameworks,
                      allowing them to handle all aspects of the development
                      process.
                      {id == 4 && readMore ? (
                        <span id="more">
                          <p> Full stack development with React and NodeJS.</p>
                        </span>
                      ) : (
                        ""
                      )}
                      <div class="mt-25">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => handleClickReadMore(4)}
                          class="button-light"
                        >
                          <i class="fas fa-arrow-right"></i>{" "}
                          {id == 4 && readMore ? "Read Less" : "Read More"}
                        </a>
                      </div>
                    </p>
                  </div>
                </article>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
              <div class="themeioan_services">
                <article>
                  <div class="blog-content">
                    <div class="icon-space">
                      <div class="glyph-icon flaticon-028-thinking"></div>
                    </div>
                    <h5 class="title">
                      <a href="services-detail.html">Cloud Computing</a>
                    </h5>
                    <p>

                      Embark on a transformative journey into the realm of Cloud Computing with our comprehensive courses. Learn the fundamentals and advanced concepts of cloud technologies, including Infrastructure as a Service (IaaS), Platform as a Service (PaaS), and Software as a Service (SaaS). Gain hands-on experience with leading cloud platforms like AWS, Azure, and Google Cloud.
                      {id == 5 && readMore ? (
                        <span id="more">
                          <p></p>
                        </span>
                      ) : (
                        ""
                      )}
                      <div class="mt-25">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => handleClickReadMore(5)}
                          class="button-light"
                        >
                          <i class="fas fa-arrow-right"></i>{" "}
                          {id == 5 && readMore ? "Read Less" : "Read More"}
                        </a>
                      </div>
                    </p>
                  </div>
                </article>
              </div>
            </div>

            {/* <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
              <div class="themeioan_services">
                <article>
                  <div class="blog-content">
                    <div class="icon-space">
                      <div class="glyph-icon flaticon-028-thinking"></div>
                    </div>
                    <h5 class="title">
                      <a href="services-detail.html">
                        Android Development With Kotlin
                      </a>
                    </h5>
                    <p>
                      Kotlin is very good for developing Mobile cross-platform,
                      compiling the code to Native libraries,processing
                      data,programming server-side of applications, for building
                      Web as well as Android applications. Kotlin is primarily
                      designed to allow compilation.
                      {id == 6 && readMore ? (
                        <span id="more">
                          <p>rate optimization. Best Services.</p>
                        </span>
                      ) : (
                        ""
                      )}
                      <div class="mt-25">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => handleClickReadMore(6)}
                          class="button-light"
                        >
                          <i class="fas fa-arrow-right"></i>{" "}
                          {id == 6 && readMore ? "Read Less" : "Read More"}
                        </a>
                      </div>
                    </p>
                  </div>
                </article>
              </div>
                      </div> */}

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
              <div class="themeioan_services">
                <article>
                  <div class="blog-content">
                    <div class="icon-space">
                      <div class="glyph-icon flaticon-028-thinking"></div>
                    </div>
                    <h5 class="title">
                      <a href="services-detail.html">Blockchain</a>
                    </h5>
                    <p>
                      A blockchain is a digitized, decentralized, public ledger
                      cryptocurrency transactions. Transactions such as the
                      exchange of assets or data are recorded in distributed
                      blocks which are shared among the participants in the
                      blockchain network
                      {id == 7 && readMore ? (
                        <span id="more">
                          <p></p>
                        </span>
                      ) : (
                        ""
                      )}
                      <div class="mt-25">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => handleClickReadMore(7)}
                          class="button-light"
                        >
                          <i class="fas fa-arrow-right"></i>{" "}
                          {id == 7 && readMore ? "Read Less" : "Read More"}
                        </a>
                      </div>
                    </p>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
