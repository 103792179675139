import React, { useState } from "react";

function JobAsistance() {
  const [readMore, setReadMore] = useState(false);
  const [id, setId] = useState("");
  const handleClickReadMore = (id) => {
    if (readMore) {
      setReadMore(false);
      setId(id);
    } else {
      setReadMore(true);
      setId(id);
    }
  };
  return (
    <>
      <div class="lernen_banner large bg-jobasistance">
        <div class="container">
          <div class="row">
            <div class="lernen_banner_title">
              <h1>Placement Program</h1>

              <div class="lernen_breadcrumb">
                <div class="breadcrumbs">
                  <span class="first-item">
                    <a href="index.html">Homepage</a>
                  </span>
                  <span class="separator">&gt;</span>
                  <span class="last-item">Placement Program</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- services style projects area start --> */}
      <div id="projects" class="wrap-bg">
        <div class="container">
          <div class="row justify-content-center text-center">
            <div class="col-lg-8">
              <div class="section-title with-p">
                <h2>Our Placement Program Service</h2>
                <div class="bar"></div>
                <p>
                  Techiecubsolutions provides refer jobs for freshers &
                  Experienced for various service based and product based
                  Companies
                </p>
              </div>
            </div>
          </div>
          {/* <!-- .row --> */}
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
              {/* <!-- 1 --> */}
              <div class="themeioan_services">
                <article>
                  {/* <!-- single services --> */}
                  <div class="blog-content">
                    <div class="icon-space">
                      <div class="glyph-icon flaticon-030-test"></div>
                    </div>
                    <h5 class="title">
                      <a href="services-detail.html">
                        Technical & Softskill Training
                      </a>
                    </h5>
                    <span>
                      <p>
                        Soft skills play a vital role after entering into an IT
                        company. It paves the way to the growth as a leader in
                        their career.
                      </p>
                      {id == 1 && readMore ? (
                        <span id="more">
                          <p>
                            To perform the role of a leader, one needs to
                            communicate with their team to keep them motivated.
                            So, without soft skills, it’s almost impossible to
                            emerge as a leader in a team. The essential soft
                            skills include, Communication Skills Vocabulary
                            Skills Debate Skills Interpersonal Skills
                          </p>
                        </span>
                      ) : (
                        ""
                      )}
                    </span>

                    <div class="mt-25">
                      <a
                        href="#"
                        onClick={() => handleClickReadMore(1)}
                        class="button-light"
                      >
                        <i class="fas fa-arrow-right"></i>{" "}
                        {id == 1 && readMore ? "Read Less" : "Read More"}
                      </a>
                    </div>
                  </div>
                </article>
                {/* <!-- end single services --> */}
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
              {/* <!-- 2 --> */}
              <div class="themeioan_services">
                <article>
                  {/* <!-- single services --> */}
                  <div class="blog-content">
                    <div class="icon-space">
                      <div class="glyph-icon flaticon-009-skills"></div>
                    </div>
                    <h5 class="title">
                      <a href="services-detail.html">
                        Interview & Resume Preparation
                      </a>
                    </h5>
                    <span>
                      <p>
                        The first impression is always the best. Resume writing
                        is the chief key attribute that shows the profile and
                        performance of a professional.
                      </p>
                      {id == 2 && readMore ? (
                        <span id="more">
                          <p>
                            A high-grade resume will always attract the
                            recruiters to call the candidate for an interview.
                            Since this is the first step towards a successful
                            career, we help you to prepare an effective CV that
                            has a significant priority. Formatting Tips Strictly
                            Avoid Errors Use Powerful Words
                          </p>
                        </span>
                      ) : (
                        ""
                      )}
                    </span>

                    <div class="mt-25">
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => handleClickReadMore(2)}
                        class="button-light"
                      >
                        <i class="fas fa-arrow-right"></i>{" "}
                        {id == 2 && readMore ? "Read Less" : "Read More"}
                      </a>
                    </div>
                  </div>
                </article>
                {/* <!-- end single services --> */}
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
              {/* <!-- 3 --> */}
              <div class="themeioan_services">
                <article>
                  {/* <!-- single services --> */}
                  <div class="blog-content">
                    <div class="icon-space">
                      <div class="glyph-icon flaticon-028-thinking"></div>
                    </div>
                    <h5 class="title">
                      <a href="services-detail.html">Placement Training</a>
                    </h5>
                    <span>
                      <p>
                        The dedicated and expert team of techiecubsolutions
                        placement cell is known for their regular scheduling of
                        interviews to the students with the top leading IT
                        companies.
                      </p>
                      {id == 3 && readMore ? (
                        <span id="more">
                          <p>
                            Being a leading software and business solutions
                            provider for the past eight years, Softlogic has
                            trustworthy national and international client
                            tie-ups.
                          </p>
                        </span>
                      ) : (
                        ""
                      )}
                    </span>

                    <div class="mt-25">
                      <a
                        href="#"
                        onClick={() => handleClickReadMore(3)}
                        class="button-light"
                      >
                        <i class="fas fa-arrow-right"></i>{" "}
                        {id == 3 && readMore ? "Read Less" : "Read More"}
                      </a>
                    </div>
                  </div>
                </article>
                {/* <!-- end single services --> */}
              </div>
            </div>
          </div>
          {/* <!-- .row end --> */}
        </div>
      </div>
      {/* <!-- services style projects area end --> */}
    </>
  );
}

export default JobAsistance;
