import React,{useState} from "react";
import joi from 'joi';
import valid from '../common/validate'
import swal from "sweetalert";
// import emailjs from "emailjs-com";
// import Header from "./header";
// import NavigationBar from "./navigationBar";
// import Footer from  "./footer";
// import * as PropTypes from 'prop-types';
// import logo from '../img/about-us.jpg'


const ContactUs = () => {

    const [data,setData]=useState({});
    const [errors,setErrors]=useState({});
    const [emailStatus,setEmailStatus]=useState(false)
     

    const schema = {
        contactName: joi.string().required().label('name'),
        contactEmail: joi.string().email().required(),
         contactSubject: joi.string().required().label('Subject'),
         contactMessage: joi.string().required().label('message')
    }
   
    
    


  

    // const sendEmail=(e)=> {
    //     e.preventDefault();
    
    //     emailjs
    //       .sendForm(
    //         "service_tv8sniw",
    //         "template_q0wsozo",
    //         e.target,
    //         "user_5Ci8rPqJhp90LnCWBPXln"
    //       )
    //       .then(
    //         (result) => {
    //           console.log(result.text);
    //           swal('Message Send Successfully','Message','success');
    //         },
    //         (error) => {
    //           console.log(error.text);
    //         }
    //       );
    //     e.target.reset();
    //   }
    const handleOnChange=(e)=>{
     const input={...data};
     const error={...errors};
     const errorMessage = valid.validateProperty(e.currentTarget, schema);
     console.log(errorMessage)
     if (errorMessage) error[e.currentTarget.name] = errorMessage;
     else delete error[e.currentTarget.name];
 
     input[e.currentTarget.name] = e.currentTarget.value;
     setData(input);
     setErrors(error || {})
    
    }

    const handleSubmit=()=>{
        setEmailStatus(true)
       		window.Email.send({
				Host: "mail.techiecubsolutions.com",
				Username : "info@techiecubsolutions.com",
				Password : "Redhat@19",
				To : 'info@techiecubsolutions.com',
				From : "info@techiecubsolutions.com",
				Subject : "Enquery",
				Body : 'Name ->  '+data.contactName+'<br>'+'Email -> '+data.contactEmail+'<br>'+'Subject ->  '+data.contactSubject+'<br>'+'Message ->  '+data.contactMessage+'<br>',
				}).then(
					message => {
						// form.append('<div class="success-msg">Thank you! We will contact you shortly.</div>');
                        setEmailStatus(false)
                        swal('Thanks for the details. We will connect with you shortly.','Message Send Success','success')
						//formData.reset()
					}
				)
				.catch(
					err=>{console.log(err);setEmailStatus(false)}
                    // form.append('<div class="error-msg">Error occurred. Please try again later.</div>')
				);
    }

  return ( 
    <>
    {/* <!-- breadcrumb banner content area start --> */}
    <div class="lernen_banner large bg-contact">
        <div class="container">
            <div class="row">
                <div class="lernen_banner_title">
                    <h1>Contact</h1>
                    <div class="lernen_breadcrumb">
                        <div class="breadcrumbs">
									<span class="first-item">
									<a href="index.html">Homepage</a></span>
                            <span class="separator">&gt;</span>
                            <span class="last-item">Contact</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- end breadcrumb banner content area start -->

    <!-- contact area start --> */}
    <div id="contact" class="wrap-bg">
        <div class="container">
        <div class="row justify-content-center text-center">
                <div class="col-lg-8">
                    <div class="section-title with-p">
                        <h2>Contact Us</h2>
                        <div class="bar"></div>
                        <p>Please submit your query and we look forward to answering your questions and providing quality service here at Techiecub Solutions
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-lg-6">
                    <div class="dreidbgleft">
                        <img src="images/content/landing/contact-image.jpg" alt="Buy this Course"/>
                    </div>
                </div>
            <div class="col-md-12 col-lg-6">
            <div class="themeioan-form-contact form" id="contact-us-form" >
                           
                           <div>
                               <input onChange={handleOnChange} class="input-text required-field" type="text" name="contactName" id="contactName"
                                      placeholder="Name" title="Your Full Name"/>
                                      {errors.contactName && <div className="error-msg">{errors['contactName']}</div>}
                           </div>
                           <div>
                               <input onChange={handleOnChange} class="input-text required-field email-field" type="email" name="contactEmail"
                                      id="contactEmail" placeholder="Email" title="Your Email"/>
                                      {errors.contactEmail && <div className="error-msg">{errors['contactEmail']}</div>}
                           </div>
                           <div>
                               <input onChange={handleOnChange} class="input-text required-field" type="text" name="contactSubject" id="contactSubject"
                                      placeholder="Subject" title="Your Subject"/>
                                      {errors.contactSubject && <div className="error-msg">{errors['contactSubject']}</div>}
                           </div>
                           <div>
                       <textarea onChange={handleOnChange} class="input-text required-field" name="contactMessage" id="contactMessage"
                                 placeholder="Message" title="Your Message"></textarea>
                                      {errors.contactMessage && <div className="error-msg">{errors['contactMessage']}</div>}

                           </div>
                           {<input class={"color-two button"}  onClick={handleSubmit}  type="submit"
                                  value="Send Message"/>}
                                  
                       </div>
            </div>
            </div>
        </div>
    </div>
    {/* <!-- contact area end --> */}

    <div class="themeioan_contact_map">
        <p><iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1943.3379392619092!2d77.5985785!3d13.0562914!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTPCsDAzJzIzLjMiTiA3N8KwMzUnNTUuNyJF!5e0!3m2!1sen!2sin!4v1621274430857!5m2!1sen!2sin"  width="600" height="450" style={{border:0}} allowfullscreen=""></iframe>
        </p>
    </div>
    </>
   );
}
 
export default ContactUs;

