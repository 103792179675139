import React from 'react';
//import logo from '../images/logo.png';

function Header() {
    return (
        <>

            <header id="header" class="transparent-header">
                 <div class="topheader top_header_light hidemobile">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-7">
                                <div class="address-icon">Please submit your query at<a style={{color:'darkorange'}} href="tel:6362626494">info@techiecubsolutions.com</a></div> </div>
                            <div class="col-lg-5 text-right">
{/*                                  <div class="custom-page-top">
                                    <a href="#">Login</a><a href="#">Register</a>
                                </div>  */}

                                <div class="social_top_header">
                                    <a target='_blank' href="https://www.facebook.com/Techiecub-Solutions-104172641944747"><i class="fab fa-facebook" aria-hidden="true"></i></a><a href="#"><i class="fab fa-twitter" aria-hidden="true"></i></a><a target='_blank' href="https://www.linkedin.com/company/79793300/admin"><i class="fab fa-linkedin" aria-hidden="true"></i></a><a href="#"><i class="fab fa-instagram" aria-hidden="true"></i></a><a href="#"><i class="fab fa-google-plus" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <nav class="navbar navbar-default navbar-expand-md navbar-light" id="navigation" data-offset-top="1">

                    <div class="container">

                        <div class="navbar-header">
                            <div style={{width:215}} class="navbar-brand"><a href="/"><img src="images/LogoHeader1.jpeg" alt="Logo" /></a></div>

                        </div>

                        <div class="burger-icon">
                            <div class="bar1"></div>
                            <div class="bar2"></div>
                            <div class="bar3"></div>
                        </div>
                        <div class="collapse navbar-collapse " id="navbarCollapse">
                            <ul class="nav navbar-nav ml-auto">

                                <li >
                                    <a href="/">Home</a>
                                    {/* <ul class="sub-menu">
                                        <li><a href="index.html" class="nav-link ">Home 1</a></li>
                                        <li><a href="index2.html" class="nav-link ">Home 2</a></li>
                                        <li><a href="index3.html" class="nav-link ">Home 3</a></li>
                                        <li><a href="index4.html" class="nav-link ">Home 4</a></li>
                                    </ul> */}
                                </li>
                                <li >
                                    <a href="/about">About Us</a>
                                    {/* <ul class="sub-menu">
                                        <li><a href="about.html">About Us</a></li>
                                        <li><a href="team.html" class="nav-link ">Our Team</a></li>
                                        <li><a href="team-single.html" class="nav-link ">Single Team</a></li>
                                        <li><a href="process.html" class="nav-link ">Our Process</a></li>
                                        <li><a href="faq.html" class="nav-link ">FAQs</a></li>
                                        <li><a href="elements.html" class="nav-link ">Elements</a></li>
                                        <li><a href="typography.html" class="nav-link ">Typography</a></li>
                                        <li><a href="coming-soon.html" class="nav-link ">Coming Soon</a></li>
                                        <li><a href="404.html" class="nav-link ">404 Error</a></li>
                                    </ul> */}
                                </li>
                                <li class="subnav">
                                    <a href="/service-main">Services</a>
                                    <ul class="sub-menu">
                                        {/* <li ><a class="nav-link " href="/job-asistance">Placement Program</a></li> */}
                                        <li><a class="nav-link " >IT Trainings</a></li>
                                        <li><a class="nav-link " >Internships</a></li>
                                        <li><a class="nav-link " >Application-Services</a></li>
                                        <li><a class="nav-link " >Global IT Staffing</a></li>
                                        {/* <li ><a class="nav-link " href="/co-works">Co-Working Space</a></li> */}
                                    </ul>
                                </li>
                                <li class="subnav">
                                    <a href="/trainingmain">IT Trainings</a>
                                    <ul class="sub-menu">
                                        
                                        <li className='subnav'>
                                            <a href="#">DevOps(CI/CD)</a>
                                            {/* <ul class="sub-menu">
                                                <li><a class="nav-link" >GIT/GitLab/Bitbucket</a></li>
                                                <li><a class="nav-link" >Jenkins</a></li>
                                                <li><a class="nav-link" >Ansible</a></li>
                                                <li><a class="nav-link" href="/ansible">Ansible</a></li>
                                                <li><a class="nav-link" >Docker</a></li>
                                                <li><a class="nav-link" >Kubernetes</a></li>
                                                <li><a class="nav-link" >Monitoring Tools</a></li>
                                            </ul> */}
                                        </li>
                                        {/* <li className='subnav'>
                                            <a href="#">Trending Technologies &nbsp; <i class="fas fa-crown"></i></a>
                                            <ul class="sub-menu">
                                                <li><a class="nav-link" href="/data-science">Data Science</a></li>
                                                <li><a class="nav-link" >AI&ML&DL</a></li>
                                                <li><a class="nav-link" >Internet of Things(IoT)</a></li>
                                                <li><a class="nav-link" >Cyber Security</a></li>
                                                <li><a class="nav-link" >Big Data & Analytics</a></li>
                                                <li><a class="nav-link" >Full-stack(React JS and Node JS)</a></li>
                                                {/*<li><a class="nav-link" href="/data-visualization">Data Visualization</a></li>
                                                <li><a class="nav-link" href="/android-kotlin">Android development with Kotlin</a></li>
                                                <li><a class="nav-link" >Blockchain</a></li>
                                            </ul>
                                        </li> */}
                                        <li className='subnav'>
                                            <a href="#">Cloud Computing</a>
                                            <ul class="sub-menu">
                                                <li><a class="nav-link">AWS</a></li>
                                                <li><a class="nav-link" >Azure</a></li>
                                                {/* <li><a class="nav-link" href="/ibm">IBM</a></li> */}
                                                <li><a class="nav-link" >Google</a></li>
                                            </ul>
                                        </li>
                                        <li className='subnav'>
                                            <a href="#">AI&ML&DL</a>
                                        </li>
                                        <li className='subnav'>
                                            <a href="#">Internet of Things(IoT)</a>
                                        </li>
                                        <li className='subnav'>
                                            <a href="#">Cyber Security</a>
                                        </li>
                                        {/*<li className='subnav'>
                                            <a href="#">Database</a>
                                            <ul class="sub-menu">
                                                <li><a class="nav-link" >Oracle</a></li>
                                                <li><a class="dropdown-item" href="/courses/sql">SQL</a></li>
                                                <li><a class="nav-link" >MySQL</a></li>
                                                <li><a class="nav-link" >Postgres</a></li>
                                                <li><a class="nav-link" >Mongo DB</a></li>
                                            </ul>
                                        </li>
                                        <li className='subnav'>
                                            <a href="#">Testing</a>
                                            <ul class="sub-menu">
                                                <li><a class="nav-link" >Selenium</a></li>
                                                <li><a class="nav-link" >Jmeter</a></li>
                                                <li><a class="nav-link" >QTP</a></li>
                                                <li><a class="nav-link" >Test Automation Tools</a></li>
                                            </ul>
                                        </li>

                                        {/* <li className='subnav'>
                                            <a href="#">Telecom</a>
                                            <ul class="sub-menu">
                                                <li><a class="nav-link" href="/CDMA">CDMA</a></li>
                                                <li><a class="nav-link" href="/GSM">GSM</a></li>
                                                <li><a class="nav-link" href="/GPRS">GPRS</a></li>
                                                <li><a class="nav-link" href="/1G-2G-3G">1G/2G/3G</a></li>
                                                <li><a class="nav-link" href="/LTE-4G">LTE/4G</a></li>
                                                <li><a class="nav-link" href="/5G">5G</a></li>
                                                <li><a class="nav-link" href="/SIP">SIP</a></li>
                                            </ul>
                                        </li>
                                        <li className='subnav'>
                                            <a href="#">Academic Course</a>
                                            <ul class="sub-menu">
                                                <li><a class="nav-link" href="/jee-main">JEE Main</a></li>
                                                <li><a class="nav-link" href="/jee-advance">JEE Advanced</a></li>
                                                <li><a class="nav-link" href="/KCET">KCET</a></li>
                                                <li><a class="nav-link" href="/CBSE">CBSE</a></li>
                                                <li><a class="nav-link" href="/ICSCE">ICSCE</a></li>
                                                <li><a class="nav-link" href="/PUC">PUC</a></li>
                                            </ul>
                                        </li> */}
                                    </ul>
                                </li>
                                <li class="subnav">
                                    <a href="/productmain">Product Development</a>
                                    <ul class="sub-menu">
                                        <li><a class="nav-link" href="/app-development">App Development</a></li>
                                        <li><a class="nav-link" href="/web-development">Web Development</a></li>
                                    </ul>
                                </li>
                                <li >
                                    <a href="/careers">Careers</a>
                                    {/* <ul class="sub-menu">
                                        <li><a href="contact.html" class="nav-link ">Contact</a></li>
                                        <li><a href="contact2.html" class="nav-link ">Contact 2</a></li>
                                    </ul> */}
                                </li>
                            </ul>
                            <div class="header-cta">
                                <a href="/contact" class="btn btn-1c">Contact</a>
                            </div>
                        </div>

                    </div>

                </nav>

            </header>

        </>
    );
}

export default Header;
