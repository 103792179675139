import React, { useState } from 'react';





function InternshipPrograme() {
    const [readMore, setReadMore] = useState(false);
    const [id, setId] = useState('');
    const handleClickReadMore = (id) => {
        if (readMore) {
            setReadMore(false)
            setId(id);
        } else {
            setReadMore(true)
            setId(id);
        }
    }
    return (
        <>


            <div class="lernen_banner large bg-internship">
                <div class="container">
                    <div class="row">
                        <div class="lernen_banner_title">


                            <div class="lernen_breadcrumb">
                                <div class="breadcrumbs">
                                    <span class="first-item">
                                        <a href="index.html">Homepage</a></span>
                                    <span class="separator">&gt;</span>
                                    <span class="last-item">Internship Programe</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* <!-- services style projects area start --> */}
            <div id="projects" class="wrap-bg">
                <div class="container">
                    <div class="row justify-content-center text-center">
                        <div class="col-lg-8">
                            <div class="section-title with-p">
                                <h2>Internship with Job Assistance</h2>
                                <div class="bar"></div>
                                <p>Techiecubsolutions has tied up with various product-based Companies to provide In-Depth Training and Live Projects for students in the final year as a part of their curriculum.
                                    The program starts with an intensive training program that is customized based on live and real-time projects.
                                </p>

                            </div>
                        </div>
                    </div>
                    {/* <!-- .row --> */}
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
                            {/* <!-- 1 --> */}
                            <div class="themeioan_services">
                                <article>
                                    {/* <!-- single services --> */}
                                    <div class="blog-content">
                                        <div class="icon-space">
                                            <div class="glyph-icon flaticon-030-test"></div>
                                        </div>
                                        <h5 class="title"><a href="services-detail.html">Project Management</a>
                                        </h5>
                                        <p>We help in gaining project management knowledge and skillset required to manage an entire project on your own based on both
                                            Waterfall and Agile. The highly skilled mentor helps in complete the Case Study of the Project from the beginning until it ends.
                                        </p>
                                        {id == 1 && readMore ? <span id='more'>
                                            <p>
                                                This real-life exposure helps in acquiring the skills needed to become successful in the profession.
                                            </p>

                                        </span> : ''}



                                        <div class="mt-25">
                                            <a style={{ cursor: 'pointer' }} onClick={() => handleClickReadMore(1)} class="button-light"><i class="fas fa-arrow-right"></i> {id == 1 && readMore ? 'Read Less' : 'Read More'}</a>
                                        </div>
                                        {/*                                         <div class="mt-25">
                                            <a href="services-detail.html" class="button-light"><i class="fas fa-arrow-right"></i> Read More</a>
                                        </div> */}
                                    </div>
                                </article>
                                {/* <!-- end single services --> */}
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
                            {/* <!-- 2 --> */}
                            <div class="themeioan_services">
                                <article>
                                    {/* <!-- single services --> */}
                                    <div class="blog-content">
                                        <div class="icon-space">
                                            <div class="glyph-icon flaticon-009-skills"></div>
                                        </div>
                                        <h5 class="title"><a href="services-detail.html">Training Mentor</a></h5>
                                        <p>Mentors focus in a highly transactional way on the development of new skills and refreshing old skills. Various Trainer Led activities, discussions, and exercises help to increase confidence. A complete ownership is with a trainer to impart skills and provide timely support.

                                        </p>
                                        {id == 2 && readMore ? <span id='more'>
                                            <p>
                                                Mentor identifies the Goal at the start of session and coaching intervention
                                            </p>

                                        </span> : ''}



                                        <div class="mt-25">
                                            <a style={{ cursor: 'pointer' }} onClick={() => handleClickReadMore(2)} class="button-light"><i class="fas fa-arrow-right"></i> {id == 2 && readMore ? 'Read Less' : 'Read More'}</a>
                                        </div>
                                        {/*                                         <div class="mt-25">
                                            <a href="services-detail.html" class="button-light"><i class="fas fa-arrow-right"></i> Read More</a>
                                        </div> */}
                                    </div>
                                </article>
                                {/* <!-- end single services --> */}
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
                            {/* <!-- 3 --> */}
                            <div class="themeioan_services">
                                <article>
                                    {/* <!-- single services --> */}
                                    <div class="blog-content">
                                        <div class="icon-space">
                                            <div class="glyph-icon flaticon-028-thinking"></div>
                                        </div>
                                        <h5 class="title"><a href="services-detail.html">Presentation</a>
                                        </h5>
                                        <p>Effective Presentation is always a means of communication to various speaking situations such as addressing a meeting, talking to a group, or briefing a team.
                                            We provide tips and step by step guide to delivering a professional and effective presentation.
                                        </p>
                                        {id == 3 && readMore ? <span id='more'>
                                            <p>
                                                Along with that, we help in providing more detailed information about presenting and communicating in various circumstances.
                                            </p>

                                        </span> : ''}



                                        <div class="mt-25">
                                            <a style={{ cursor: 'pointer' }} onClick={() => handleClickReadMore(3)} class="button-light"><i class="fas fa-arrow-right"></i> {id == 3 && readMore ? 'Read Less' : 'Read More'}</a>
                                        </div>
                                        {/*                                         <div class="mt-25">
                                            <a href="services-detail.html" class="button-light"><i class="fas fa-arrow-right"></i> Read More</a>
                                        </div> */}
                                    </div>
                                </article>
                                {/* <!-- end single services --> */}
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
                            {/* <!-- 1 --> */}
                            <div class="themeioan_services">
                                <article>
                                    {/* <!-- single services --> */}
                                    <div class="blog-content">
                                        <div class="icon-space">
                                            <div class="glyph-icon flaticon-004-elearning"></div>
                                        </div>
                                        <h5 class="title"><a href="services-detail.html">Team Work</a>
                                        </h5>
                                        <p>Members alignment to carry out the capstone project collaboratively helps in achieving big goals in less time. We use the concept of bringing several individuals together
                                            in order to efficiently and effectively complete a 
                                        </p>
                                        {id == 4 && readMore ? <span id='more'>
                                            <p>
                                            project and helps project professionals
                                                to overcome obstacles by exchanging their expertise.Teamwork helps to well manage the resources and speeds up the achievements.
                                            </p>

                                        </span> : ''}



                                        <div class="mt-25">
                                            <a style={{ cursor: 'pointer' }} onClick={() => handleClickReadMore(4)} class="button-light"><i class="fas fa-arrow-right"></i> {id == 4 && readMore ? 'Read Less' : 'Read More'}</a>
                                        </div>
                                        {/*                                         <div class="mt-25">
                                            <a href="services-detail.html" class="button-light"><i class="fas fa-arrow-right"></i> Read More</a>
                                        </div> */}
                                    </div>
                                </article>
                                {/* <!-- end single services --> */}
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
                            {/* <!-- 2 --> */}
                            <div class="themeioan_services">
                                <article>
                                    {/* <!-- single services --> */}
                                    <div class="blog-content">
                                        <div class="icon-space">
                                            <div class="glyph-icon flaticon-018-presentation-1"></div>
                                        </div>
                                        <h5 class="title"><a href="services-detail.html">Certification</a></h5>
                                        <p>TechieCub solutions provide authenticated Certifications on the basis of assessment of individual skills.
                                            It helps in recognizing and documents your experience, knowledge, and education. Once certified individual will definitely enhance the profession
                                        </p>
                                        {/*                                         <div class="mt-25">
                                            <a href="services-detail.html" class="button-light"><i class="fas fa-arrow-right"></i> Read More</a>
                                        </div> */}
                                    </div>
                                </article>
                                {/* <!-- end single services --> */}
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
              {/* <!-- 1 --> */}
              <div class="themeioan_services">
                <article>
                  {/* <!-- single services --> */}
                  <div class="blog-content">
                    <div class="icon-space">
                      <div class="glyph-icon flaticon-030-test"></div>
                    </div>
                    <h5 class="title">
                      <a href="services-detail.html">
                        Technical & Softskill Training
                      </a>
                    </h5>
                    <span>
                      <p>
                        Soft skills play a vital role after entering into an IT
                        company. It paves the way to the growth as a leader in
                        their career.
                      </p>
                      {id == 1 && readMore ? (
                        <span id="more">
                          <p>
                            To perform the role of a leader, one needs to
                            communicate with their team to keep them motivated.
                            So, without soft skills, it’s almost impossible to
                            emerge as a leader in a team. The essential soft
                            skills include, Communication Skills Vocabulary
                            Skills Debate Skills Interpersonal Skills
                          </p>
                        </span>
                      ) : (
                        ""
                      )}
                    </span>

                    <div class="mt-25">
                      <a
                        href="#"
                        onClick={() => handleClickReadMore(1)}
                        class="button-light"
                      >
                        <i class="fas fa-arrow-right"></i>{" "}
                        {id == 1 && readMore ? "Read Less" : "Read More"}
                      </a>
                    </div>
                  </div>
                </article>
                {/* <!-- end single services --> */}
              </div>
            </div>

                    </div>
                    {/* <!-- .row end --> */}
                    <div class="row">
           
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
              {/* <!-- 2 --> */}
              <div class="themeioan_services">
                <article>
                  {/* <!-- single services --> */}
                  <div class="blog-content">
                    <div class="icon-space">
                      <div class="glyph-icon flaticon-009-skills"></div>
                    </div>
                    <h5 class="title">
                      <a href="services-detail.html">
                        Interview & Resume Preparation
                      </a>
                    </h5>
                    <span>
                      <p>
                        The first impression is always the best. Resume writing
                        is the chief key attribute that shows the profile and
                        performance of a professional.
                      </p>
                      {id == 2 && readMore ? (
                        <span id="more">
                          <p>
                            A high-grade resume will always attract the
                            recruiters to call the candidate for an interview.
                            Since this is the first step towards a successful
                            career, we help you to prepare an effective CV that
                            has a significant priority. Formatting Tips Strictly
                            Avoid Errors Use Powerful Words
                          </p>
                        </span>
                      ) : (
                        ""
                      )}
                    </span>

                    <div class="mt-25">
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => handleClickReadMore(2)}
                        class="button-light"
                      >
                        <i class="fas fa-arrow-right"></i>{" "}
                        {id == 2 && readMore ? "Read Less" : "Read More"}
                      </a>
                    </div>
                  </div>
                </article>
                {/* <!-- end single services --> */}
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
              {/* <!-- 3 --> */}
              <div class="themeioan_services">
                <article>
                  {/* <!-- single services --> */}
                  <div class="blog-content">
                    <div class="icon-space">
                      <div class="glyph-icon flaticon-028-thinking"></div>
                    </div>
                    <h5 class="title">
                      <a href="services-detail.html">Placement Training</a>
                    </h5>
                    <span>
                      <p>
                        The dedicated and expert team of techiecubsolutions
                        placement cell is known for their regular scheduling of
                        interviews to the students with the top leading IT
                        companies.
                      </p>
                      {id == 3 && readMore ? (
                        <span id="more">
                          <p>
                            Being a leading software and business solutions
                            provider for the past eight years, Softlogic has
                            trustworthy national and international client
                            tie-ups.
                          </p>
                        </span>
                      ) : (
                        ""
                      )}
                    </span>

                    <div class="mt-25">
                      <a
                        href="#"
                        onClick={() => handleClickReadMore(3)}
                        class="button-light"
                      >
                        <i class="fas fa-arrow-right"></i>{" "}
                        {id == 3 && readMore ? "Read Less" : "Read More"}
                      </a>
                    </div>
                  </div>
                </article>
                {/* <!-- end single services --> */}
              </div>
            </div>
          </div>
                </div>
            </div>
            {/* <!-- services style projects area end --> */}

        </>
    )
}

export default InternshipPrograme;