import React from 'react'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import pdf from "../Icons/General Instructions.pdf";


function MachineLearning() {
    return (
        <>
         <div class="lernen_banner large bg-machineLearning">
        <div class="container">
            <div class="row">
                <div class="lernen_banner_title">
                <h1>Machine Learning</h1>
                    <div class="lernen_breadcrumb">
                        <div class="breadcrumbs">
									<span class="first-item">
									<a href="/">Homepage</a></span>
                            <span class="separator">&gt;</span>
                            <span class="last-item">Machine Learning</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="features" class="wrap-bg">
       
       <div class="container">
           <div class="row justify-content-center text-center">
               <div class="col-lg-8">
                   <div class="section-title with-p">
                       <h2>About Course</h2>
                       <div class="bar"></div>
                       <p>Machine Learning is one the hottest technology trending these days. This course covers every aspect of machine learning from thinking, development & deployment. More than 10 projects of different domains are covered. It uses scikit-learn for machine learning & pandas for data wrangling. It's a very hands-on course. Code is public & have around 500 stars. The use cases are from different companies.
                       </p>
                   </div>
               </div>
           </div>
         
           <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 1 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            {/* <!-- uses solid style --> */}
                            <i class="secondary-color fas fa-chalkboard-teacher fa-3x"></i>
                            <h4><a href="#">IT Experts as Trainers</a></h4>
                            <p>Learning a technology with a professional who is well expertise in that solve 60% of your needs.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 2 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-cubes fa-3x"></i>
                            <h4><a href="#">Fully Hands-on Training</a></h4>
                            <p>We support any training with more practical classes. So, we always prefers to give hands-on training.
</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 3 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-people-carry fa-3x"></i>
                            <h4><a href="#">Flexible Timings</a></h4>
                            <p>We can give the flexibility timings as per your schedule. So that it will not impact your office or personal work.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
            </div>
           {/* <div class="header-curriculum">
                
               
               
            </div> */}
            <h3>Curriculum Content</h3>
            {/* <h4 className='text-right  btn-primary'  ><a href={pdf}  Download>Download Curriculum PDF </a></h4> */}
           <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Introduction to Machine Learning
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; What is Machine Learning </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Types of ML - Supervised, Unsupervised </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Supervised - Classification, Regression </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Unsupervised - Clustering, Association </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Machine Learning Pipeline  </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Essential NumPy
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Introduction to NumPy </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Creation  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Access </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Stacking and Splitting </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Methods </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Broadcasting </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Pandas for Machine Learning
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Introduction to Pandas </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Understanding Series & DataFrames  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Accessing subsets of data - Rows, Columns, Filters </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Function Application - map, apply, groupby, rolling, str</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Normalizing JSON </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Application - EDA on Employee data, sales data </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Essential Maths & Statistics
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Essential Linear Algebra </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Probability Concepts </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Understanding distributions </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Mean, Median, Mode, Quantile</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Other statistics Concepts </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Linear Models for Classification & Regression
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp;  Linear Regression using Ordinary Least Squares</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Gradient Descent Algorithm</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Regularized Regression Methods - Ridge, Lasso, ElasticNet </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Logistic Regression for Classification</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; OnLine Learning Methods - Stochastic Gradient Descent & Passive Aggrasive </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Preprocessing for Machine Learning
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Introduction to Preprocessing</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; StandardScaler</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; MinMaxScaler </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; RobustScaler</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Normalization</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Decision Trees
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Introduction to Decision Trees</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; The Decision Tree Algorithms</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Decision Tree for Classification </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Decision Tree for Regression</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Naive Bayes
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>

                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Model Selection & Evaluation
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Cross Validation
                            Hyperparameter Tuning
                            Model Evaluation
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Feature Selection & Dimensionality Reduction
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Nearest Neighbors
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>


            {/* Course Dtail Strat */}
            <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Support Vector Machines
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Ensemble Methods
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Recommendation Engine
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Projectse?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>

                      {/*course detail end   */}


                      {/* FAQ Start */}
                      <h3>Premium Course FAQs</h3>
            
            <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I miss a class?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            You will never miss a lecture at Techiecub! You can choose either of the two options: View the recorded session of the class available in your LMS OR You can attend the missed session, in any other live batch.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I have queries after I complete this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Your access to the Support Team is for lifetime and will be available 24/7. The team will help you in resolving queries, during and after the course.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How soon after Signing up would I get access to the Learning Content?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Post-enrolment, the LMS access will be instantly provided to you and will be available for lifetime. You will be able to access the complete set of previous class recordings, PPTs, PDFs, assignments. Moreover the access to our 24x7 support team will be granted instantly as well. You can start learning right away.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
                      {/* FAQ End */}

          
         
       </div>
      
   </div>
  

  
        </>
    )
}

export default MachineLearning;