import React from 'react'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import pdf from "../Icons/General Instructions.pdf";


function AndroidWithKotlin() {
    return (
        <>
         <div class="lernen_banner large bg-androidWithKotlin">
        <div class="container">
            <div class="row">
                <div class="lernen_banner_title">
                    <h1>Android With Kotlin</h1>
                    <div class="lernen_breadcrumb">
                        <div class="breadcrumbs">
									<span class="first-item">
									<a href="index.html">Homepage</a></span>
                            <span class="separator">&gt;</span>
                            <span class="last-item">Android Kotlin</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="features" class="wrap-bg">
       
       <div class="container">
           <div class="row justify-content-center text-center">
               <div class="col-lg-8">
                   <div class="section-title with-p">
                       <h2>About Course</h2>
                       <div class="bar"></div>
                       <p>Kotlin is very good for developing Mobile cross-platform, compiling the code to Native libraries, processing data, programming server-side of applications, for building Web as well as Android applications. Kotlin/Native is primarily designed to allow compilation for platforms where virtual machines are not desirable or possible, for example, embedded devices or iOS. This Kotlin training helps your developer team increase productivity and efficiency. This Kotlin course gives an in-depth understanding and takes a completely hands-on approach. Corporate training in Kotlin is customized based on the requirement of the clients.
                       </p>
                   </div>
               </div>
           </div>
         
           <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 1 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            {/* <!-- uses solid style --> */}
                            <i class="secondary-color fas fa-chalkboard-teacher fa-3x"></i>
                            <h4><a href="#">IT Experts as Trainers</a></h4>
                            <p>Learning a technology with a professional who is well expertise in that solve 60% of your needs.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 2 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-cubes fa-3x"></i>
                            <h4><a href="#">Fully Hands-on Training</a></h4>
                            <p>We support any training with more practical classes. So, we always prefers to give hands-on training.
</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 3 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-people-carry fa-3x"></i>
                            <h4><a href="#">Flexible Timings</a></h4>
                            <p>We can give the flexibility timings as per your schedule. So that it will not impact your office or personal work.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
            </div>
           {/* <div class="header-curriculum">
                
               
               
            </div> */}
            <h3>Curriculum Content</h3>
            {/* <h4 className='text-right  btn-primary'  ><a href={pdf}  Download>Download Curriculum PDF </a></h4> */}
           <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Kotlin Basics
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Data Types </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Packages & Imports </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Control Flows </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Returns & Jumps </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            OOP and Kotlin 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Classes & Inheritance </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Properties & Fields </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Interfaces </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Visibility Modifiers </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Extensions </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Data Classes </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Sealed Classes </li></a>

                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                             Functional Programming
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                         <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Functions </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Lambdas </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Inline Functions </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Kotlin Collections
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Iterators </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Ranges & Progressions </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Sequences </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Transformations </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Plus & Minus Operator </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Grouping </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; List, Map & Set specific Operations </li></a>

                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Kotlin Miscellaneous
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Destructuring Declarations </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Type Checks & Casts </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; This Expressions </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Equality </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Operator Overloading </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Null Safety</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp;Reflection </li></a>

                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Kotlin Coroutines
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                         <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Basics </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Cancellations & Timeouts </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Suspend Functions </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Coroutine Contexts & Dispatchers </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Flow </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Exception Handling</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Shared Mutability </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>


                                  {/* FAQ Start */}
                                  <h3>Premium Course FAQs</h3>
            <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What will you learn as a part of this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Who should go for this training?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What are the skills that you will be learning with our DevOps course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What are the pre-requisites for this Course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I miss a class?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            You will never miss a lecture at Techiecub! You can choose either of the two options: View the recorded session of the class available in your LMS OR You can attend the missed session, in any other live batch.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I have queries after I complete this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Your access to the Support Team is for lifetime and will be available 24/7. The team will help you in resolving queries, during and after the course.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How soon after Signing up would I get access to the Learning Content?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Post-enrolment, the LMS access will be instantly provided to you and will be available for lifetime. You will be able to access the complete set of previous class recordings, PPTs, PDFs, assignments. Moreover the access to our 24x7 support team will be granted instantly as well. You can start learning right away.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
                      {/* FAQ End */}

          
         
       </div>
      
   </div>
  

  
        </>
    )
}

export default AndroidWithKotlin;