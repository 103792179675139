import React from 'react'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import pdf from "../Icons/General Instructions.pdf";


function Jmeter() {
    return (
        <>
         <div class="lernen_banner large bg-jmeter">
        <div class="container">
            <div class="row">
                <div class="lernen_banner_title">
                    <h1>Jmeter</h1>
                    <div class="lernen_breadcrumb">
                        <div class="breadcrumbs">
									<span class="first-item">
									<a href="/">Homepage</a></span>
                            <span class="separator">&gt;</span>
                            <span class="last-item">Jmeter</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="features" class="wrap-bg">
       
       <div class="container">
           <div class="row justify-content-center text-center">
               <div class="col-lg-8">
                   <div class="section-title with-p">
                       <h2>About Course</h2>
                       <div class="bar"></div>
                       <p>The JMeter training online deals with fundamental to advanced level concepts of JMeter, application performance, and load test cases through use cases, real-world scenarios, and industry-based examples. The Online JMeter course will enable the delegate to become a certified performance test engineer in JMeter. The online JMeter course will help in the understanding of the JMeter concepts, how to modulate load factors, send requests, how to make use of the assertion to validate responses with the help of a server, and more in-depth.
                       </p>
                   </div>
               </div>
           </div>
         
           <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 1 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            {/* <!-- uses solid style --> */}
                            <i class="secondary-color fas fa-chalkboard-teacher fa-3x"></i>
                            <h4><a href="#">IT Experts as Trainers</a></h4>
                            <p>Learning a technology with a professional who is well expertise in that solve 60% of your needs.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 2 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-cubes fa-3x"></i>
                            <h4><a href="#">Fully Hands-on Training</a></h4>
                            <p>We support any training with more practical classes. So, we always prefers to give hands-on training.
</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 3 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-people-carry fa-3x"></i>
                            <h4><a href="#">Flexible Timings</a></h4>
                            <p>We can give the flexibility timings as per your schedule. So that it will not impact your office or personal work.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
            </div>
           {/* <div class="header-curriculum">
                
               
               
            </div> */}
            <h3>Curriculum Content</h3>
            {/* <h4 className='text-right  btn-primary'  ><a href={pdf}  Download>Download Curriculum PDF </a></h4> */}
           <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Java Basics
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; What are Classes and Objects? </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; What are Methods and Functions? </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; What is Inheritance? </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; What is Encapsulation? </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Polymorphism? </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Constructors and Destructors? </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Introduction to JMeter
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; What is JMeter? </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; History of JMeter. </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Pros and cons of JMeter. </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Pre-requisites to install JMeter.</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Configuring JMeter in Easy Steps
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Sharing software’s and relevant dependencies </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Installing suitable version of Java. </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Setting up environment variables for JMeter and Java. </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Components of JMeter
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Test Plan </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Thread Groups </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Workbench </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Config Elements </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Timers </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Pre Processors </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How to do scripting with Recording and Replay Functionality
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; How to record? </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Dos and Don’ts during recording. </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; How to replay? </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Dos and Don’ts during replay. </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; How to debug the recorded code? </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Data Driven or Parameterization using CSV files:
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Data driven of input data using inbuilt JMeter control</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Parameterization of input data from EXCEL sheet </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Live example with parameterized script </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Page Verification and Validation
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Validating the web pages using JMeter controls </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Ensuring that all the web pages are loaded during execution </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Live example with page verification and validation. </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Handling Dynamic Web Pages
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Doing Scripting in such a way that it should not fail even the web pages changes dynamically for every loop </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Introducing the concept called correlation to handle dynamic web pages recording and replay. </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Live example with scripting on dynamic applications. </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Different Types of Testing using JMeter
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Load/Stress testing </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Endurance Testing </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Functional Automation Testing </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Regression Testing</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Database Testing </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Performing Distributed Testing
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Configuration for doing distributed testing </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; How to share the test across the computers which are in different locations </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; How to share the load injectors to share the virtual users during load test. </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Other Important Features of JMeter that we cover
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Plugin for generating automated load test results and graphs </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Plugin for Server Monitoring of apache servers </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Plugin for Load testing on Oracle Database </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Result analysis with third party website </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Handling web service based applications. </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>


                       {/* FAQ Start */}
                      <h3>Premium Course FAQs</h3>
            
            <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I miss a class?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            You will never miss a lecture at Techiecub! You can choose either of the two options: View the recorded session of the class available in your LMS OR You can attend the missed session, in any other live batch.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I have queries after I complete this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Your access to the Support Team is for lifetime and will be available 24/7. The team will help you in resolving queries, during and after the course.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How soon after Signing up would I get access to the Learning Content?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Post-enrolment, the LMS access will be instantly provided to you and will be available for lifetime. You will be able to access the complete set of previous class recordings, PPTs, PDFs, assignments. Moreover the access to our 24x7 support team will be granted instantly as well. You can start learning right away.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
                      {/* FAQ End */}

          
         
       </div>
      
   </div>
  

  
        </>
    )
}

export default Jmeter;