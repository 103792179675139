import React from 'react'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import pdf from "../Icons/General Instructions.pdf";


function BlockChain() {
    return (
        <>
         <div class="lernen_banner large bg-blockChain">
        <div class="container">
            <div class="row">
                <div class="lernen_banner_title">
                    <h1>BlockChain</h1>
                    <div class="lernen_breadcrumb">
                        <div class="breadcrumbs">
									<span class="first-item">
									<a href="index.html">Homepage</a></span>
                            <span class="separator">&gt;</span>
                            <span class="last-item">BlockChain</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="features" class="wrap-bg">
       
       <div class="container">
           <div class="row justify-content-center text-center">
               <div class="col-lg-8">
                   <div class="section-title with-p">
                       <h2>About Course</h2>
                       <div class="bar"></div>
                       <p>A blockchain is a digitized, decentralized, public ledger cryptocurrency transactions. Transactions such as the exchange of assets or data are recorded in distributed blocks which are shared among the participants in the blockchain network. All the confirmed and validated transaction blocks are linked and chained from the beginning of the chain to the most current block. Every record in the distributed ledger has a timestamp and unique cryptographic signature, thus making the ledger an auditable, immutable history of all transactions in the network.Blockchain was implemented first time as part of digital currency bitcoin. In future blockchain can be used to develop the application for finance, supply chain, healthcare, IOT domain using platforms like Ethereum and Hyperledger
                       </p>
                   </div>
               </div>
           </div>
         
           <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 1 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            {/* <!-- uses solid style --> */}
                            <i class="secondary-color fas fa-chalkboard-teacher fa-3x"></i>
                            <h4><a href="#">IT Experts as Trainers</a></h4>
                            <p>Learning a technology with a professional who is well expertise in that solve 60% of your needs.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 2 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-cubes fa-3x"></i>
                            <h4><a href="#">Fully Hands-on Training</a></h4>
                            <p>We support any training with more practical classes. So, we always prefers to give hands-on training.
</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 3 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-people-carry fa-3x"></i>
                            <h4><a href="#">Flexible Timings</a></h4>
                            <p>We can give the flexibility timings as per your schedule. So that it will not impact your office or personal work.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
            </div>
           {/* <div class="header-curriculum">
                
               
               
            </div> */}
            <h3>Curriculum Content</h3>
            {/* <h4 className='text-right  btn-primary'  ><a href={pdf}  Download>Download Curriculum PDF </a></h4> */}
           <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Blockchain 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Introduction to Block Chain </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Understanding Distributed systems </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Types of blockchain </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Consensus </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; CAP theorem and blockchain </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Decentralization
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Decentralization using blockchain </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Methods of decentralization </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Routes to decentralization </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Blockchain and full ecosystem decentralization </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Smart contracts </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Decentralized Organizations </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Platforms for decentralization </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Symmetric cryptography
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Working with the OpenSSL command line </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Introduction </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Cryptographic primitives </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Public key cryptography
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                         <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Asymmetric cryptography </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Public and private keys </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Financial markets and trading </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Introducing bitcoin
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Bitcoin </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Digital keys and addresses </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Transactions </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Blockchain </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Mining </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Bitcoin network and payments
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; The Bitcoin network </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Wallets </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Bitcoin payments </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Innovation in Bitcoin </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Bitcoin clients and apis
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Bitcoin installation </li></a>
                     </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Alternative coins
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Theoretical foundations </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Bitcoin limitations </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Namecoin </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Litecoin </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Primecoin </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Zcash </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Smart contracts
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; History </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Definition </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Ricardian contracts </li></a>
                      </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Ethereum
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                         <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Introduction </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Ethereum â€“ bird's eye view </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; The Ethereum network </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Components of the Ethereum ecosystem </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Ethereum development environment
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Test networks </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Setting up a private net </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Starting up the private network </li></a>
                    </ul>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>


                      {/* FAQ Start */}
                      <h3>Premium Course FAQs</h3>
            <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What will you learn as a part of this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Who should go for this training?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What are the skills that you will be learning with our DevOps course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What are the pre-requisites for this Course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I miss a class?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            You will never miss a lecture at Techiecub! You can choose either of the two options: View the recorded session of the class available in your LMS OR You can attend the missed session, in any other live batch.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I have queries after I complete this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Your access to the Support Team is for lifetime and will be available 24/7. The team will help you in resolving queries, during and after the course.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How soon after Signing up would I get access to the Learning Content?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Post-enrolment, the LMS access will be instantly provided to you and will be available for lifetime. You will be able to access the complete set of previous class recordings, PPTs, PDFs, assignments. Moreover the access to our 24x7 support team will be granted instantly as well. You can start learning right away.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
                      {/* FAQ End */}

          
         
       </div>
      
   </div>
  

  
        </>
    )
}

export default BlockChain;