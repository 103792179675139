import React from 'react';

const ServiceMain = ({ id, readMore }) => {
    return (
        <>
            <div className="lernen_banner large bg-serviceMain">
                <div className="container">
                    <div className="row">
                        <div className="lernen_banner_title">
                            <h1>Our Services</h1>
                            <div className="lernen_breadcrumb">
                                <div className="breadcrumbs">
                                    <span className="first-item">
                                        <a href="/">Homepage</a>
                                    </span>
                                    <span className="separator">&gt;</span>
                                    <span className="last-item">Services</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="features" className="wrap-bg services">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-8">
                            <div className="section-title with-p">
                                <h2>Our Services</h2>
                                <div className="bar"></div>
                                <p>Techiecub Solutions has tied up with various product-based Companies to provide In-Depth Training and Live Projects for students in the final year as a part of their curriculum. The program starts with an intensive training program that is customized based on live and real-time projects.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {/* Placement Program */}
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                            <div className="single-features-light">
                                <div className="move">
                                    <h4><a href="#">Global IT Staffing</a></h4>
                                    <p>Techiecub Solutions Empower your workforce with our global IT staffing solutions. We specialize in connecting businesses with top-tier IT talent around the world. Whether you need temporary support or long-term expertise, our staffing services ensure that you have access to skilled professionals who align with your unique requirements. Elevate your team and drive success with our global IT staffing solutions</p>
                                    <div className="feature_link">
                                        {/*<a href="/job-asistance"><i className="fas fa-arrow-right"></i></a> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Internship Program */}
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                            <div className="single-features-light">
                                <div className="move">
                                    <h4><a href="#">Internship with Job Assistance</a></h4>
                                    <p>Techiecub Solutions has tied up with various product-based Companies to provide In-Depth Training and Live Projects for students in the final year as a part of their curriculum. The program starts with an intensive training program that is customized based on live and real-time projects.Explore exciting internship opportunities with us! Our programs are designed to provide hands-on experience and valuable insights into the dynamic world of IT. Not only will you gain practical skills, but you'll also benefit from job assistance, ensuring a smooth transition from learning to a successful career. </p>
                                    {id === 1 && readMore && (
                                        <span id='more'>
                                            <p>students in the final year as a part of their curriculum. The program starts with an intensive training program that is customized based on live and real-time projects.</p>
                                            <div className="feature_link">
                                                <a href="/internship-programe"><i className="fas fa-arrow-right"></i></a>
                                            </div>
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Corporate Training */}
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                            <div className="single-features-light">
                                <div className="move">
                                    <h4><a href="#">IT Training</a></h4>
                                    <p>Elevate your skills with our comprehensive IT training courses. From foundational concepts to advanced technologies, we offer hands-on learning experiences designed to empower your career. Dive into areas like artificial intelligence, cybersecurity, cloud computing, and more. Stay ahead in the ever-evolving IT landscape with our expert-led training. Unlock your potential and shape a successful future in the world of technology. Explore, learn, and thrive with our IT training courses.</p>
                                    {id === 2 && readMore && (
                                        <span id='more'>
                                            {/*<p>get your body clean.</p>*/}
                                            <div className="feature_link">
                                                <a href="/corporate-training"><i className="fas fa-arrow-right"></i></a>
                                            </div>
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Other Services */}
                        {/* ... Other services similar to the above structure ... */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ServiceMain;
