import React from 'react'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import pdf from "../Icons/General Instructions.pdf";


function ChefPuppet() {
    return (
        <>
         <div class="lernen_banner large bg-chefPuppet">
        <div class="container">
            <div class="row">
                <div class="lernen_banner_title">
                    <h1> Chef / Puppet</h1>
                    <div class="lernen_breadcrumb">
                        <div class="breadcrumbs">
									<span class="first-item">
									<a href="index.html">Homepage</a></span>
                            <span class="separator">&gt;</span>
                            <span class="last-item">Chef / Puppet</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="features" class="wrap-bg">
       
       <div class="container">
           <div class="row justify-content-center text-center">
               <div class="col-lg-8">
                   <div class="section-title with-p">
                       <h2>About Course</h2>
                       <div class="bar"></div>
                       <p>Chef is one of the most widely embraced Configuration Management tools in the trending DevOps culture. It is applied to organize the task of configuring and maintaining an organization’s servers, and can integrate with cloud-based platforms including AWS, MS Azure, Google Cloud etc. It can also integrate with on-premise infrastructure. Chef has the ability to change your infrastructure into code. With the help of its easy and readable syntax Chef can be used in a hassle-free manner. Chef comprises solutions for both small and large scale systems.
                       </p>
                   </div>
               </div>
           </div>
         
           <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 1 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            {/* <!-- uses solid style --> */}
                            <i class="secondary-color fas fa-chalkboard-teacher fa-3x"></i>
                            <h4><a href="#">IT Experts as Trainers</a></h4>
                            <p>Learning a technology with a professional who is well expertise in that solve 60% of your needs.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 2 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-cubes fa-3x"></i>
                            <h4><a href="#">Fully Hands-on Training</a></h4>
                            <p>We support any training with more practical classes. So, we always prefers to give hands-on training.
</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 3 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-people-carry fa-3x"></i>
                            <h4><a href="#">Flexible Timings</a></h4>
                            <p>We can give the flexibility timings as per your schedule. So that it will not impact your office or personal work.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
            </div>
           {/* <div class="header-curriculum">
                
               
               
            </div> */}
            <h3>Curriculum Content</h3>
            {/* <h4 className='text-right  btn-primary'  ><a href={pdf}  Download>Download Curriculum PDF </a></h4> */}
           <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            The Basics
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Important – Download These First – Working Files </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Introduction To Configuration Management </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Why Puppet? </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; How To Access Your Working Files </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            The Puppet Infrastructure
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Puppet Agents </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Puppet Masters </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; MCollective And Systems Orchestration </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Cross Platform Puppet </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            The Puppet Run Cycle
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul> 
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Introduction To Puppet Run Cycle</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Gathering System Facts </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Node Matching And Catalogue Compilation </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            The Puppet Language – A Basic Primer
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Puppet Resources </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Applying A Simple Puppet Manifest </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Puppet Types </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; The Package File Service Pattern </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                           Organizing A Puppet Environment
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Defining Nodes </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Puppet Modules – Reusable Code </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Forge Modules – Where To Find Reusable Code</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Provisioning A Web Server </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            MCollective
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; MCollective And Live Management </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; MCollective With Puppet </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Using MCollective To Interact With Services </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Using MCollective To Interact With Puppet </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Additional Tools
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Managing Modules With Librarian-Puppet </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Managing Directory Environments And Modules With R10K </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; External Node Classifiers And Reporting With Puppet Dashboard – Part 1 </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; External Node Classifiers And Reporting With Puppet Dashboard – Part 2 </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>

                      {/* FAQ Start */}
                      <h3>Premium Course FAQs</h3>
            
            <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I miss a class?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            You will never miss a lecture at Techiecub! You can choose either of the two options: View the recorded session of the class available in your LMS OR You can attend the missed session, in any other live batch.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I have queries after I complete this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Your access to the Support Team is for lifetime and will be available 24/7. The team will help you in resolving queries, during and after the course.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How soon after Signing up would I get access to the Learning Content?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Post-enrolment, the LMS access will be instantly provided to you and will be available for lifetime. You will be able to access the complete set of previous class recordings, PPTs, PDFs, assignments. Moreover the access to our 24x7 support team will be granted instantly as well. You can start learning right away.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
                      {/* FAQ End */}

          
         
       </div>
      
   </div>
  

  
        </>
    )
}

export default ChefPuppet;