import React from 'react'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import pdf from "../Icons/General Instructions.pdf";


function BigDataAnalytics() {
    return (
        <>
         <div class="lernen_banner large bg-bigDataAnalytics">
        <div class="container">
            <div class="row">
                <div class="lernen_banner_title">
                    <h1>Big Data & Analytics</h1>
                    <div class="lernen_breadcrumb">
                        <div class="breadcrumbs">
									<span class="first-item">
									<a href="/">Homepage</a></span>
                            <span class="separator">&gt;</span>
                            <span class="last-item">Big Data & Analytics</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="features" class="wrap-bg">
       
       <div class="container">
           <div class="row justify-content-center text-center">
               <div class="col-lg-8">
                   <div class="section-title with-p">
                       <h2>About Course</h2>
                       <div class="bar"></div>
                       <p>Data is the new oil" and knowledge of Hadoop framework is a must-have skill for anyone interested to make a career in this emerging field of Big Data Technologies. This course builds a foundation for Big Data Technologies. After completing this learners will get to know the challenges faced in Big Data field and how a distributed ecosystem help in meeting those challenges and solving Big Data problems. This course is designed by Experts for complete hands-on training for the inquisitive learners and Big Data enthusiasts.
                       </p>
                   </div>
               </div>
           </div>
         
           <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 1 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            {/* <!-- uses solid style --> */}
                            <i class="secondary-color fas fa-chalkboard-teacher fa-3x"></i>
                            <h4><a href="#">IT Experts as Trainers</a></h4>
                            <p>Learning a technology with a professional who is well expertise in that solve 60% of your needs.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 2 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-cubes fa-3x"></i>
                            <h4><a href="#">Fully Hands-on Training</a></h4>
                            <p>We support any training with more practical classes. So, we always prefers to give hands-on training.
</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 3 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-people-carry fa-3x"></i>
                            <h4><a href="#">Flexible Timings</a></h4>
                            <p>We can give the flexibility timings as per your schedule. So that it will not impact your office or personal work.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
            </div>
           {/* <div class="header-curriculum">
                
               
               
            </div> */}
            <h3>Curriculum Content</h3>
            {/* <h4 className='text-right  btn-primary'  ><a href={pdf}  Download>Download Curriculum PDF </a></h4> */}
           <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                             Introduction to Big Data & Hadoop 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Understand what is Big Data?  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; List the challenges associated with Big Data  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Understand the difference between Real-time and Batch Processing  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Understand Hadoop capabilities  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Understand Hadoop ecosystem </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Hadoop & HDFS Internals 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Understand HDFS Architecture  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Hands – on HDFS  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Understand MapReduce Execution Flow  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Hands – on MapReduce </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                             Introduction to MapReduce 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                         <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Understand Blocks and Splits   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp;Analyze MapReduce Use Cases   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Understand MapReduce Anatomy   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Understand Input Splits </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Advanced MapReduce Concepts 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Joins in Map Reduce   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Custom Input/output formats    </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Data profiling and testing  </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Introduction to Pig 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Distributed Cache  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp;Input Output formats  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Sequence Files processing in Map Reduce  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Pig Concepts </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                           Advanced Hive Concepts
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Hive Data Types & Data Models  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp;Configuration settings & Hive Basic Operations  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Performance Improvements in HIVE </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; PIG vs HIVE </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Extending Hive & HBase Introduction 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Extending HIVE through UDFs  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp;HBase, its origin & Basics  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; HBase, Read Write Paths & Data Models </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Project Development
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Development of Hadoop Application from Scratch  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; We will work on user generated data like review comments  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; objective is to find highly rated things </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

             </Accordion>


                      {/* FAQ Start */}
                      <h3>Premium Course FAQs</h3>
            <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What will you learn as a part of this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Who should go for this training?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How soon after Signing up would I get access to the Learning Content?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Post-enrolment, the LMS access will be instantly provided to you and will be available for lifetime. You will be able to access the complete set of previous class recordings, PPTs, PDFs, assignments. Moreover the access to our 24x7 support team will be granted instantly as well. You can start learning right away.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                {/* <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What are the skills that you will be learning with our DevOps course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem> */}

                {/* <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What are the pre-requisites for this Course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem> */}
                {/* <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I miss a class?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            You will never miss a lecture at Techiecub! You can choose either of the two options: View the recorded session of the class available in your LMS OR You can attend the missed session, in any other live batch.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem> */}

                {/* <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I have queries after I complete this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Your access to the Support Team is for lifetime and will be available 24/7. The team will help you in resolving queries, during and after the course.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem> */}

                {/* <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How soon after Signing up would I get access to the Learning Content?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Post-enrolment, the LMS access will be instantly provided to you and will be available for lifetime. You will be able to access the complete set of previous class recordings, PPTs, PDFs, assignments. Moreover the access to our 24x7 support team will be granted instantly as well. You can start learning right away.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem> */}
            </Accordion>
                      {/* FAQ End */}

          
         
       </div>
      
   </div>
  

  
        </>
    )
}

export default BigDataAnalytics;