import React from 'react'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import pdf from "../Icons/General Instructions.pdf";


function Qtp() {
    return (
        <>
         <div class="lernen_banner large bg-qtp">
        <div class="container">
            <div class="row">
                <div class="lernen_banner_title">
                    <h1>QTP</h1>
                    <div class="lernen_breadcrumb">
                        <div class="breadcrumbs">
									<span class="first-item">
									<a href="/">Homepage</a></span>
                            <span class="separator">&gt;</span>
                            <span class="last-item">QTP</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="features" class="wrap-bg">
       
       <div class="container">
           <div class="row justify-content-center text-center">
               <div class="col-lg-8">
                   <div class="section-title with-p">
                       <h2>About Course</h2>
                       <div class="bar"></div>
                       <p>QTP stands for Quick Test Professional, a software from HP to provide functional and regression test automation for software applications and environment. It can also be used for enterprise quality assurance. It was renamed as HP Unified Functional Testing (UFT) by combining QTP and HP Service Test in 2012. It uses both Visual Basic scripting language (VBScript) and a graphical user interface (GUI) to specify a test procedure and to control the objects under test. It is significantly easier for a non-technical person to create working test cases.
                       </p>
                   </div>
               </div>
           </div>
         
           <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 1 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            {/* <!-- uses solid style --> */}
                            <i class="secondary-color fas fa-chalkboard-teacher fa-3x"></i>
                            <h4><a href="#">IT Experts as Trainers</a></h4>
                            <p>Learning a technology with a professional who is well expertise in that solve 60% of your needs.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 2 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-cubes fa-3x"></i>
                            <h4><a href="#">Fully Hands-on Training</a></h4>
                            <p>We support any training with more practical classes. So, we always prefers to give hands-on training.
</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 3 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-people-carry fa-3x"></i>
                            <h4><a href="#">Flexible Timings</a></h4>
                            <p>We can give the flexibility timings as per your schedule. So that it will not impact your office or personal work.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
            </div>
           {/* <div class="header-curriculum">
                
               
               
            </div> */}
            <h3>Curriculum Content</h3>
            {/* <h4 className='text-right  btn-primary'  ><a href={pdf}  Download>Download Curriculum PDF </a></h4> */}
           <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Introduction to Automation Testing
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; What is Automation Testing </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; When Automation is needed </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; When Automation is not needed </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Advantages of Automation Testing </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Disadvantages of Automation Testing </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; What are the popular Automation Tools in the industry </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Introduction to QTP
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; QTP IDE , Basic Components in QTP , Addin Manager</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; How does QTP works/Object recognition concept </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Record a sample Test </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Understand the Script </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Execution of a Test </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Enhancement of recorded script </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Step Generator
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; What is Step Generator </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; How to Generate script using Step Generator </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Advantages & Disadvantages of using Step Generator </li></a>
                    </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Virtual Object
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; What is Virtual Object </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; When Virtual Object is used </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Limitations of Virtual Object </li></a>
                    </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Debugging
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; When Debugging is used </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Step Into </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Step Out </li></a>
                    </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Descriptive Programming
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; What is Descriptive Programming </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Types of Descriptive Programming </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Working with DP Object </li></a>
                    </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Basics of VB Scripting
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Data Types, Variables, Constant </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Operators-Arithmetic,comparison,Concatenation,Logical </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Conditional construct If Then Else and Select Case </li></a>
                    </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Real time Scenarios
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp;How to start in real time projects </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; How to connect to the Database </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; How to get input value from the external excel sheet </li></a>
                    </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Career opportunities in QTP domain
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Software Automation Test Engineer - QTP </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; QTP/UFT Test Manager </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; QTP Automation Engineer </li></a>
                    </ul>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>


 
                      {/* FAQ Start */}
                      <h3>Premium Course FAQs</h3>
            
            <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I miss a class?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            You will never miss a lecture at Techiecub! You can choose either of the two options: View the recorded session of the class available in your LMS OR You can attend the missed session, in any other live batch.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I have queries after I complete this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Your access to the Support Team is for lifetime and will be available 24/7. The team will help you in resolving queries, during and after the course.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How soon after Signing up would I get access to the Learning Content?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Post-enrolment, the LMS access will be instantly provided to you and will be available for lifetime. You will be able to access the complete set of previous class recordings, PPTs, PDFs, assignments. Moreover the access to our 24x7 support team will be granted instantly as well. You can start learning right away.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
                      {/* FAQ End */}

          
         
       </div>
      
   </div>
  

  
        </>
    )
}

export default Qtp;