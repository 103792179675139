import React from 'react';

const WhyUs = () => {
    return ( 
    
        <div id="why-us-white">
            <div class="why-us-container why-us-left-bg5">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-5 col-xl-6 col-lg-6">
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-7 col-xl-6 col-lg-6 text-left">
                            <div class="white-box-large">
                                <div class="section-title">
                                    <div>
                                        <h3>We Can Help You In Product development</h3>
                                        <div class="bar"></div>
                                    </div>
                                </div>
                                <p> We know how to combine strategy, design and engineering to deliver technology solutions that help you reach operational excellence.Check if your business ideas and digital needs match with our capabilities and skills. Or simply ask our experts for a free consultation.</p>
                                <ul class="themeioan_ul_icon">
                                    <li><i class="fas fa-check-circle"></i> Web Development </li>
                                    <li><i class="fas fa-check-circle"></i> Mobile App Development</li>
                                    <li><i class="fas fa-check-circle"></i> UI/UX design Service</li>
                                    <li><i class="fas fa-check-circle"></i> Chatbot Development</li>
                                </ul>
                                <div class="mt-25 mb-50">
                                    <a href="/productmain" class="color-two btn-custom ">View Our Development <i class="fas fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    
     );
}
 
export default WhyUs;