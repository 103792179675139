import React from 'react'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import pdf from "../Icons/General Instructions.pdf";


function Selenium() {
    return (
        <>
         <div class="lernen_banner large bg-selenium">
        <div class="container">
            <div class="row">
                <div class="lernen_banner_title">
                    <h1>Selenium</h1>
                    <div class="lernen_breadcrumb">
                        <div class="breadcrumbs">
									<span class="first-item">
									<a href="/">Homepage</a></span>
                            <span class="separator">&gt;</span>
                            <span class="last-item">Selenium</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="features" class="wrap-bg">
       
       <div class="container">
           <div class="row justify-content-center text-center">
               <div class="col-lg-8">
                   <div class="section-title with-p">
                       <h2>About Course</h2>
                       <div class="bar"></div>
                       <p>Data Science Masters Program makes you proficient in tools and systems used by Data Science Professionals. It includes training on Statistics, Data Science, Python, Apache Spark & Scala, Tensorflow and Tableau. The curriculum has been determined by extensive research on 5000+ job descriptions across the globe..
                       </p>
                   </div>
               </div>
           </div>
         
           <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 1 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            {/* <!-- uses solid style --> */}
                            <i class="secondary-color fas fa-chalkboard-teacher fa-3x"></i>
                            <h4><a href="#">IT Experts as Trainers</a></h4>
                            <p>Learning a technology with a professional who is well expertise in that solve 60% of your needs.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 2 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-cubes fa-3x"></i>
                            <h4><a href="#">Fully Hands-on Training</a></h4>
                            <p>We support any training with more practical classes. So, we always prefers to give hands-on training.
</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 3 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-people-carry fa-3x"></i>
                            <h4><a href="#">Flexible Timings</a></h4>
                            <p>We can give the flexibility timings as per your schedule. So that it will not impact your office or personal work.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
            </div>
           {/* <div class="header-curriculum">
                
               
               
            </div> */}
            <h3>Curriculum Content</h3>
            {/* <h4 className='text-right  btn-primary'  ><a href={pdf}  Download>Download Curriculum PDF </a></h4> */}
           <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Introduction
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; What is Selenium?</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Who developed Selenium? </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Selenium Components </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Selenium IDE
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Installing Selenium IDE </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Creating your First Selenium IDE script </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; How to use Locators in Selenium IDE </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; How to enhance a script using Selenium IDE </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Creating and Running Tests </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Creating and Running Test Suite </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            WebDriver Introduction
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Introduction to WebDriver & Comparison with Selenium RC </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Installing Selenium WebDriver </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Architecture of selenium Webdriver </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Creating your First Script in Webdriver </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Launching AUT and Inspecting properties of Elements
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Launching AUT in FireFox </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Launching AUT in InternetExplorer</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Launching AUT in Chrome </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Launching AUT in Safari </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Inspecting properties of Elements on different Browsers </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Installing FireBug and FirePath </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Automating Operations on various Elements
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Browser </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; TextBox </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; ListBox </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Links </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Check Box </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Radio Button </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Automating Keyboard and Mouse Events
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Action Class </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; KeyBoard Events </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Drag & Drop Actions </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Mouse Hover Action </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; RightClick, Double Click & Tool Tip </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Handling multiple Windows
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; getwindowHandle() </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; getwindowHandles() </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Switching between windows </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Handling elements present in different windows </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Handling Alerts
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; accept() </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; dismiss() </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; getText() </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; sendKeys() </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Handling Frames
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; What is iFrame? </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Locating Frames </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Switching between Frames </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Handling Frames</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Creating Customize XPath/CSS Selectors
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; What is XPath </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; When to Use XPath </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Absolute XPath/Relative XPath </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Specifying conditions with XPath</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Synchronization
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; ImplicitWait </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; ImplicitWait </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; FluentWait </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; PageLoadTimeout</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>


                      {/* FAQ Start */}
                      <h3>Premium Course FAQs</h3>
            
            <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I miss a class?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            You will never miss a lecture at Techiecub! You can choose either of the two options: View the recorded session of the class available in your LMS OR You can attend the missed session, in any other live batch.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I have queries after I complete this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Your access to the Support Team is for lifetime and will be available 24/7. The team will help you in resolving queries, during and after the course.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How soon after Signing up would I get access to the Learning Content?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Post-enrolment, the LMS access will be instantly provided to you and will be available for lifetime. You will be able to access the complete set of previous class recordings, PPTs, PDFs, assignments. Moreover the access to our 24x7 support team will be granted instantly as well. You can start learning right away.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
                      {/* FAQ End */}

          
         
       </div>
      
   </div>
  

  
        </>
    )
}

export default Selenium;