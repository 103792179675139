import React,{useEffect, useState} from 'react';
import valid from '../common/validate';
import sendEmail from '../common/mail';
import joi from 'joi';
import swal from 'sweetalert'
const ContactArea = () => {

    const [data,setData]=useState({});
    const [errors,setErrors]=useState({});
    const [emailStatus,setEmailStatus]=useState(false)
     

    const schema = {
        contactName: joi.string().required().label('name'),
        contactEmail: joi.string().email().required(),
         contactSubject: joi.string().required().label('Subject'),
         contactMessage: joi.string().required().label('message')
    }
   
    
    


  

    // const sendEmail=(e)=> {
    //     e.preventDefault();
    
    //     emailjs
    //       .sendForm(
    //         "service_tv8sniw",
    //         "template_q0wsozo",
    //         e.target,
    //         "user_5Ci8rPqJhp90LnCWBPXln"
    //       )
    //       .then(
    //         (result) => {
    //           console.log(result.text);
    //           swal('Message Send Successfully','Message','success');
    //         },
    //         (error) => {
    //           console.log(error.text);
    //         }
    //       );
    //     e.target.reset();
    //   }
    const handleOnChange=(e)=>{
     const input={...data};
     const error={...errors};
     const errorMessage = valid.validateProperty(e.currentTarget, schema);
     console.log(errorMessage)
     if (errorMessage) error[e.currentTarget.name] = errorMessage;
     else delete error[e.currentTarget.name];
 
     input[e.currentTarget.name] = e.currentTarget.value;
     setData(input);
     setErrors(error || {})
    
    }

    const handleSubmit=()=>{
        setEmailStatus(true)
       		window.Email.send({
				Host: "mail.techiecubsolutions.com",
				Username : "info@techiecubsolutions.com",
				Password : "Redhat@19",
				To : 'info@techiecubsolutions.com',
				From : "info@techiecubsolutions.com",
				Subject : "Enquery",
				Body : 'Name ->  '+data.contactName+'<br>'+'Email -> '+data.contactEmail+'<br>'+'Subject ->  '+data.contactSubject+'<br>'+'Message ->  '+data.contactMessage+'<br>',
				}).then(
					message => {
						// form.append('<div class="success-msg">Thank you! We will contact you shortly.</div>');
                        setEmailStatus(false)
                        swal('Thanks for the details. We will connect with you shortly.','Message Send Success','success')
						//formData.reset()
					}
				)
				.catch(
					err=>{console.log(err);setEmailStatus(false)}
                    // form.append('<div class="error-msg">Error occurred. Please try again later.</div>')
				);
    }

    
    return ( 
      
        <div id="contact" class="wrap-bg">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-lg-8">
                        <div class="section-title with-p">
                            <h2>Make an Appointment</h2>
                            <div class="bar"></div>
                            <p>Do you have the next big idea? Let’s talk about it
Talk to us now or send us an email to let us know about your project.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-lg-6">
                        <div class="dreidbgleft">
                            <img src="images/content/home/contact-image.jpg" alt="Buy this Course"/>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-6">
                        <div class="themeioan-form-contact form" id="contact-us-form" >
                           
                            <div>
                                <input onChange={handleOnChange} class="input-text required-field" type="text" name="contactName" id="contactName"
                                       placeholder="Name" title="Your Full Name"/>
                                       {errors.contactName && <div className="error-msg">{errors['contactName']}</div>}
                            </div>
                            <div>
                                <input onChange={handleOnChange} class="input-text required-field email-field" type="email" name="contactEmail"
                                       id="contactEmail" placeholder="Email" title="Your Email"/>
                                       {errors.contactEmail && <div className="error-msg">{errors['contactEmail']}</div>}
                            </div>
                            <div>
                                <input onChange={handleOnChange} class="input-text required-field" type="text" name="contactSubject" id="contactSubject"
                                       placeholder="Subject" title="Your Subject"/>
                                       {errors.contactSubject && <div className="error-msg">{errors['contactSubject']}</div>}
                            </div>
                            <div>
                        <textarea onChange={handleOnChange} class="input-text required-field" name="contactMessage" id="contactMessage"
                                  placeholder="Message" title="Your Message"></textarea>
                                       {errors.contactMessage && <div className="error-msg">{errors['contactMessage']}</div>}

                            </div>
                            {<input class={"color-two button"}  onClick={handleSubmit}  type="submit"
                                   value="Send Message"/>}
                                   
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
     );
}
 
export default ContactArea;