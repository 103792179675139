import React from 'react'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import pdf from "../Icons/General Instructions.pdf";


function DataVisualization() {
    return (
        <>
         <div class="lernen_banner large bg-dataVisualization">
        <div class="container">
            <div class="row">
                <div class="lernen_banner_title">
                    <h1>Data Visualization</h1>
                    <div class="lernen_breadcrumb">
                        <div class="breadcrumbs">
									<span class="first-item">
									<a href="/">Homepage</a></span>
                            <span class="separator">&gt;</span>
                            <span class="last-item">Data Visualization</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="features" class="wrap-bg">
       
       <div class="container">
           <div class="row justify-content-center text-center">
               <div class="col-lg-8">
                   <div class="section-title with-p">
                       <h2>About Course</h2>
                       <div class="bar"></div>
                       <p>Data visualization is the presentation of data in a pictorial or graphical format. It enables decision makers to see analytics presented visually, so they can grasp difficult concepts or identify new patterns. With interactive visualization, you can take the concept a step further by using technology to drill down into charts and graphs for more detail, interactively changing what data you see and how it’s processed.
                       </p>
                   </div>
               </div>
           </div>
         
           <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 1 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            {/* <!-- uses solid style --> */}
                            <i class="secondary-color fas fa-chalkboard-teacher fa-3x"></i>
                            <h4><a href="#">IT Experts as Trainers</a></h4>
                            <p>Learning a technology with a professional who is well expertise in that solve 60% of your needs.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 2 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-cubes fa-3x"></i>
                            <h4><a href="#">Fully Hands-on Training</a></h4>
                            <p>We support any training with more practical classes. So, we always prefers to give hands-on training.
</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 3 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-people-carry fa-3x"></i>
                            <h4><a href="#">Flexible Timings</a></h4>
                            <p>We can give the flexibility timings as per your schedule. So that it will not impact your office or personal work.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
            </div>
           {/* <div class="header-curriculum">
                
               
               
            </div> */}
            <h3>Curriculum Content</h3>
            {/* <h4 className='text-right  btn-primary'  ><a href={pdf}  Download>Download Curriculum PDF </a></h4> */}
           <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Matplotlib 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Simple Line Plots </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Simple Scatter Plots </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Visualizing Errors </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Density and Contour Plots </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Histograms, Binnings, and Density </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Customizing Plot Legends </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Bokeh
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; What is Bokeh  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Bokeh and Bokeh Server </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Creating Your First Bokeh Plot  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Plotting triangles and circle glyphs </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Using Bokeh with Pandas  </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Diving Into Bokeh
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Section Introduction  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Structure of a Bokeh Chart  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Recap - Bokeh Structure   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Bug with the Show Method  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Bokeh Interfaces  </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Customizing Bokeh Graphs
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Section Introduction   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Creating an Initial Plot  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Figure Background   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; List of Colors  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; List of Text Fonts   </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Advanced Plotting
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Section Introduction  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; ColumnDataSource  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Plotting Periodic Table Elements  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Popup Windows with Custom HTML  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Gridplots   </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Annotations: Spans and Boxes 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                         <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Span Annotations  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Annotations: Labels and LabelSets </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Labels in Spans  </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Bokeh Server: Interactive Plots and Widgets
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                         <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Section Introduction   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Widgets in Static Bokeh Graphs  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Widgets in Interactive Bokeh Server Apps   </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Bokeh Server: Streaming Real Time Data
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Section Introduction   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Streaming Random Points and Lines   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Streaming Financial Data - Designing the App  </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>


                      {/* FAQ Start */}
                      <h3>Premium Course FAQs</h3>
            <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What will you learn as a part of this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Who should go for this training?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What are the skills that you will be learning with our DevOps course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What are the pre-requisites for this Course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I miss a class?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            You will never miss a lecture at Techiecub! You can choose either of the two options: View the recorded session of the class available in your LMS OR You can attend the missed session, in any other live batch.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I have queries after I complete this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Your access to the Support Team is for lifetime and will be available 24/7. The team will help you in resolving queries, during and after the course.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How soon after Signing up would I get access to the Learning Content?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Post-enrolment, the LMS access will be instantly provided to you and will be available for lifetime. You will be able to access the complete set of previous class recordings, PPTs, PDFs, assignments. Moreover the access to our 24x7 support team will be granted instantly as well. You can start learning right away.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
                      {/* FAQ End */}

          
         
       </div>
      
   </div>
  

  
        </>
    )
}

export default DataVisualization;