import React from 'react'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import pdf from "../Icons/General Instructions.pdf";


function Aws() {
    return (
        <>
         <div class="lernen_banner large bg-aws">
        <div class="container">
            <div class="row">
                <div class="lernen_banner_title">
                    <h1>AWS</h1>
                    <div class="lernen_breadcrumb">
                        <div class="breadcrumbs">
									<span class="first-item">
									<a href="index.html">Homepage</a></span>
                            <span class="separator">&gt;</span>
                            <span class="last-item">AWS</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="features" class="wrap-bg">
       
       <div class="container">
           <div class="row justify-content-center text-center">
               <div class="col-lg-8">
                   <div class="section-title with-p">
                       <h2>About Course</h2>
                       <div class="bar"></div>
                       <p>AWS Training: AWS is a one stop destination for cloud computing services. It offers exclusively secure cloud services from storage, database, networking to management tools, security and analytics which are cost effective, easy to use and reliable in functionality. All these services are provided over the availability zones (AZ) which means a data centre spread across regions in the geographic proximity of edge locations, they act as an content delivery network in the cloud. This platform is a union of infrastructure as service (IaaS), platform as a service (PaaS) and packaged software as a service (SaaS) offerings. The widely used domains of AWS has their own products to provide services like the database has Amazon Aurora, Amazon Redshift etc., migration has AWS database Migration services and many other products each in their respective domains. To learn more about the products, refer AWS services
                       </p>
                   </div>
               </div>
           </div>
         
           <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 1 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            {/* <!-- uses solid style --> */}
                            <i class="secondary-color fas fa-chalkboard-teacher fa-3x"></i>
                            <h4><a href="#">IT Experts as Trainers</a></h4>
                            <p>Learning a technology with a professional who is well expertise in that solve 60% of your needs.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 2 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-cubes fa-3x"></i>
                            <h4><a href="#">Fully Hands-on Training</a></h4>
                            <p>We support any training with more practical classes. So, we always prefers to give hands-on training.
</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 3 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-people-carry fa-3x"></i>
                            <h4><a href="#">Flexible Timings</a></h4>
                            <p>We can give the flexibility timings as per your schedule. So that it will not impact your office or personal work.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
            </div>
           {/* <div class="header-curriculum">
                
               
               
            </div> */}
            <h3>Curriculum Content</h3>
            {/* <h4 className='text-right  btn-primary'  ><a href={pdf}  Download>Download Curriculum PDF </a></h4> */}
           <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Introduction To The Course 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Introduction, Overview  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Getting Started, What you'll need. </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            AWS Overview
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; The history of AWS  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Overview of Services of AWS </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Identity Access Management (IAM)
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; IAM </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Console and All about IAM </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; IAM Lab</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            EC2 - The Backbone of AWS
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Introduction & Overview  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Launching an EC2 Instance </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; How to use Putty (Windows Users Only)</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Security Groups Basics   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Volumes and Snapshots  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Creating a Windows EC2 Instance & RAID Group </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Create an AMI   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; AMI's - EBS root volumes vs Instance Store  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Load Balancers & Health Checks   </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            AWS Object Storage and CDN - S3, Glacier and CloudFront
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; S3 </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Create an S3 Bucket </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Version Control</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Lifecycle Management & Glacier   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; CloudFront </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Create a CDN  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; S3 - Security & Encryption   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Storage Gateway </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; S3 Transfer Acceleration   </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                             Route53
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; DNS 101  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Route53 - Lab </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Route53 - Routing Policies</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Databases on AWS
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                    <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Databases  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Launching an RDS Instance  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Backups, Multi-AZ & Read Replicas</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; DynamoDB  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; RedShift  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Elasticache </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Aurora   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; AMI's - EBS root volumes vs Instance Store  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Load Balancers & Health Checks   </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            VPC
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Introduction & Overview  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Build your own custom VPC  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Network Address Translation (NAT) </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Access Control Lists (ACL's)   </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Application Services
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; SQS </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; SWF   </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; SNS  </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Elastic Transcoder   </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>


                      {/* FAQ Start */}
                      <h3>Premium Course FAQs</h3>
            <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What will you learn as a part of this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Who should go for this training?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What are the skills that you will be learning with our DevOps course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What are the pre-requisites for this Course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I miss a class?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            You will never miss a lecture at Techiecub! You can choose either of the two options: View the recorded session of the class available in your LMS OR You can attend the missed session, in any other live batch.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I have queries after I complete this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Your access to the Support Team is for lifetime and will be available 24/7. The team will help you in resolving queries, during and after the course.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How soon after Signing up would I get access to the Learning Content?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Post-enrolment, the LMS access will be instantly provided to you and will be available for lifetime. You will be able to access the complete set of previous class recordings, PPTs, PDFs, assignments. Moreover the access to our 24x7 support team will be granted instantly as well. You can start learning right away.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
                      {/* FAQ End */}

          
         
       </div>
      
   </div>
  

  
        </>
    )
}

export default Aws;