import React from 'react'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import pdf from "../Icons/General Instructions.pdf";


function FullStack() {
    return (
        <>
         <div class="lernen_banner large bg-fullStack">
        <div class="container">
            <div class="row">
                <div class="lernen_banner_title">
                    <h1>Full Stack ReactJs And Nodejs</h1>
                    <div class="lernen_breadcrumb">
                        <div class="breadcrumbs">
									<span class="first-item">
									<a href="/">Homepage</a></span>
                            <span class="separator">&gt;</span>
                            <span class="last-item">Full Stack</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="features" class="wrap-bg">
       
       <div class="container">
           <div class="row justify-content-center text-center">
               <div class="col-lg-8">
                   <div class="section-title with-p">
                       <h2>About Course</h2>
                       <div class="bar"></div>
                       <p>Since learning Full Stack is a comprehensive program we have included all the essential concepts of Full Stack Development in our syllabus. The content of the syllabus is updated regularly and it is the best in the industry.
                       </p>
                   </div>
               </div>
           </div>
         
           <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 1 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            {/* <!-- uses solid style --> */}
                            <i class="secondary-color fas fa-chalkboard-teacher fa-3x"></i>
                            <h4><a href="#">IT Experts as Trainers</a></h4>
                            <p>Learning a technology with a professional who is well expertise in that solve 60% of your needs.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 2 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-cubes fa-3x"></i>
                            <h4><a href="#">Fully Hands-on Training</a></h4>
                            <p>We support any training with more practical classes. So, we always prefers to give hands-on training.
</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                    {/* <!-- 3 --> */}
                    <div class="single-features-light">{/* <!-- single features --> */}
                        <div class="move">
                            <i class="secondary-color fas fa-people-carry fa-3x"></i>
                            <h4><a href="#">Flexible Timings</a></h4>
                            <p>We can give the flexibility timings as per your schedule. So that it will not impact your office or personal work.</p>
                        </div>
                    </div>{/* <!-- end single features --> */}
                </div>
            </div>
           {/* <div class="header-curriculum">
                
               
               
            </div> */}
            <h3>Curriculum Content</h3>
            {/* <h4 className='text-right  btn-primary'  ><a href={pdf}  Download>Download Curriculum PDF </a></h4> */}
           <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            All-in-One Javascript Development Suite
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Fundamentals Of JavaScript </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; JavaScript for Beginning Web Developers </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; JavaScript for Absolute Beginners </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Fundamentals of jQuery </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Fundamentals of Ajax Development </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Create a node.js Real Time Chat Application </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Advanced JavaScript </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
                
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            All-In-One HTML/HTML5 And CSS/CSS3 Suite
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Applying Designs to WireFrames with HTML5 and CSS3 </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Build Your Own HTML5 Video Player </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Building Responsive Websites with HTML5 and CSS3 </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; HTML5 and CSS3 Site Design </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; HTML5 Mobile Game Development by Example – Educational Game </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; HTML5 Mobile Game Development by Example -Veggies vs Zombies</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Make HTML5 Games with No Coding Required </li></a>

                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Node.Js Training
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                         <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Introduction and Foundation </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Node Projects </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Working with shrink-wrap to lock the node modules versions </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Working with asynchronous programming </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Building a HTTP Server with Node.JS using HTTP APIs </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; File System</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Buffers, Streams, and Events </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Angular Training
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; What is a SPA? What is Angular?</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Preparing for TypeScript </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Angular-4 new features</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Building with A4 Components </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Bootstrap Scaffolding </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Angular 4 Binding and Events</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Dependency Injection and services</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            React.js
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Welcome to Starting with React</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; React Components </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; React State and Props</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; React Event Handling</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Routing in React React flux </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Styling React</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Course Conclusion</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Linux
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Installing Linux</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Getting To Know The Command Line </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; It’s All About The Files</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Working With Command Line</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; More Advanced Command Line And Concepts </li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            SQL
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <ul>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Rational Databases</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; SQL Querying </li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Your First Queries</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Filtering Your Results</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Grouping Your Data</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Joining Tables</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Subqueries</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Manipulating Your Data</li></a>
                            <a href="#"><li><i class="fas fa-dot-circle"></i> &nbsp; Transaction Control</li></a>
                        </ul>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>


                                  {/* FAQ Start */}
            <h3>Premium Course FAQs</h3>
            <Accordion>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What will you learn as a part of this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Who should go for this training?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What are the skills that you will be learning with our DevOps course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What are the pre-requisites for this Course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            In this premium certification training you will expertise the principles of continuous development and deployment, automation of configuration management, inter-team collaboration, & IT service agility, using premium tools like Git, Docker, Jenkins, Cucumber, Ansible, TeamCity, & Nagios. You will learn the various aspects of premium delivery model.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I miss a class?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            You will never miss a lecture at Techiecub! You can choose either of the two options: View the recorded session of the class available in your LMS OR You can attend the missed session, in any other live batch.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What if I have queries after I complete this course?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Your access to the Support Team is for lifetime and will be available 24/7. The team will help you in resolving queries, during and after the course.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            How soon after Signing up would I get access to the Learning Content?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                            <p>
                            Post-enrolment, the LMS access will be instantly provided to you and will be available for lifetime. You will be able to access the complete set of previous class recordings, PPTs, PDFs, assignments. Moreover the access to our 24x7 support team will be granted instantly as well. You can start learning right away.
                            </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
                      {/* FAQ End */}

       </div>
   </div>
        </>
    )
}

export default FullStack;