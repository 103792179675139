import React from 'react';

const Slider = () => {
    return ( 
      <>
        <div className="owl-navigation owl-carousel owl-theme">
           
            <div className="item">
                <div className="image-overly-dark-opacity header-content slider-bg3" role="banner">
                    <div className="container opac">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 header-area">
                                <div className="header-area-inner header-text">
                                    <div><span className="subtitle ">Get trained with best real-time Expert <span className="base-color">Trainers</span></span></div>
                                    <div className="btn-section">
                                        <a href="#" className="color-two btn-custom">Get in Touch <i className="fas fa-arrow-right"></i></a>
{/*                                         <div className="video-relative">
                                            <a href="https://www.youtube.com/watch?v=TKnufs85hXk" className="video-btn popup-video ">
                                                <i className="fas fa-play"></i>
                                                <span className="ripple orangebg"></span>
                                                <span className="ripple orangebg"></span>
                                                <span className="ripple orangebg"></span>
                                            </a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="item">
                <div className="header-content image-overly-dark-opacity slider-bg10" role="banner">
                    <div className="container opac">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 header-area">
                                <div className="header-area-inner header-text"> 
                                    <span className="welcome">Welcome to Techiecub Solutions</span>
                                    <div><span className="subtitle">Chasing <span className="base-color">the jobs?</span></span></div>
                                    <h1 className="title">Let jobs chase you!</h1>
                                    <div className="btn-section">
                                        <a href="#" className="color-one btn-custom">Get in Touch <i className="fas fa-arrow-right"></i></a>
{/*                                         <div className="video-relative">
                                            <a href="https://www.youtube.com/watch?v=TKnufs85hXk" className="video-btn popup-video ">
                                                <i className="fas fa-play"></i>
                                                <span className="ripple orangebg"></span>
                                                <span className="ripple orangebg"></span>
                                                <span className="ripple orangebg"></span>
                                            </a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className="position-center image-overly-dark-opacity header-content slider-bg1" role="banner">
                    <div className="container opac">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 header-area">
                                <div className="header-area-inner header-text"> 
                                    <div><span className="subtitle ">Build your <span className="base-color">dream project</span></span></div>
                                    <h1 className="title">along with us!</h1>
                                    <div className="btn-section">
                                        <a href="#" className="color-two btn-custom">Get in Touch <i className="fas fa-arrow-right"></i></a>
{/*                                         <div className="video-relative">
                                            <a href="https://www.youtube.com/watch?v=TKnufs85hXk" className="video-btn popup-video ">
                                                <i className="fas fa-play"></i>
                                                <span className="ripple orangebg"></span>
                                                <span className="ripple orangebg"></span>
                                                <span className="ripple orangebg"></span>
                                            </a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        
     );
}
 
export default Slider;