import React from 'react';

const ProductMain = () => {
    return ( 
        <>
           {/* <!-- breadcrumb banner content area start --> */}
            <div class="lernen_banner large bg-product">
                <div class="container">
                    <div class="row">
                        <div class="lernen_banner_title">
                            <h1>Product Development</h1>
                            <div class="lernen_breadcrumb">
                                <div class="breadcrumbs">
                                    <span class="first-item">
                                        <a href="index.html">Homepage</a></span>
                                    <span class="separator">&gt;</span>
                                    <span class="last-item">Product Development</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    {/* <!-- end breadcrumb banner content area start -->

     <!-- services image area start --> */}
    <div id="services_image" class="wrap-bg wrap-bg-light">
        <div class="container">
            <div class="row justify-content-center text-center">
                <div class="col-lg-8">
                    <div class="section-title with-p">
                        <h2>Our Services</h2>
                        <div class="bar"></div>
                        <p>We are open to collaborate with startups, enterprises, and accelerators to solve their challenges and improve their legacy processes. Our approach is collective, and we think about the long term game from the start. And as one of the leading development companies we assure to form a long-term and rock-solid alliance with our clients for a brighter and digital-ready future.
                        </p>
                    </div>
                </div>
            </div>

            {/* <!-- .row --> */}
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
                    {/* <!-- 1 --> */}
                    <div class="themeioan_services">
                        <article>{/* <!-- single services --> */}
                            <div class="blog-content">
                                <div class="icon-space">
                                    <div class="glyph-icon flaticon-030-test"></div>
                                </div>
                                <h5 class="title"><a href="services-detail.html">Custom Website Development</a>
                                </h5>
                                <p>Enrich your business capabilities with our custom web design and development services to meet your business challenges. Break-away from the ordinary with a unique custom website. Our custom web builds do not rely on plugins templates, plugins or existing code, we work on your product from scratch.
                                </p>
                                <div class="mt-25">
                                    <a href="services-detail.html" class="button-light"><i class="fas fa-arrow-right"></i> Read More</a>
                                </div>
                            </div>
                        </article>{/* <!-- end single services --> */}
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
                    {/* <!-- 2 --> */}
                    <div class="themeioan_services">
                        <article>{/* <!-- single services --> */}
                            <div class="blog-content">
                                <div class="icon-space">
                                    <div class="glyph-icon flaticon-009-skills"></div>
                                </div>
                                <h5 class="title"><a href="services-detail.html">Mobile App Development</a></h5>
                                <p>Reach your customers anywhere, anytime, and on any device with secure mobile solutions. Signity provides the most comprehensive suite of services whether it is developing the best mobile app for a startup that trends in the app store or an enterprise-level application that will be commercially viable for the business.
                                </p>
                                <div class="mt-25">
                                    <a href="services-detail.html" class="button-light"><i class="fas fa-arrow-right"></i> Read More</a>
                                </div>
                            </div>
                        </article>{/* <!-- end single services --> */}
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
                    {/* <!-- 3 --> */}
                    <div class="themeioan_services">
                        <article>{/* <!-- single services --> */}
                            <div class="blog-content">
                                <div class="icon-space">
                                    <div class="glyph-icon flaticon-028-thinking"></div>
                                </div>
                                <h5 class="title"><a href="services-detail.html">AI/ML Solutions</a>
                                </h5>
                                <p>Integrate end-to-end artificial intelligence and ML lifecycle in your business model to market faster, increase the overall productivity, and deliver a secure, enterprise-ready platform. We help develop a cutting-edge customized artificial intelligence solution based on a unique algorithm that turns your data into a competitive advantage.
                                </p>
                                <div class="mt-25">
                                    <a href="services-detail.html" class="button-light"><i class="fas fa-arrow-right"></i> Read More</a>
                                </div>
                            </div>
                        </article>{/* <!-- end single services --> */}
                    </div>
                </div>
            </div>
            {/* <!-- .row end --> */}
            <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">                       
                        <div class="single-features-light">
                            <div class="move">
                             
                                <h4><a href="#">Enterprise Search Technology</a></h4>
                                <p>We build search platforms to discover hidden insights in a centralized, agile, and secure data repository to unlock the full value of your business. This works within your structured and unstructured database via cloud computing. As a digital product agency, we help transform your organization into a simplified structural data format.</p>
                                <div class="mt-25">
                                    <a href="services-detail.html" class="button-light"><i class="fas fa-arrow-right"></i> Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                       
                        <div class="single-features-light">
                            <div class="move">
                                <h4><a href="#">Chatbot Development</a></h4>
                                <p>Revolutionize the way your business interacts with the customers. Their simulation of human language and behavior provides several benefits. Give them a 24/7 real-time interaction facility irrespective of their location and time-zone along with the option to purchase via display links and CTA buttons to provide a richer user experience.</p>
                                <div class="mt-25">
                                    <a href="services-detail.html" class="button-light"><i class="fas fa-arrow-right"></i> Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                       
                       <div class="single-features-light">
                           <div class="move">
                               <h4><a href="#">Outsourcing</a></h4>
                               <p>Maximize operational excellence and drive growth for your business with our outsourcing services. We offer agile best in class mobile applications development and web solutions that will fuel business transformation, innovation, and empower positive impact on your balance sheet. Let outsourcing be your new cost-benefit.</p>
                                <div class="mt-25">
                                    <a href="services-detail.html" class="button-light"><i class="fas fa-arrow-right"></i> Read More</a>
                                </div>
                           </div>
                       </div>
                   </div>
                </div>
             

        <div id="services-two" class="wrap-bg wrap-bg-light text-left">
           
           <div class="container">
               <div class="row align-items-center">
                   <div class="col-md-6">
                       <div class="section-title section-text-left">
                           <div>
                               <h3>How We Help Our Clients Grow</h3>
                               <div class="bar"></div>
                               <p>We take pride in transforming our clients into magnates who rule the market. Our work says it all in the words of our valuable customers.</p>
                               <div class="mt-25 mb-50">
                                   <a href="/about" class="color-two btn-custom ">Start Today <i class="fas fa-arrow-right"></i></a>
                               </div>
                           </div>
                       </div>
                   </div>
                   <div class="col-md-6">
                       <div class="row">
                           <div class="col-lg-6">
                               <div class="min-h-350px services_image services_bg1">
                               </div>
                           </div>
                           <div class="col-lg-6">
                               <div class="min-h-350px services_image services_bg2">
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
        
        </div>
    </div>
   
{/*      <!-- services image area end -->

<!-- Feature Contact --> */}
    <div id="feature_contact" class="container">
    <div class="row justify-content-center text-center">
                <div class="col-lg-8">
                    <div class="section-title with-p">
                        <h2>Engagement Model</h2>
                        <div class="bar"></div>
                        <p>Choose and collaborate with us by opting for any of our engagement models according to your business requirements, manpower needs,
duration, and complexities.
                        </p>
                    </div>
                </div>
            </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="feature_contact_item fc_bg2 ">
                    <div class="opac">
                        <h3>Dedicated Team</h3>
                        <p>It is a long-term collaboration best suited for complex and big projects initiating from scratch. With this model, you get professionals who work as your own employees, without having to worry about handling issues related to recruitment, administration, or infrastructure.
                            <ul class="themeioan_ul_icon">
                                    <li><i class="fas fa-check-circle"></i> No hidden costs</li>
                                    <li><i class="fas fa-check-circle"></i> Flexible payments</li>
                                    <li><i class="fas fa-check-circle"></i> Transparency in work</li>
                                    <li><i class="fas fa-check-circle"></i> No setup fees</li>
                                </ul>
                            </p>
                        <a href="/app-development" class="color-one btn-custom">Get in Touch <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="feature_contact_item fc_bg1 ">
                    <div class="opac">
                        <h3>Fixed Time</h3>
                        <p>It is a classic approach that has a low risk, you pay a fixed price, and you don’t pay any more than that. If you have a limited budget, or if your financial planning procedure is slow, this model will save you a lot of trouble by giving you an exact budget.
                            <ul class="themeioan_ul_icon">
                                    <li><i class="fas fa-check-circle"></i> No hidden costs</li>
                                    <li><i class="fas fa-check-circle"></i> Flexible payments</li>
                                    <li><i class="fas fa-check-circle"></i> Transparency in work</li>
                                    <li><i class="fas fa-check-circle"></i> No setup fees</li>
                                </ul>
                            </p>
                        <a href="/web-development" class="color-one btn-custom">Get in Touch <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
     {/* <!-- Feature Contact end --> */}
  
    
        </>
     );
}
 
export default ProductMain;