import React from 'react';

const Feature = () => {
    return (  

      
        <div id="features" class="wrap-bg services">
       
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-lg-8">
                        <div class="section-title with-p">
                            <h2>Welcome to Techiecub Solutions</h2>
                            <div class="bar"></div>
                            <p>Techiecub Solutions has tied up with various product-based Companies to provide In-Depth Training and Live Projects for students in the final year as a part of their curriculum.
The program starts with an intensive training program that is customized based on live and real-time projects.
                            </p>
                        </div>
                    </div>
                </div>
              
                <div class="row">
                    {/* <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">

                        <div class="single-features-light">
                            <div class="move">
                                <h4><a href="#">Placement Program</a></h4>
                                <p>Techiecubsolutions provides refer jobs for freshers & Experienced for various service based and product based Companies</p>
                                <div class="feature_link">
                                    <a href="/job-asistance"><i class="fas fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">

                        <div class="single-features-light">
                            <div class="move">

                                <h4><a href="#">Internship & Job Assistance</a></h4>
                                <p>Techiecub Solutions has tied up with various product-based Companies to provide In-Depth Training and Live Projects for students in the final year.
                                    {/* as a part of their curriculum.  */}
                                    {/* The program starts with an intensive training program that is customized based on live and real-time projects */}
                                    </p>
                                <div class="feature_link">
                                    {/*<a href="/internship-programe"><i class="fas fa-arrow-right"></i></a>P*/}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">

                        <div class="single-features-light">
                            <div class="move">
                                <h4><a href="#">IT Trainings</a></h4>
                                <p>Techiecub Solutions expert trainers bring real-world experience to the table, ensuring a comprehensive and practical learning experience.</p>
                                <div class="feature_link">
                                    {/*<a href="/corporate-training"><i class="fas fa-arrow-right"></i></a>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">                       
                        <div class="single-features-light">
                            <div class="move">
                             
                                <h4><a href="#">Web & App Development</a></h4>
                                <p>TechieCub Solutions, we specialize in developing real-time projects and delivering top-notch product solutions to our diverse clientele.
                                    {/* that filters the quality candidates from the rest. */}
                                    </p>
                                <div class="feature_link">
                                    {/*<a href="/productmain"><i class="fas fa-arrow-right"></i></a>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                   <div class="row">
                   
                    {/* <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb25">
                       
                        <div class="single-features-light">
                            <div class="move">
                                <h4><a href="#">Co-Working</a></h4>
                                <p>Techiecubsolutions offers Co-working space, on-demand meeting rooms, conference room etc. for your business needs in Bangalore</p>
                                <div class="feature_link">
                                    <a href="/co-works"><i class="fas fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
             
            </div>
          
        </div>
       
    );
}
 
export default Feature;