import React from 'react';
import Slider from "../homeLayout/slider";
import Feature from '../homeLayout/feature';
import Service from '../homeLayout/service';
import WhyUs from '../homeLayout/whyUs';
import ContactArea from '../homeLayout/contactArea';
import WidgetCallArea from '../homeLayout/widgetCallArea';
import BlogArea from '../homeLayout/blogArea';
import SponserArea from '../homeLayout/sponserArea';


function Home() {
    return (
        <>
            <main>
                <Slider />
                <Feature/>
                <Service/>
                <WhyUs/>
                <ContactArea/>
                {/* <WidgetCallArea/> */}
                {/* <BlogArea/> */}
                {/* <SponserArea/> */}
            </main>
        </>
    );
}

export default Home;