import React,{useState} from 'react';

function CorporateTraining(){
    const [readMore, setReadMore] = useState(false);
    const [id, setId] = useState('');
    const handleClickReadMore = (id) => {
        if (readMore) {
            setReadMore(false)
            setId(id);
        } else {
            setReadMore(true)
            setId(id);
        }
    }
    return(
        <>
          
   
    <div class="lernen_banner large bg-corporateTraining">
        <div class="container">
            <div class="row">
                <div class="lernen_banner_title">
                    <h1>Corporate Training</h1>

                    <div class="lernen_breadcrumb">
                        <div class="breadcrumbs">
									<span class="first-item">
									<a href="index.html">Homepage</a></span>
                            <span class="separator">&gt;</span>
                            <span class="last-item">Corporate Training</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    

    {/* <!-- services style projects area start --> */}
    <div id="projects" class="wrap-bg">
        <div class="container">
            <div class="row justify-content-center text-center">
                <div class="col-lg-8">
                    <div class="section-title with-p">
                        <h2>The Best Corporate Training</h2>
                        <div class="bar"></div>
                        <p>Corporate training has evolved rapidly in recent years to move beyond training just new hires, sales people and leaders. Learning and development professionals and corporate trainers are seen as strategic partners who are critical to the success of their business. Why? Organizations today suffer from a skills gap. And many companies say that it takes 3-5 years to take a seasoned professional and make them productive. This means companies have to train, retrain, and jointly educate employees and managers in order to grow. As millennials take on more responsibility, companies will need to build leadership and communication skills at all levels and in all locations around the world.
                        </p>
                    </div>
                </div>
            </div>
            {/* <!-- .row --> */}
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
                    {/* <!-- 1 --> */}
                    <div class="themeioan_services">
                        <article>
                            {/* <!-- single services --> */}
                            <div class="blog-content">
                                <div class="icon-space">
                                    <div class="glyph-icon flaticon-030-test"></div>
                                </div>
                                <h5 class="title"><a href="services-detail.html">Technology Training</a>
                                </h5>
                                <p>Techiecubsolutions offers few technology training to prepare professionals to meet the expanding
                                </p>
                                {id == 1 && readMore ? <span id='more'>
                                            <p>
                                            technical requirements in the market and ensure they stay upgraded. 
                                            Technology Training provides IT professionals with the ability to enhance their knowledge and gain expertise in advanced technologies.
                                            </p>

                                        </span> : ''}



                                        <div class="mt-25">
                                            <a style={{ cursor: 'pointer' }} onClick={() => handleClickReadMore(1)} class="button-light"><i class="fas fa-arrow-right"></i> {id == 1 && readMore ? 'Read Less' : 'Read More'}</a>
                                        </div>
                            </div>
                        </article>
                        {/* <!-- end single services --> */}
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
                    {/* <!-- 2 --> */}
                    <div class="themeioan_services">
                        <article>
                            {/* <!-- single services --> */}
                            <div class="blog-content">
                                <div class="icon-space">
                                    <div class="glyph-icon flaticon-009-skills"></div>
                                </div>
                                <h5 class="title"><a href="services-detail.html">Process Training</a></h5>
                                <p>Techiecubsolutions offers process training becasue currently many of successful organizations have standard 
                                </p>
                                {id == 2 && readMore ? <span id='more'>
                                            <p>
                                            operating procedures and work instructions to ensure quality assurances across all the departments.
                                            Theses SOPs and WIs are expected to be followed consistently by every employee to ensure synchronization of efforts in various processes. Thus it becomes imperative that they understand these SOPs and WIs completely and apply in their day-to-day tasks.
                                            </p>

                                        </span> : ''}



                                        <div class="mt-25">
                                            <a style={{ cursor: 'pointer' }} onClick={() => handleClickReadMore(2)} class="button-light"><i class="fas fa-arrow-right"></i> {id == 2 && readMore ? 'Read Less' : 'Read More'}</a>
                                        </div>
{/*                                 <div class="mt-25">
                                    <a href="services-detail.html" class="button-light"><i class="fas fa-arrow-right"></i> Read More</a>
                                </div> */}
                            </div>
                        </article>
                        {/* <!-- end single services --> */}
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 course-single mb25">
                    {/* <!-- 3 --> */}
                    <div class="themeioan_services">
                        <article>
                            {/* <!-- single services --> */}
                            <div class="blog-content">
                                <div class="icon-space">
                                    <div class="glyph-icon flaticon-028-thinking"></div>
                                </div>
                                <h5 class="title"><a href="services-detail.html">Thinking Creative</a>
                                </h5>
                                <p>We specialize in delivering top-notch Company Induction Trainings, leveraging our expertise to provide the best training experience, we understand the significance of a robust onboarding process in integrating new team members seamlessly. Our expertly crafted training programs cover essential aspects, including company values, policies, organizational structure, benefits, and technology tools. With a commitment to excellence, we ensure that participants are well-prepared to navigate their roles and contribute effectively to the success of our organization. Trust us to guide your team through a comprehensive and engaging induction experience.
                                </p>
                                {id == 3 && readMore ? <span id='more'>
                                            <p>
                                            </p>

                                        </span> : ''}



                                        <div class="mt-25">
                                            <a style={{ cursor: 'pointer' }} onClick={() => handleClickReadMore(3)} class="button-light"><i class="fas fa-arrow-right"></i> {id == 3 && readMore ? 'Read Less' : 'Read More'}</a>
                                        </div>
                            </div>
                        </article>
                        {/* <!-- end single services --> */}
                    </div>
                </div>

            </div>
            {/* <!-- .row end --> */}
        </div>
    </div>
    {/* <!-- services style projects area end --> */}

        </>
    )
}

export default CorporateTraining;