import React, { Component } from "react";
import { BrowserRouter as Router, Link, Redirect, Route, Switch } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import Home from "./home";
import ContactUs from "./contactUs";
import About from "./about";
import DataScience from "./courses/Premium/DataScience";
import WebDevelopment from "./product/webDevelopment";
import AppDevelopment from "./product/appDevelopment";
import CorporateTraining from "./services/corporateTraining";
import InternshipPrograme from "./services/internshipPrograme";
import ResourceOutsourcing from "./services/resourceOutsourcing";
import JobAsistance from "./services/jobAsistance";
import CoWorks from "./services/co-works";
import TrainingMain from "./trainingMain";
import Careers from "./careers";
import ProductMain from "./productmain";
import ServiceMain from "./serviceMain";

import MachineLearning from './courses/Premium/MachineLearning'
import BigDataAnalytics from "./courses/Premium/BigDataAnalytics";
import FullStack from "./courses/Premium/FullStackWebDevelopment";
import DataVisualization from "./courses/Premium/DataVisualization";
import AndroidWithKotlin from "./courses/Premium/AndroidWithKotlin";
import BlockChain from "./courses/Premium/BlockChain";
import Git from "./courses/Devops/git";
import Jekins from "./courses/Devops/jekins";
import ChefPuppet from "./courses/Devops/chefPuppet";
import Ansible from "./courses/Devops/ansible";
import Nagios from "./courses/Devops/Nagios";
import DockerKubernetes from "./courses/Devops/dockerKubernetes";
import Aws from "./courses/cloud/aws";
import Azure from "./courses/cloud/azure";
import Ibm from "./courses/cloud/ibm";
import Google from "./courses/cloud/google";
import Mongodb from "./courses/Database/mongodb";
import MySql from "./courses/Database/mysql";
import PlSql from "./courses/Database/plsql";
import Postgre from "./courses/Database/postgre";
import Selenium from "./courses/testing/selenium";
import Jmeter from "./courses/testing/jmeter";
import Qtp from "./courses/testing/qtp";
import JeeMain from "./courses/academic/jeeMain";
import JeeAdvance from "./courses/academic/jeeAdvance";
import Kcet from "./courses/academic/kcet";
import CBSE from "./courses/academic/CBSE";
import ICSCE from "./courses/academic/ICSCE";
import PUC from "./courses/academic/PUC";
import CDMA from "./courses/Telecom/CDMA";
import GSM from "./courses/Telecom/GSM";
import GPRS from "./courses/Telecom/GPRS";
import telecom1G2G3G from "./courses/Telecom/telecom1G2G3G";
import LTE4G from "./courses/Telecom/LTE4G";
import telecom5G from "./courses/Telecom/telecom5G";
import SIP from "./courses/Telecom/SIP";





class UbiquitouesRoutes extends Component {
   
  render() {
    return (
      <>
      <Header />
     
        <Router>
          <>
            <Switch>
            {/* <Redirect from='/' exact to='/home' /> */}
              <Route path="/" exact component={Home} />
              <Route path="/contact" exact component={ContactUs} />
              <Route path="/about" exact component={About} />
            
              <Route path="/trainingmain" exact component={TrainingMain} />
              <Route path="/careers" exact component={Careers} />
              <Route path="/productmain" exact component={ProductMain} />

              {/* Product */}
              <Route path="/web-development" exact component={WebDevelopment} />
              <Route path="/app-development" exact component={AppDevelopment} />


              {/* Services */}
              <Route path="/corporate-training" exact component={CorporateTraining} />
              <Route path="/internship-programe" exact component={InternshipPrograme} />
              <Route path="/resource-outsourcing" exact component={ResourceOutsourcing} />
              <Route path="/job-asistance" exact component={JobAsistance} />
              <Route path="/co-works" exact component={CoWorks} />

              <Route path="/service-main" exact component={ServiceMain} />
             
              {/* Courses */}
              <Route path="/data-science" exact component={DataScience} />
              <Route path="/machine-learning" exact component={MachineLearning} />
              <Route path="/big-data-and-analytics" exact component={BigDataAnalytics} />
              <Route path="/full-stack-web-development" exact component={FullStack} />
              <Route path="/data-visualization" exact component={DataVisualization} />
              <Route path="/android-kotlin" exact component={AndroidWithKotlin} />
              <Route path="/blockchain" exact component={BlockChain} />
              <Route path="/git" exact component={Git} />
              <Route path="/jekins" exact component={Jekins} />
              <Route path="/chef-puppet" exact component={ChefPuppet} />
              <Route path="/ansible" exact component={Ansible} />
              <Route path="/nagios" exact component={Nagios} />
              <Route path="/docker-kubernetes" exact component={DockerKubernetes} />
              <Route path="/aws" exact component={Aws} />
              <Route path="/azure" exact component={Azure} />
              <Route path="/ibm" exact component={Ibm} />
              <Route path="/google" exact component={Google} />
              <Route path="/mongodb" exact component={Mongodb} />
              <Route path="/mysql" exact component={MySql} />
              <Route path="/plsql" exact component={PlSql} />
              <Route path="/postgre" exact component={Postgre} />
              <Route path="/selenium" exact component={Selenium} />
              <Route path="/jmeter" exact component={Jmeter} />
              <Route path="/qtp" exact component={Qtp} />
              <Route path="/jee-main" exact component={JeeMain} />
              <Route path="/jee-advance" exact component={JeeAdvance} />
              <Route path="/kcet" exact component={Kcet} />
              <Route path="/CBSE" exact component={CBSE} />
              <Route path="/ICSCE" exact component={ICSCE} />
              <Route path="/PUC" exact component={PUC} />
              <Route path="/CDMA" exact component={CDMA} />
              <Route path="/GSM" exact component={GSM} />
              <Route path="/GPRS" exact component={GPRS} />
              <Route path="/1G-2G-3G" exact component={telecom1G2G3G} />
              <Route path="/LTE-4G" exact component={LTE4G} />
              <Route path="/5G" exact component={telecom5G} />
              <Route path="/SIP" exact component={SIP} />









              
              {/* <Route path="/courses" exact component={Courses} />

              <Route path="/courses/full-stack-web-development" exact component={FullStackWebDevelopment} />
              <Route path="/courses/machine-learning" exact component={ML} />
              <Route path="/courses/data-science" exact component={DataScience} />
              <Route path="/courses/sql" exact component={SQL} />
              <Route path="/courses/big-data-and-analytics" exact component={BigDataAnalytics} />
              <Route path="/courses/competitive-programming" exact component={CompetitiveProgramming} />

              <Route path="/login" exact component={Login} />
              <Route path="/contactus" exact component={ContactUs} />
              <Route path="/about" exact component={About} />
              <Route path="/career" exact component={Career} />
              <Route path="/jobasistance" exact component={JobAsistance} />
              <Route path="/corporatetraining" exact component={CorporateTraining} />
              <Route path="/project" exact component={Projects} />
              <Route path="/internship" exact component={InternshipPrograme} />
              <Route path="/resource" exact component={ResourceOutsourcing} />
              
              <Route path="/service" exact component={ServiceMain} /> */}
            </Switch>
          </>
          <Footer/>
        </Router>
      </>
    );
  }
}

export default UbiquitouesRoutes;
